import type { LangContextType } from '@shared/common/context/TranslateContext'
import { LangContext } from '@shared/common/context/TranslateContext'
import { Button, Input, Modal, Spin } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsClipboard2CheckFill, BsCopy, BsPlusLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

import { getOrderShareLink } from '../../reducers/orderReducer'

type Props = {
  orderId: string
}

const ModalShareOrder = ({ orderId }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  const buttonRef = useRef<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [copying, setCopying] = useState<boolean>(false)
  const [inviteLink, setInviteLink] = useState<string>('')
  const { lang } = useContext(LangContext) as LangContextType

  const fetchData = async () => {
    setLoading(true)

    try {
      const info = await dispatch(
        getOrderShareLink({
          id: orderId
        })
      ).unwrap()

      setInviteLink(`${process.env.REACT_APP_BASE_URL}/invite/${info.id}`)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      fetchData()
    }
  }, [isOpenModal])

  const onCopy = () => {
    setCopying(true)

    setTimeout(() => {
      setCopying(false)
    }, 1500)
  }

  return (
    <div>
      <Button
        type='default'
        onClick={() => {
          setIsOpenModal(true)
        }}
        icon={<BsPlusLg />}
      >
        Mời
      </Button>

      <Modal
        title='Mời bạn bè vào nhóm'
        className='top-10'
        width={600}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false)
        }}
        open={isOpenModal}
      >
        <div className='min-h-[160px]'>
          {!loading ? (
            <div className='flex flex-col gap-6 pb-3 pt-4'>
              <div className='flex flex-row gap-3'>
                <Input value={inviteLink} readOnly />

                <CopyToClipboard text={inviteLink} onCopy={onCopy}>
                  <Button icon={copying ? <BsClipboard2CheckFill /> : <BsCopy />} ref={buttonRef} type='primary' disabled={copying} htmlType='button'>
                    {copying ? lang('Copied', 'Đã sao chép') : lang('Copy', 'Sao chép')}
                  </Button>
                </CopyToClipboard>
              </div>

              <div className='rounded border border-primary-300 bg-primary-50 p-3 text-sm'>
                {lang(
                  'Hãy gửi liên kết này cho bạn bè của bạn để cùng nhau đặt hàng theo nhóm và quản lý thật dễ dàng. Lưu ý: liên kêt này sẽ hết hạn sau 1 giờ.',
                  'Hãy gửi liên kết này cho bạn bè của bạn để cùng nhau đặt hàng theo nhóm và quản lý thật dễ dàng. Lưu ý: liên kêt này sẽ hết hạn sau 1 giờ.'
                )}
              </div>
            </div>
          ) : (
            <div>
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ModalShareOrder

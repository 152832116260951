import GoogleSignInButton from '@individual/GoogleSignInButton'
import type { RootState } from '@shared/store'
import { Alert, Button, Form, Input, message, Result } from 'antd'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'

import { register } from './reducers/authReducer'

const RegisterPage: FC = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const [loading, setLoading] = useState<boolean>(false)
  const [successfull, setSuccessfull] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      messageApi.error(errorMessage)
    }
  }, [errorMessage])

  const handleRegister = async (formValue: { username: string; email: string; password: string; code: string }) => {
    const { username, email, password } = formValue

    setLoading(true)

    try {
      await dispatch(register({ username, email, password })).unwrap()
      setSuccessfull(true)
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
      setSuccessfull(false)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = (form: any) => {
    handleRegister(form)
  }

  return (
    <div className='flex min-h-screen w-full flex-col bg-[#FCFCFC] pt-10 center'>
      {contextHolder}

      <div className='flex w-[362px] flex-col'>
        {!successfull ? (
          <div className='flex flex-col'>
            <div className='text-5xl font-semibold text-base-1'>Sign up</div>
            <div className='mt-6 text-sm font-semibold text-base-1'>Sign up with Open account</div>

            <div className='mt-6'>
              <GoogleSignInButton />
            </div>

            <div className='mt-6 h-[1px] bg-[#EFEFEF]' />

            <div className='mt-6 text-sm font-semibold text-base-1'>Or continue with email address</div>

            <Form name='register' layout='vertical' onFinish={onFinish} autoComplete='off'>
              <Form.Item label='Username' name='username' rules={[{ required: true, message: 'Please input your username!' }]} className='mt-6 font-semibold'>
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label='Email'
                name='email'
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please input a valid email.' }
                ]}
                className='mt-6 font-semibold'
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]} className='mt-6 font-semibold'>
                <Input.Password size='large' />
              </Form.Item>

              {errorMessage && <Alert message={errorMessage} type='error' showIcon className='mt-6' />}

              <div className='flex flex-col py-6'>
                <Form.Item>
                  <Button type='primary' size='large' htmlType='submit' loading={loading} className='w-full'>
                    Sign up
                  </Button>
                </Form.Item>

                <div className='flex flex-row font-semibold'>
                  <div className='font-semibold text-tertiary'>Already a member?</div>
                  <Link to='/login' className='ml-1 flex flex-1 flex-row no-underline'>
                    Sign in
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className='flex flex-col rounded-lg border border-solid border-gray-200 bg-white shadow-md'>
            <Result
              status='success'
              title='Check your email'
              subTitle='We have sent a verification email to your email address. Please check your email and click on the confirmation button to activate your account.'
              extra={[
                <Link to='/login' key='console'>
                  <Button type='primary' className='w-full'>
                    Back to login
                  </Button>
                </Link>
              ]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RegisterPage

import React from 'react'

type Props = {
  className?: string
  type: 'circle' | 'circle-2' | 'full' | 'icon' | 'text'
}

const Logo = ({ type, className }: Props) => {
  return (
    <div className={className}>
      <img src={`/images/brand/logo-${type}.svg`} className='max-h-full max-w-full object-contain' />
    </div>
  )
}

export default Logo

/* eslint-disable no-nested-ternary */
import React from 'react'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { updateTask } from '../../reducers/taskReducer'

type Props = {
  info: any
}

const TaskItem = ({ info }: Props) => {
  const params = useParams()
  const dispatch = useDispatch<any>()

  const handleIsCompleteTask = async (isComplete: boolean) => {
    try {
      await dispatch(updateTask({ id: info.id, isCompleted: isComplete })).unwrap()
    } catch (error) {}
  }

  const onCheckCompleteTask = () => {
    handleIsCompleteTask(!info.isCompleted)
  }

  return (
    <Link
      to={`/to-do/${params.folderId}/${info.id}`}
      className={`task_item flex h-12 select-none flex-row items-center gap-3 px-3 py-2 text-sm  ${
        params.taskId === info.id ? 'bg-gray-100' : 'bg-white hover:bg-light-1'
      }`}
    >
      <button onClick={onCheckCompleteTask} className='cursor-pointer bg-transparent p-0 center'>
        {info.isCompleted ? <BsCheckCircleFill size={16} className='text-[#576570]' /> : <BsCircle size={16} />}
      </button>

      <div className='flex-1 leading-none'>{info.title}</div>

      {info.childTaskCount > 0 ? (
        <div className='rounded-r-lg rounded-s-lg border border-slate-200 px-[6px] py-[1px] text-[11px]'>
          {info.childTaskCompletedCount} of {info.childTaskCount}
        </div>
      ) : (
        ''
      )}
    </Link>
  )
}

export default TaskItem

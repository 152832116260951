import { EAppPlatform } from '@shared/common/enum'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { localStorage } from 'redux-persist-webextension-storage'

import childTaskReducer from './reducers/childTaskReducer'
import folderReducer from './reducers/folderReducer'
import taskReducer from './reducers/taskReducer'

const persistConfig = {
  key: 'todo',
  // @ts-ignore
  storage: Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION ? localStorage : storage,
  whitelist: ['folder']
  // blacklist: ['current'],
}

const TodoReducer = combineReducers({
  folder: folderReducer,
  task: taskReducer,
  childTask: childTaskReducer
})

export default persistReducer(persistConfig, TodoReducer)

import { formatMoney, formatTime } from '@shared/common/utils'
import type { RootState } from '@shared/store'
import { Table, type TablePaginationConfig } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { getStatusName } from './common/helper'
import ModalAddOrder from './partials/order/ModalAddOrder'
import OrderListEvent from './partials/order/OrderListEvent'
import { fetchOrders, updateFilter } from './reducers/orderReducer'

const GroupOrderIndex = () => {
  const navigate: NavigateFunction = useNavigate()

  const dispatch = useDispatch<any>()
  const items = useSelector((state: RootState) => state.groupOrder.order.items)
  const filter = useSelector((state: RootState) => state.groupOrder.order.filter)
  const total = useSelector((state: RootState) => state.groupOrder.order.total)

  const fetchData = async () => {
    try {
      await dispatch(fetchOrders()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  /*
   *****************************************
   *
   *
   */

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns: any = [
    {
      title: 'Tên',
      render: (record: any) => <div className='font-medium'>{`${record.name}`}</div>
    },
    {
      title: 'Tổng tiền',
      width: '150px',
      align: 'right',
      render: (record: any) => <div>{`${record.meta?.totalPrice ? formatMoney(record.meta?.totalPrice) : '-'}`}</div>
    },
    {
      title: 'Đã thanh toán',
      width: '150px',
      align: 'center',
      render: (record: any) => (
        <div className='center'>
          {record.meta?.totalQuantity > 0 ? (
            <div className='flex flex-row items-center'>
              <span>{record.meta?.countPaidQuantity || 0}</span>
              <span>/</span>
              <span>{record.meta?.totalQuantity || 0}</span>
            </div>
          ) : (
            <div>-</div>
          )}
        </div>
      )
    },
    {
      title: 'Trạng thái',
      width: '180px',
      align: 'center',
      render: (record: any) => <div>{`${getStatusName(record)}`}</div>
    },
    {
      title: 'Người tạo nhóm',
      width: '200px',
      align: 'center',
      render: (record: any) => <div>{`${record.user.fullname}`}</div>
    },
    {
      title: 'Ngày tạo',
      width: '160px',
      align: 'right',
      render: (record: any) => <div>{formatTime(new Date(record.createdAt), "dd 'tháng' MM")}</div>
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}

    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.createdAt)) {
      newFilter = Object.assign(newFilter, {
        createdAt: filters.createdAt[0],
        page: 1
      })
    } else if (filters.lang === null) {
      newFilter = Object.assign(newFilter, {
        createdAt: '',
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  const onClickRow = (record: any) => {
    navigate(`/group-order/${record.id}`)
  }

  return (
    <div className='flex h-full flex-col'>
      <OrderListEvent />

      <div className='fixed left-[72px] right-0 top-[120px] z-10'>
        <div className='container relative'>
          <ModalAddOrder onClose={() => {}} />
        </div>
      </div>

      <div className='container relative pb-12 pt-20'>
        <Table
          bordered={false}
          dataSource={items}
          rowKey='id'
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          columns={columns}
          className='rounded-t border border-b-0'
          rowClassName='cursor-pointer'
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total, hideOnSinglePage: true }}
        />
      </div>
    </div>
  )
}

export default GroupOrderIndex

import { EUserRole, EUserStatus } from '@shared/common/enum'
import { convertEnumToSelectOptions, getUpdatedDiff } from '@shared/common/utils'
import { Button, Form, Input, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUser, updateUser } from './reducers/userReducer'

const ZobiteAdminUserDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const fetchMediaInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getUser({
            userId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchMediaInfo()
    }
  }, [params.id])

  const hideModal = async () => {
    // if (onClose) {
    //   onClose()
    //   setInfo({})
    // }
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
      return
    }

    try {
      setLoading(true)
      await dispatch(
        updateUser({
          id: info.id,
          ...updatedInfo
        })
      ).unwrap()

      setInfo(Object.assign(info, updatedInfo))

      hideModal()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {!loading && info?.id ? (
        <Form onValuesChange={handleValuesChange} layout='vertical' onFinish={onFinish} initialValues={info}>
          <div className='flex flex-row gap-6'>
            <Form.Item className='flex-1' label='Username' name='username' required>
              <Input readOnly />
            </Form.Item>

            <Form.Item className='flex-1' label='Email' name='email' required>
              <Input readOnly />
            </Form.Item>
          </div>

          <Form.Item label='Name' name='fullname' required>
            <Input />
          </Form.Item>

          <Form.Item label='Phone' name='phone' required>
            <Input />
          </Form.Item>

          <Form.Item label='Status' name='status' required>
            <Select options={convertEnumToSelectOptions(EUserStatus)} />
          </Form.Item>

          <Form.Item label='Roles' name='roles' required>
            <Select mode='multiple' options={convertEnumToSelectOptions(EUserRole)} />
          </Form.Item>

          <div className='center'>
            <Button disabled={disabled} loading={loading} className='mt-6' type='primary' htmlType='submit'>
              Update
            </Button>
          </div>
        </Form>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default ZobiteAdminUserDetail

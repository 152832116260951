import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import axios from 'axios'

interface IState {
  current: any
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
  }
}

const initialState: IState = {
  current: undefined,
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100
  }
}

/*
 *****************************************
 *
 *
 */

let lastCancelToken: any

type FetchOrderItemDto = {
  orderId: string
}

export const fetchOrderItems = createAsyncThunk('group-order/orderItem/list', async (payload: FetchOrderItemDto, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = {
      ...state.groupOrder.orderItem.filter,
      orderId: payload.orderId
    }

    if (lastCancelToken) {
      lastCancelToken.cancel()
    }

    lastCancelToken = axios.CancelToken.source()

    return await api.get('group-order/order-items', {
      params,
      cancelToken: lastCancelToken.token
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type OrderItemGetDto = {
  id: string
}

export const getOrderItem = createAsyncThunk('group-order/orderItem/get', async (payload: OrderItemGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`group-order/order-items/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * CREATE
 *
 */

type OrderItemCreateDto = {
  orderId: string
  name: string
  note: string
  price: string
  quantity: number
  status?: number
}

export const createOrderItem = createAsyncThunk('group-order/orderItem/create', async (payload: OrderItemCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`group-order/order-items`, payload)
    return { ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type OrderItemUpdateDto = {
  id: string
  name?: string
  note?: string
  price?: string
  quantity?: number
  status?: number
  paymentMethod?: number
}

export const updateOrderItem = createAsyncThunk('group-order/orderItem/update', async (payload: OrderItemUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`group-order/order-items/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type OrderItemDeleteDto = {
  id: string
}

export const deleteOrderItem = createAsyncThunk('group-order/orderItem/delete', async (payload: OrderItemDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`group-order/order-items/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const orderItemSlice = createSlice({
  name: 'orderItem',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    addOrderItemLocal(state, { payload }) {
      const info = payload as any

      const findIndex = state.items.findIndex((item) => item.id === info.id)

      if (findIndex >= 0) {
        state.items.splice(findIndex, 1, { ...state.items[findIndex], ...info })
      } else {
        state.items.push(info)
      }
    },

    updateOrderItemLocal(state, { payload }) {
      const info = payload as any

      const findIndex = state.items.findIndex((item) => item.id === info.id)

      if (findIndex >= 0) {
        state.items.splice(findIndex, 1, { ...state.items[findIndex], ...info })
      } else {
        state.items.push(info)
      }
    },

    deleteOrderItemLocal(state, { payload }) {
      const { id } = payload

      const index = state.items.findIndex((item) => item.id === id)

      if (index >= 0) {
        state.items.splice(index, 1)
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderItems.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(createOrderItem.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, { ...state.items[findIndex], ...info })
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateOrderItem.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, { ...state.items[findIndex], ...info })
        } else {
          state.items.push(info)
        }
      })
      .addCase(deleteOrderItem.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, updateOrderItemLocal, addOrderItemLocal, deleteOrderItemLocal } = orderItemSlice.actions
export default orderItemSlice.reducer

import React from 'react'

import TodoLayout from './layout/TodoLayout'
import FolderDetail from './partials/folder/FolderDetail'
import FolderIndex from './partials/folder/FolderIndex'
import TaskDetail from './partials/task/TaskDetail'
import TodoIndex from './TodoIndex'

export const TodoRoutes = [
  {
    path: 'to-do',
    element: <TodoLayout />,
    children: [
      { path: '', element: <TodoIndex /> },
      {
        path: ':folderId',
        element: <FolderDetail />,
        children: [
          { path: '', element: <FolderIndex /> },
          { path: ':taskId', element: <TaskDetail /> }
        ]
      }
    ]
  }
]

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import axios from 'axios'

interface IState {
  current: any
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
  }
}

const initialState: IState = {
  current: undefined,
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100
  }
}

/*
 *****************************************
 *
 *
 */

let lastCancelToken: any

export const fetchOrders = createAsyncThunk('group-order/order/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.groupOrder.order.filter }

    if (lastCancelToken) {
      lastCancelToken.cancel()
    }

    lastCancelToken = axios.CancelToken.source()

    return await api.get('group-order/orders', {
      params,
      cancelToken: lastCancelToken.token
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type OrderGetDto = {
  id: string
}

export const getOrder = createAsyncThunk('group-order/order/get', async (payload: OrderGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`group-order/orders/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type GetInviteLinkDto = {
  id: string
}

export const getOrderShareLink = createAsyncThunk('group-order/order/get-invite-link', async (payload: GetInviteLinkDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`group-order/orders/${payload.id}/create-invite-link`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * CREATE
 *
 */

type OrderCreateDto = {
  name: string
  link: string
  note: string
}

export const createOrder = createAsyncThunk('group-order/order/create', async (payload: OrderCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`group-order/orders`, payload)
    return { ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type OrderUpdateDto = {
  id: string
  name?: string
  note?: string
  link?: string
}

export const updateOrder = createAsyncThunk('group-order/order/update', async (payload: OrderUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`group-order/orders/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type OrderDeleteDto = {
  id: string
}

export const deleteOrder = createAsyncThunk('group-order/order/delete', async (payload: OrderDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`group-order/orders/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const orderSlice = createSlice({
  name: 'order',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    updateOrderLocal(state, { payload }) {
      const info = payload as any

      const findIndex = state.items.findIndex((item) => item.id === info.id)

      if (findIndex >= 0) {
        state.items.splice(findIndex, 1, Object.assign(state.items[findIndex], payload))
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        }
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, updateOrderLocal } = orderSlice.actions
export default orderSlice.reducer

/* eslint-disable no-underscore-dangle */
import { reset, updateToken } from '@shared/modules/auth/reducers/authReducer'
import axios from 'axios'

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (err) => {
    const { config } = err

    if (config.url !== '/auth/login' && err.response?.status === 401 && !config._retry) {
      // Access Token was expired
      config._retry = true

      if (config.url === '/auth/refreshtoken') {
        store.dispatch(reset())
      }

      try {
        const { refreshToken } = store.getState().auth

        if (refreshToken) {
          const rs: any = await api.post('/auth/refreshtoken', {
            refreshToken
          })

          const { token } = rs

          axios.defaults.headers.common.Authorization = `Bearer ${token}`

          store.dispatch(updateToken(token))

          return await api(config)
        }

        return await axios(config)
      } catch (error: any) {
        if (error?.response?.status !== 401) {
          store.dispatch(reset())
        }

        return Promise.reject(error)
      }
    }

    return Promise.reject(err)
  }
)

export default api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import { EAppPlatform, EAppStatus } from '@shared/common/enum'
import type { RootState } from '@shared/store'

export interface AppState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    createdAt?: string
    status?: number
    platform?: number
  }
}

const initialState: AppState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: '-createdAt',
    status: EAppStatus.RELEASED,
    platform: EAppPlatform.WEBAPP
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchApps = createAsyncThunk('app/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.app.filter }

    params.platform = EAppPlatform.WEBAPP

    return await api.get('apps', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AppGetDto = {
  appId: string
}

export const getApp = createAsyncThunk('app/get', async (payload: AppGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`apps/${payload.appId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

/*
 *****************************************
 *
 *
 */

type InstallAppDto = {
  appId: string
}

export const installApp = createAsyncThunk('app/install', async (payload: InstallAppDto, { rejectWithValue }) => {
  try {
    await api.post(`apps/install`, {
      appId: payload.appId
    })
    return { id: payload.appId }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type UninstallAppDto = {
  appId: string
}

export const uninstallApp = createAsyncThunk('app/uninstall', async (payload: UninstallAppDto, { rejectWithValue }) => {
  try {
    await api.post(`apps/uninstall`, {
      appId: payload.appId
    })
    return { id: payload.appId }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const appSlice = createSlice({
  name: 'app',

  initialState,

  reducers: {
    reset: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = JSON.parse(JSON.stringify(initialState))
    },

    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchApps.fulfilled, (state, action) => {
      const { total, items } = action.payload as any

      state.items = items
      state.total = total
    })
  }
})

export const { reset, updateFilter } = appSlice.actions
export default appSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import axios from 'axios'

interface IState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    taskId?: string
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: 'position'
  }
}

/*
 *****************************************
 */

type ChildTaskFetchDto = {
  taskId: string
}

let lastCancelToken: any

export const fetchChildTasks = createAsyncThunk('todo/childTask/list', async (payload: ChildTaskFetchDto, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = {
      ...state.todo.childTask.filter,
      ...payload
    }
    if (lastCancelToken) {
      lastCancelToken.cancel()
    }

    lastCancelToken = axios.CancelToken.source()

    return await api.get('todo/childTasks', {
      params,
      cancelToken: lastCancelToken.token
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type ChildTaskGetDto = {
  id: string
}

export const getChildTask = createAsyncThunk('todo/childTask/get', async (payload: ChildTaskGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`todo/childTasks/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type ChildTaskCreateDto = {
  taskId: string
  content?: string
  position?: number
}

export const createChildTask = createAsyncThunk('todo/childTask/create', async (payload: ChildTaskCreateDto, { rejectWithValue }) => {
  try {
    return await api.post(`todo/childTasks`, payload)
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type ChildTaskUpdateDto = {
  id: string
  content?: string
  note?: string
  isCompleted?: boolean
}

export const updateChildTask = createAsyncThunk('todo/childTask/update', async (payload: ChildTaskUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`todo/childTasks/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type ChildTaskUpdatePositionsDto = {
  positions: any[]
}

export const updateChildTaskPositions = createAsyncThunk(
  'todo/childTask/update-positions',
  async (payload: ChildTaskUpdatePositionsDto, { rejectWithValue }) => {
    try {
      await api.put(`todo/childTasks/update-positions`, payload)
      return payload
    } catch (error: any) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

/*
 *****************************************
 */

type ChildTaskDeleteDto = {
  id: string
}

export const deleteChildTask = createAsyncThunk('todo/childTask/delete', async (payload: ChildTaskDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`todo/childTasks/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

export const childTaskSlice = createSlice({
  name: 'todo_child_task',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchChildTasks.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        state.items = items
        state.total = total
      })
      .addCase(createChildTask.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateChildTask.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        }
      })
      .addCase(deleteChildTask.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
      .addCase(updateChildTaskPositions.fulfilled, (state, action) => {
        const { positions } = action.payload as any

        for (const newItem of positions) {
          const findIndex = state.items.findIndex((item) => item.id === newItem.id)

          if (findIndex >= 0) {
            const newObject = Object.assign(state.items[findIndex], newItem)

            state.items.splice(findIndex, 1, newObject)
          }
        }
      })
  }
})

export const { updateFilter } = childTaskSlice.actions
export default childTaskSlice.reducer

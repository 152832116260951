import GoogleSignInButton from '@individual/GoogleSignInButton'
import type { RootState } from '@shared/store'
import { Button, Result, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { BsFillCheckCircleFill, BsShieldLockFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { joinInvite } from './reducers/inviteReducer'

const InvitePage = () => {
  const params = useParams()
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const [info, setInfo] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()
  const [counter, setCounter] = useState(5)

  const timer = useRef<any>()

  const fetchData = async () => {
    if (params.id) {
      try {
        setLoading(true)

        const rs = await dispatch(
          joinInvite({
            id: params.id
          })
        ).unwrap()

        if (rs) {
          if (rs.action === 1 && rs.data.id) {
            timer.current = setInterval(() => {
              setCounter((val) => val - 1)
            }, 1000)
          }
        }

        setInfo(rs)
      } catch (err: any) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (counter <= 0) {
      if (timer.current) {
        clearInterval(timer.current)
        navigate(`/group-order/${info.data.id}`)
      }
    }
  }, [counter])

  useEffect(() => {
    if (isLoggedIn) {
      fetchData()
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [])

  return (
    <main className='flex flex-col border'>
      {loading ? (
        <div className='flex-1 center'>
          {isLoggedIn ? (
            <div className='flex-1 flex-col gap-3 center'>
              <Spin />

              <div className='font-medium'>Đang kiểm tra dữ liệu.</div>
            </div>
          ) : (
            <div className='flex-1 flex-col gap-4 center'>
              <div>
                <BsShieldLockFill size={112} className='text-red-500' />
              </div>
              <div className='text-xl font-bold'>Bạn cần đăng nhập để tiếp tục</div>

              <div className='mt-6'>
                <GoogleSignInButton
                  onSuccess={() => {
                    window.location.reload()
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='flex-1 center'>
          {error ? (
            <div>
              {error.errorCode === 40705 && (
                <Result
                  status='error'
                  title='Liên kết đã hết hạn'
                  subTitle='Liên kết này đã không còn hiệu lực, bạn vui lòng kiểm tra lại hoặc sử dụng liên kết khác.'
                />
              )}

              {error.statusCode === 400 && <Result status='error' title='Liên kết không hợp lệ' subTitle='Đường dẫn bạn vừa sử dụng không hợp lệ.' />}
            </div>
          ) : (
            <div className='center'>
              {info.action === 1 && (
                <div className='flex max-w-[500px] flex-col gap-3 center'>
                  <div>
                    <BsFillCheckCircleFill size={80} className='text-green-500' />
                  </div>

                  <div className='text-3xl font-black'>Tham gia đặt nhóm</div>
                  <div className='flex flex-col text-center text-main'>
                    <div>
                      Bạn được mời tham gia đặt nhóm <b className='font-bold text-primary'>{info?.data?.name}</b>.
                    </div>
                    <div>Hệ thống sẽ tự động chuyển bạn tới nhóm trong {counter} giây.</div>

                    <div className='mt-5 center'>
                      <Link to={`/group-order/${info.data.id}`}>
                        <Button type='primary'>Tới nhóm ngay</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </main>
  )
}

export default InvitePage

import AppIcon from '@shared/components/AppIcon'
import { Tooltip } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  name: string
  to: string
  icon: string
  className?: string
}

const MenuItem = ({ name, to, icon, className }: Props) => {
  const location = useLocation()

  const isActive = () => {
    if (to === '/') {
      return location.pathname === to
    }
    return location.pathname.startsWith(`/${to}`)
  }

  return (
    <Tooltip placement='right' title={name}>
      <Link
        to={isActive() ? location.pathname : to}
        className={`drag_item h-[72px] min-h-[72px] w-[72px] min-w-[72px] cursor-pointer select-none transition duration-75 ease-in center ${className}`}
      >
        {isActive() && <div className='absolute left-0 h-12 w-[6px] rounded-r bg-primary' />}

        <div className='h-12 w-12 rounded-lg center'>
          <AppIcon name={icon} />
        </div>
      </Link>
    </Tooltip>
  )
}

export default MenuItem

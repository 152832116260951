import { getInvoice } from '@shared/common/reducers/invoiceReducer'
import { getUpdatedDiff } from '@shared/common/utils'
import { Button, Form, Input, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const InvoiceDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const fetchMediaInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getInvoice({
            invoiceId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      setLoading(true)
      fetchMediaInfo()
    }
  }, [params.id])

  const hideModal = async () => {
    // if (onClose) {
    //   onClose()
    //   setInfo({})
    // }
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {!loading && info?.id ? (
        <Form onValuesChange={handleValuesChange} layout='vertical' onFinish={onFinish} initialValues={info} autoComplete='off'>
          <div className='flex flex-row gap-6'>
            <Form.Item className='flex-1' label='Username' name='username' required>
              <Input readOnly />
            </Form.Item>

            <Form.Item className='flex-1' label='Email' name='email' required>
              <Input readOnly />
            </Form.Item>
          </div>

          <Form.Item label='Name' name='fullname' required>
            <Input />
          </Form.Item>

          <Form.Item label='Phone' name='phone' required>
            <Input />
          </Form.Item>

          <div className='center'>
            <Button disabled={disabled} loading={loading} className='mt-6' type='primary' htmlType='submit'>
              Update
            </Button>
          </div>
        </Form>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default InvoiceDetail

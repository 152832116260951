import { Image } from 'antd'
import React from 'react'

type Props = {
  name: string
  className?: string
}

const AppIcon = ({ name, className }: Props) => {
  return (
    <div className='h-8 w-8 center'>
      <Image src={`/images/app-icons/${name}.svg`} preview={false} className={`object-contain ${className}`} />
    </div>
  )
}

export default AppIcon

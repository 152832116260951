import { EPackageType } from '@shared/common/enum'
import { formatMoney } from '@shared/common/utils'
import { Button } from 'antd'
import React from 'react'
import { HiArrowRight, HiCheckCircle } from 'react-icons/hi2'

type Props = {
  step: number
  calculatedInfo: any
  app: any
  currentPackage: any
  changeStep: (step: number) => void
}

const CheckoutStep1 = ({ app, currentPackage, changeStep, calculatedInfo, step }: Props) => {
  return (
    <div className='mt-12 flex flex-row gap-6 border-t pt-16'>
      <div className='flex flex-1 flex-col'>
        <div className='text-2xl font-semibold text-primary'>
          {app.name} - {currentPackage.name}
        </div>

        <div className='mt-4 flex flex-row items-center text-lg'>
          <div className='font-semibold text-main'>{`${formatMoney(currentPackage.price)}`}</div>
          <div className='text-tertiary'>/ {currentPackage.type === EPackageType.RECURRING_YEARLY ? 'year' : 'month'}</div>
        </div>

        <div className='mt-10 flex flex-col gap-3.5'>
          {currentPackage.content?.split('\n')?.map((item: string) => {
            return (
              <div className='flex flex-row items-center gap-2'>
                <HiCheckCircle size={28} className='text-gray-400' />
                {item}
              </div>
            )
          })}
        </div>
      </div>

      <div className='w-[320px]'>
        <div className='rounded border bg-gray-50 p-4'>
          <div className='border-b pb-4 text-lg font-bold'>Order Summary</div>

          <div className='mt-3 flex flex-row py-1.5'>
            <div>Subtotal</div>
            <div className='flex-1' />
            <div className='text-gray-600'>{`${formatMoney(calculatedInfo.subTotal)}`}</div>
          </div>

          <div className='flex flex-row py-1.5'>
            <div>VAT</div>
            <div className='flex-1' />
            <div>-</div>
          </div>

          <div className='flex flex-row py-1.5'>
            <div>Discount</div>
            <div className='flex-1' />
            <div>{`${calculatedInfo.discount ? formatMoney(calculatedInfo.discount) : '-'}`}</div>
          </div>

          <div className='flex flex-row items-center py-1.5'>
            <div className='font-medium'>Total</div>
            <div className='flex-1' />
            <div className='font-semibold text-primary'>{`${formatMoney(calculatedInfo.total)}`}</div>
          </div>
        </div>

        {step === 0 && (
          <Button type='primary' size='large' className='mt-6 flex w-full flex-row items-center gap-2.5' onClick={() => changeStep(1)}>
            Tiến hành thanh toán
            <HiArrowRight size={16} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default CheckoutStep1

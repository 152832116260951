import type { RootState } from '@shared/store'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const GoogleCallbackPage = () => {
  const navigate: NavigateFunction = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code')
    const error = new URLSearchParams(window.location.search).get('error')
    const state = new URLSearchParams(window.location.search).get('state')
    if (code && window && window.opener) {
      window.opener.postMessage({ code, state }, window.location.origin)
    } else if (error && window.opener) {
      window.opener.postMessage({ error }, window.location.origin)
    }
  }, [])

  return <div />
}

export default GoogleCallbackPage

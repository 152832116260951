import { formatTime, getEnumKeyName } from '@shared/common/utils'
import Lang from '@shared/components/Lang'
import type { RootState } from '@shared/store'
import type { TablePaginationConfig } from 'antd'
import { Drawer, notification, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { TableCreatedAtFilters } from '../../common/constant'
import { EQuoteStatus } from './common/constant'
import ModalAdd from './components/ModalAdd'
import Search from './components/Search'
import TableActions from './components/TableActions'
import { fetchQuotes, updateFilter } from './reducers/quoteReducer'

function ZobiteAdminQuotesLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const [api, contextHolder] = notification.useNotification()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.zobiteAdmin.quote.total)
  const items = useSelector((state: RootState) => state.zobiteAdmin.quote.items)
  const filter = useSelector((state: RootState) => state.zobiteAdmin.quote.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchQuotes()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onClickRow = (record: any) => {
    navigate(`/zobite-admin/movation-quotes/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/zobite-admin/movation-quotes`)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const onReload = () => {
    fetchData()
    setSelectedRowKeys([])
    api.success({
      message: `Notification`,
      description: <Lang vi='Thành công.' en='Updated successfully.' />,
      placement: 'topRight',
      duration: 2
    })
  }

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      sorter: true
    },
    {
      title: 'Author',
      dataIndex: 'author',
      width: '300px',
      sorter: true
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      sorter: true,
      filters: [
        { text: 'Vietnamese', value: 'vi' },
        { text: 'English', value: 'en' }
      ],
      width: '200px',
      render: (val: string) => <div>{val === 'vi' ? 'Vietnamese' : 'English'}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      filters: [
        { text: 'Published', value: EQuoteStatus.PUBLISHED },
        { text: 'In-Review', value: EQuoteStatus.IN_REVIEW },
        { text: 'Draft', value: EQuoteStatus.DRAFT }
      ],
      width: '200px',
      render: (status: string) => <div>{getEnumKeyName(EQuoteStatus, status)}</div>
    },
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      filterMultiple: false,
      filters: TableCreatedAtFilters,
      render: (val: string) => <div className=''>{formatTime(new Date(val))}</div>
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}

    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.lang)) {
      newFilter = Object.assign(newFilter, {
        lang: `in:${filters.lang.join(',')}`,
        page: 1
      })
    } else if (filters.lang === null) {
      newFilter = Object.assign(newFilter, {
        lang: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.createdAt)) {
      newFilter = Object.assign(newFilter, {
        createdAt: filters.createdAt[0],
        page: 1
      })
    } else if (filters.lang === null) {
      newFilter = Object.assign(newFilter, {
        createdAt: '',
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  return (
    <div className='flex flex-col'>
      {contextHolder}

      <div className='flex flex-row gap-4'>
        <Search />
        <div className='flex-1' />

        <ModalAdd onSuccess={fetchData} />
      </div>

      <div className='mt-8'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          rowSelection={rowSelection}
          dataSource={items}
          rowKey='id'
          columns={columns}
          rowClassName='cursor-pointer'
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <TableActions selectedKeys={selectedRowKeys} onReload={onReload} />

      <Drawer title='Edit Quote' width={640} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default ZobiteAdminQuotesLayout

import { createSlice } from '@reduxjs/toolkit'

interface IState {
  length: number
  masks: string[]
  histories: any[]
}

const initialState: IState = {
  length: 16,
  masks: ['lowercase', 'uppercase', 'numbers', 'symbols'],
  histories: []
}

/*
 *****************************************
 *
 *
 */

export const configSlice = createSlice({
  name: 'generate_secure_password_config',

  initialState,

  reducers: {
    setLength(state, { payload }) {
      state.length = payload
    },

    setMasks(state, { payload }) {
      state.masks = payload
    },

    addHistory(state, { payload }) {
      const index = state.histories.findIndex((item) => item.value === payload)

      if (index < 0) {
        const now = new Date()

        state.histories.push({
          createdAt: now,
          value: payload
        })
      }
    },

    clearHistories(state) {
      state.histories = []
    }
  },

  extraReducers: (_builder) => {}
})

export const { setLength, setMasks, addHistory, clearHistories } = configSlice.actions
export default configSlice.reducer

import React from 'react'
import { BsFolder, BsFolderFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

interface FolderItemProps {
  active: boolean
  info: any
  onContextMenu: (evt: any) => void
}

const FolderItem: React.FC<FolderItemProps> = ({ info, active, onContextMenu }) => {
  return (
    <Link
      to={`/to-do/${info.id}`}
      onContextMenu={onContextMenu}
      className={`cat_item category-item flex cursor-pointer select-none flex-row items-center gap-3 p-4 pl-3.5 ${active ? 'bg-light-2' : 'hover:bg-gray-50'}`}
    >
      {active ? <BsFolderFill className='text-primary-400' size={14} /> : <BsFolder className='text-gray-700' size={14} />}

      <div className={`line-clamp-1 flex-1 pb-[2px] leading-none ${active ? 'text-main' : ''}`}>{info.name}</div>

      <div className='text-sm leading-none'>{info.uncompletedTaskCount === 0 ? '' : info.uncompletedTaskCount}</div>
    </Link>
  )
}

export default FolderItem

import { EUserRole, EUserStatus } from '@shared/common/enum'
import { formatTime, getEnumKeyName } from '@shared/common/utils'
import StatisticCard from '@shared/components/StatisticCard'
import type { RootState } from '@shared/store'
import type { TablePaginationConfig } from 'antd'
import { Drawer, Table, Tag } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { LiaUserSolid } from 'react-icons/lia'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import FilterByTime from './components/FilterByTime'
import Search from './components/Search'
import { fetchUsers, updateFilter } from './reducers/userReducer'

function ZobiteAdminUsersLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.zobiteAdmin.user.total)
  const items = useSelector((state: RootState) => state.zobiteAdmin.user.items)
  const filter = useSelector((state: RootState) => state.zobiteAdmin.user.filter)
  const meta = useSelector((state: RootState) => state.zobiteAdmin.user.meta)

  const fetchData = async () => {
    try {
      await dispatch(fetchUsers()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onClickRow = (record: any) => {
    navigate(`/zobite-admin/users/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/zobite-admin/users`)
  }

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '160px',
      filters: [
        { text: 'Inactive', value: EUserStatus.INACTIVE },
        { text: 'Active', value: EUserStatus.ACTIVE },
        { text: 'Spam', value: EUserStatus.SPAM },
        { text: 'Banned', value: EUserStatus.BANNED },
        { text: 'Trash', value: EUserStatus.TRASH }
      ],
      render: (status: string) => <div>{getEnumKeyName(EUserStatus, status)}</div>
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      sorter: false,
      width: '240px',
      filters: [
        { text: 'Admin', value: EUserRole.ADMIN },
        { text: 'Developer', value: EUserRole.DEVELOPER },
        { text: 'Tester', value: EUserRole.TESTER },
        { text: 'User', value: EUserRole.USER }
      ],
      render: (_: any, { roles }: { roles: number[] }) => (
        <>
          {roles.map((role) => {
            const color = role === 9 ? 'volcano' : 'green'

            return (
              <Tag color={color} key={role}>
                {getEnumKeyName(EUserRole, role)}
              </Tag>
            )
          })}
        </>
      )
    },
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      render: (val: string) => <div className=''>{formatTime(new Date(val))}</div>
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}
    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.roles)) {
      newFilter = Object.assign(newFilter, {
        roles: filters.roles,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        roles: undefined,
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  const googleUserPercent = () => {
    if (meta?.newUserCount && meta?.googleUserCount) {
      return (meta.googleUserCount / meta.newUserCount) * 100
    }

    return '0'
  }

  const newUserPercent = () => {
    if (meta?.newUserCount && meta?.totalCount) {
      return (meta.newUserCount / meta.totalCount) * 100
    }

    return '0'
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-4'>
        <Search />

        <div className='flex-1' />

        <FilterByTime />
      </div>

      <div className='mt-6 grid grid-cols-4 gap-4'>
        <StatisticCard value={meta?.totalCount || 0} title='Total users' icon={<LiaUserSolid size={20} />} />
        <StatisticCard value={meta?.newUserCount || 0} title='New users' changePercent={newUserPercent()} icon={<LiaUserSolid size={20} />} />
        <StatisticCard value={meta?.googleUserCount || 0} title='Google Account' changePercent={googleUserPercent()} icon={<LiaUserSolid size={20} />} />
      </div>

      <div className='mt-8'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          dataSource={items}
          rowKey='id'
          columns={columns}
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <Drawer title={false} width={640} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default ZobiteAdminUsersLayout

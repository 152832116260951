import { parseCSV } from '@shared/utils/helper'
import { Button, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EQuoteStatus } from '../common/constant'
import { createQuote } from '../reducers/quoteReducer'

type Props = {
  onSuccess: () => void
}

const ModalAdd = ({ onSuccess }: Props) => {
  const inputFile = useRef<any>()
  const dispatch = useDispatch<any>()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0]
        const data: any[] = await parseCSV(file)

        for (let index = 1; index < data.length; index++) {
          const rowData = data[index]

          try {
            const message = rowData[0].replaceAll('"', '')
            await dispatch(
              createQuote({
                message,
                author: rowData[1],
                background: rowData[2],
                status: EQuoteStatus.DRAFT,
                lang: 'vi'
              })
            ).unwrap()
          } catch (error) {}
        }
      } catch (error) {
      } finally {
        inputFile.current.value = null
        onSuccess()
        setShow(false)
      }
    }
  }

  return (
    <div>
      <Button
        type='primary'
        onClick={() => {
          setShow(true)
        }}
      >
        Add Quotes
      </Button>

      <Modal className='zo-modal top-10' width='80vw' footer={false} onCancel={handleClose} open={show}>
        <div className='flex min-h-[500px] flex-col'>
          <div>
            <input ref={inputFile} type='file' accept='.csv' onChange={handleFileChange} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAdd

export enum EAppStatus {
  DEVELOPING = 1,
  TESTING = 2,
  RELEASED = 3,
  MAINTAINING = 4
}

export enum EAppType {
  FREE = 1,
  PREMIUM = 2,
  BUSINESS = 3,
  PREMIUM_LIFETIME = 4
}

export enum EAppPlatform {
  MOBILE = 1,
  WEBAPP = 2,
  DESKTOP = 3,
  TELEGRAM_BOT = 4,
  BROWSER_EXTENSION = 5
}

export enum EPackageStatus {
  DRAFT = 1,
  ACTIVE = 2
}

export enum EPackageType {
  ONETIME = 1,
  RECURRING_MONTHLY = 2,
  RECURRING_YEARLY = 3
}

export enum EInvoiceStatus {
  PAID = 1,
  UNPAID = 2,
  PROCESSING = 3,
  CANCELED = 4
}

export enum EUserStatus {
  INACTIVE = 1,
  ACTIVE = 2,
  SPAM = 3,
  BANNED = 4,
  TRASH = 5
}

export enum EUserRole {
  USER = 1,
  TESTER = 2,
  DEVELOPER = 3,
  ADMIN = 9
}

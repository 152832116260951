import type { RootState } from '@shared/store'
import type { PaginationProps } from 'antd'
import { Empty, Pagination } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchApps, updateFilter } from '../reducers/appReducer'
import ListItem from './ListItem'

const BoxExploreApps = () => {
  const dispatch = useDispatch<any>()

  const installedItems = useSelector((state: RootState) => state.installedApp.items)

  const total = useSelector((state: RootState) => state.app.total)
  const items = useSelector((state: RootState) => state.app.items)
  const filter = useSelector((state: RootState) => state.app.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchApps()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [filter])

  const onChangePage: PaginationProps['onChange'] = (page) => {
    dispatch(
      updateFilter({
        page
      })
    )
  }

  const showItems = () => {
    return (
      items.filter((item) => {
        return installedItems.findIndex((_item) => _item.id === item.id) < 0
      }) || []
    )
  }

  return (
    <div>
      {showItems().length > 0 ? (
        <div className='flex flex-col'>
          <div className='grid grid-cols-2 gap-6'>
            {showItems().map((item) => {
              return <ListItem key={item.id} info={item} />
            })}
          </div>

          <div className='mt-8 flex flex-row'>
            <Pagination
              size='small'
              hideOnSinglePage
              total={total}
              showTotal={(_total) => `Total ${_total} items`}
              defaultPageSize={filter.limit}
              defaultCurrent={filter.limit}
              onChange={onChangePage}
            />
          </div>
        </div>
      ) : (
        <div className='min-h-[500px] center'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  )
}

export default BoxExploreApps

import { EAppPlatform } from '@shared/common/enum'
import Papa from 'papaparse'

export function detectCurrentPlatform(): EAppPlatform {
  // Check if running in Electron
  if (typeof window !== 'undefined' && (window as any).process && (window as any).process.type) {
    return EAppPlatform.DESKTOP
  }

  // Check if running in React-Native
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    // return 'React Native'
    return EAppPlatform.MOBILE
  }

  // Check if running as Chrome Extension
  if (typeof window !== 'undefined' && (window as any).chrome && (window as any).chrome.runtime) {
    return EAppPlatform.BROWSER_EXTENSION
  }

  return EAppPlatform.WEBAPP
}

/*
 *****************************************
 *
 *
 */

export async function parseCSV(file: File): Promise<string[][]> {
  return new Promise((resolve, _reject) => {
    Papa.parse<string[]>(file, {
      worker: true,
      complete({ data }) {
        resolve(data)
      }
    })
  })
}

import React from 'react'

const TnoteIndex: React.FC = () => {
  return (
    <div className='flex h-full flex-col items-center pt-20'>
      <h1 className='mb-4 text-4xl font-bold text-gray-800'>Welcome to Tnote</h1>
      <p className='mb-8 text-lg text-gray-600'>Your personal note-taking space</p>
    </div>
  )
}

export default TnoteIndex

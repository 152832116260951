import React from 'react'
import { Outlet } from 'react-router-dom'

const GenerateSecurePasswordLayout = () => {
  return (
    <main className='flex flex-col bg-[#f0f2f5]'>
      <Outlet />
    </main>
  )
}

export default GenerateSecurePasswordLayout

import eventEmitter from '@shared/common/eventEmitter'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { addOrderItemLocal, deleteOrderItemLocal, updateOrderItemLocal } from '../../reducers/orderItemReducer'

type Props = {
  orderId: string
}

const OrderItemEvent = ({ orderId }: Props) => {
  const dispatch = useDispatch<any>()

  /*
   *****************************************
   *
   *
   */

  const onUpdate = (payload: any) => {
    if (payload.orderId !== orderId) {
      return
    }

    dispatch(updateOrderItemLocal(payload))
  }

  const onAdd = (payload: any) => {
    if (payload.orderId !== orderId) {
      return
    }

    dispatch(addOrderItemLocal(payload))
  }

  const onDelete = (payload: any) => {
    if (payload.orderId !== orderId) {
      return
    }

    dispatch(deleteOrderItemLocal(payload))
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    const event1 = 'group_order.order_item.add'
    const event2 = 'group_order.order_item.update'
    const event3 = 'group_order.order_item.delete'

    eventEmitter.on(event1, onAdd)
    eventEmitter.on(event2, onUpdate)
    eventEmitter.on(event3, onDelete)

    return () => {
      eventEmitter.off(event1, onAdd)
      eventEmitter.off(event2, onUpdate)
      eventEmitter.off(event3, onDelete)
    }
  }, [])

  /*
   *****************************************
   *
   *
   */

  return <div />
}

export default OrderItemEvent

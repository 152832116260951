export enum ELogLevel {
  URGENT = 1,
  ERROR = 2,
  DEBUG = 3,
  WARN = 4
}

export enum ELogType {
  SYSTEM = 1
}

/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit'

import appReducer from './partials/apps/reducers/appReducer'
import invoiceReducer from './partials/invoices/reducers/invoiceReducer'
import logReducer from './partials/logs/reducers/logReducer'
import quoteReducer from './partials/quotes/reducers/quoteReducer'
import userReducer from './partials/users/reducers/userReducer'

// const persistConfig = {
//   key: 'admin',
//   storage,
//   whitelist: ['installedApp']
//   // blacklist: ['current'],
// }

const ZobiteAdminAppReducer = combineReducers({
  invoice: invoiceReducer,
  app: appReducer,
  user: userReducer,
  quote: quoteReducer,
  log: logReducer
  // workspace: persistReducer(persistConfig, workspaceReducer),
})

export default ZobiteAdminAppReducer

import React from 'react'

import GroupOrderIndex from './GroupOrderIndex'
import GroupOrderLayout from './layout/GroupOrderLayout'
import OrderDetail from './partials/order/OrderDetail'

export const GroupOrderRoutes = [
  {
    path: 'group-order',
    element: <GroupOrderLayout />,
    children: [
      { path: '', element: <GroupOrderIndex /> },
      { path: ':orderId', element: <OrderDetail /> }
    ]
  }
]

/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit'
import { EAppPlatform } from '@shared/common/enum'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { localStorage } from 'redux-persist-webextension-storage'

import configReducer from './reducers/configReducer'

const persistConfig = {
  key: 'generate_secure_password',
  // @ts-ignore
  storage: Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION ? localStorage : storage
  // whitelist: []
  // blacklist: ['current'],
}

const BookmarkerReducer = combineReducers({
  config: configReducer
})

export default persistReducer(persistConfig, BookmarkerReducer)

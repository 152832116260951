import { message } from 'antd'
import type { ElementRef } from 'react'
import React, { useRef, useState } from 'react'
import { AiOutlinePlus, AiOutlineSearch, AiOutlineSetting } from 'react-icons/ai'
import { IoChevronBack, IoMenuOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { createNote } from '../../reducers/tnoteReducer'
import Item from './components/Item'
import NoteList from './components/NoteList'

const Navigation: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const sidebarRef = useRef<ElementRef<'aside'>>(null)
  const navbarRef = useRef<ElementRef<'div'>>(null)
  const [isResetting, setIsResetting] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const handleMouseMove = (e: MouseEvent) => {
    let newWidth = e.clientX

    if (newWidth < 240) newWidth = 240
    if (newWidth > 480) newWidth = 480

    if (sidebarRef.current && navbarRef.current) {
      sidebarRef.current.style.width = `${newWidth}px`
      navbarRef.current.style.left = `${newWidth}px`
      navbarRef.current.style.width = `calc(100% - ${newWidth}px)`
    }
  }

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const resetWidth = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(false)
      setIsResetting(true)

      sidebarRef.current.style.width = '240px'
      navbarRef.current.style.width = 'calc(100% - 240px)'
      navbarRef.current.style.left = '240px'
      setTimeout(() => setIsResetting(false), 300)
    }
  }

  const collapse = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(true)
      setIsResetting(true)

      sidebarRef.current.style.width = '0'
      navbarRef.current.style.width = '100%'
      navbarRef.current.style.left = '0'
      setTimeout(() => setIsResetting(false), 300)
    }
  }

  const handleCreate = async () => {
    try {
      const rs = await dispatch(createNote()).unwrap()
      if (rs) {
        navigate(`/tnote/${rs.id}`)
      }
    } catch (error) {}
  }

  const handleSettings = () => {
    messageApi.info('Coming soon...')
  }

  return (
    <>
      {contextHolder}
      <aside
        ref={sidebarRef}
        style={{ width: '240px' }}
        className={`group/sidebar relative z-[50] flex h-full flex-col overflow-y-auto bg-[#f7f7f5] ${
          isResetting ? 'transition-all duration-300 ease-in-out' : ''
        }`}
      >
        <div
          onClick={collapse}
          role='button'
          tabIndex={0}
          className='absolute right-2 top-3 h-6 w-6 cursor-pointer rounded-sm text-gray-500 opacity-0 transition hover:bg-gray-200 group-hover/sidebar:opacity-100'
        >
          <IoChevronBack className='h-6 w-6' />
        </div>
        <div className='mx-2 flex flex-col gap-[1px] pt-10'>
          <Item label='Search' icon={<AiOutlineSearch />} onClick={() => {}} />
          <Item label='Settings' icon={<AiOutlineSetting />} onClick={handleSettings} />
          <Item onClick={handleCreate} label='New page' icon={<AiOutlinePlus />} />
        </div>
        <div className='mt-4 px-2'>
          <NoteList />
          {/* <Item onClick={handleCreate} icon={<AiOutlinePlus />} label='Add a page' /> */}
          {/* <Popover content={<TrashBox />} trigger='click' placement='right'>
            <Item label='Trash' icon={<AiOutlineDelete />} />
          </Popover> */}
        </div>
        <div
          onMouseDown={handleMouseDown}
          className='absolute right-0 top-0 h-full w-1 cursor-ew-resize bg-gray-200 opacity-0 transition group-hover/sidebar:opacity-100'
        />
      </aside>
      <div
        ref={navbarRef}
        style={{ left: '240px', width: 'calc(100% - 240px)' }}
        className={`absolute top-0 z-[50] ${isResetting ? 'transition-all duration-300 ease-in-out' : ''}`}
      >
        <nav className={`w-full bg-transparent px-3 py-2 ${!isCollapsed ? 'p-0' : ''}`}>
          {isCollapsed && <IoMenuOutline onClick={resetWidth} role='button' className='h-6 w-6 cursor-pointer rounded-sm text-gray-500 hover:bg-gray-200' />}
        </nav>
      </div>
    </>
  )
}

export default Navigation

import api from '@shared/common/api'
import { Steps } from 'antd'
import React, { useEffect, useState } from 'react'

import CheckoutStep1 from './CheckoutStep1'
import CheckoutStep2 from './CheckoutStep2'

const CheckoutIndex = () => {
  const [info, setInfo] = useState<any>()
  const [step, setStep] = useState(0)
  // const [currentPackage, setCurrentPackage] = useState<any>({})

  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchData = async () => {
    const packageId = new URLSearchParams(window.location.search).get('package')

    try {
      const data: any = await api.post(`invoices/calculate`, {
        packageId
      })

      if (!data) {
        return
      }

      setInfo(data)
      // setCurrentPackage(data.package)
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onChangeStep = (val: any) => {
    setStep(val)
  }

  return (
    <main className='mx-auto flex max-w-4xl flex-col p-6'>
      <div className='flex-col pt-10 center'>
        <div className='text-4xl font-extrabold'>Checkout</div>
      </div>

      <Steps
        className='mt-16'
        current={step}
        items={[
          {
            title: 'Xác nhận'
          },
          {
            title: 'Thanh toán'
            // icon: step === 1 ? <LoadingOutlined /> : null
          },
          {
            title: 'Hoàn thành'
          }
        ]}
      />

      {errorMessage}

      {info && (
        <div>
          {step === 0 && <CheckoutStep1 step={step} calculatedInfo={info} app={info.app} currentPackage={info.package} changeStep={onChangeStep} />}
          {step === 1 && <CheckoutStep2 step={step} calculatedInfo={info} app={info.app} currentPackage={info.package} changeStep={onChangeStep} />}
        </div>
      )}
    </main>
  )
}

export default CheckoutIndex

import '@blocknote/core/style.css'
import '@blocknote/mantine/style.css'

import type { BlockNoteEditor, PartialBlock } from '@blocknote/core'
import { BlockNoteView } from '@blocknote/mantine'
import { useCreateBlockNote } from '@blocknote/react'
import React, { useEffect, useRef, useState } from 'react'

type BlockquoteEditorProps = {
  onContentChange: (content: string) => void
  initialContent?: string
  editable?: boolean
}

const Editor = ({ onContentChange, editable, initialContent }: BlockquoteEditorProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null)
  const [lastUpdatedContent, setLastUpdatedContent] = useState(initialContent || '')
  const editor: BlockNoteEditor = useCreateBlockNote({
    initialContent: initialContent ? (JSON.parse(initialContent) as PartialBlock[]) : undefined
  })

  const handleClickOutside = (event: MouseEvent) => {
    if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
      const currentContent = JSON.stringify(editor.document, null, 2)
      if (currentContent !== lastUpdatedContent) {
        onContentChange(currentContent)
        setLastUpdatedContent(currentContent)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [lastUpdatedContent, editor.document])

  return (
    <div ref={editorRef}>
      <BlockNoteView editable={editable} editor={editor} theme='light' />
    </div>
  )
}

export default Editor

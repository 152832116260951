import Lang from '@shared/components/Lang'
import { Button, Form, Input, Modal, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getOrder, updateOrder } from '../../reducers/orderReducer'

type Props = {
  orderId: string | undefined
  show: boolean
  onSuccess?: () => void
  onClose: () => void
}

const ModalEditOrder = ({ onSuccess, orderId, onClose, show }: Props) => {
  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [info, setInfo] = useState<any>()
  const [loading, setLoading] = useState<boolean>()

  const onFinish = async (formData: any) => {
    const { name, note, link } = formData

    try {
      await dispatch(
        updateOrder({
          id: info.id,
          name,
          note,
          link
        })
      ).unwrap()

      onClose()

      if (onSuccess) {
        onSuccess()
      }
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    setLoading(undefined)
    try {
      if (orderId) {
        const data = await dispatch(
          getOrder({
            id: orderId
          })
        ).unwrap()
        setInfo(data)
      }
    } catch (error: any) {
      // setErrorMessage('Đường dẫn không hợp lệ.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      fetchData()
    }
  }, [show])

  /*
   *****************************************
   *
   *
   */

  return (
    <Modal
      title='Chỉnh sửa đơn hàng'
      className='zo-modal top-10'
      width={700}
      footer={false}
      onCancel={() => {
        onClose()
      }}
      open={show}
    >
      <div className='flex flex-col'>
        {loading === undefined ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div className='px-5 py-6'>
            <Form layout='vertical' onFinish={onFinish} initialValues={info} autoComplete='off'>
              <div className='flex flex-col'>
                <Form.Item label='Tên đơn hàng' name='name' className='flex-1'>
                  <Input ref={myFieldRef} />
                </Form.Item>

                <Form.Item label='Liên kết' name='link' className='flex-1'>
                  <Input />
                </Form.Item>

                <Form.Item label='Ghi chú' name='note' className='flex-1'>
                  <Input.TextArea rows={10} />
                </Form.Item>
              </div>

              <div className='flex flex-row justify-end'>
                <Button loading={loading} type='primary' htmlType='submit'>
                  <Lang vi='Cập nhật' en='Update' />
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalEditOrder

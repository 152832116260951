import React from 'react'

import TnoteLayout from './layout/TnoteLayout'
import TnoteIndex from './TnoteIndex'
import TnoteItemPage from './TnoteItemPage'

export const TnoteRoutes = [
  {
    path: 'tnote',
    element: <TnoteLayout />,
    children: [
      { path: '', element: <TnoteIndex /> },
      {
        path: ':noteId',
        element: <TnoteItemPage />
      }
    ]
  }
]

import Empty from '@shared/layout/Empty'
import type { RootState } from '@shared/store'
import type { PaginationProps } from 'antd'
import { Pagination } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchInstalledApps, updateFilter } from '../reducers/installedAppReducer'
import InstalledListItem from './InstalledListItem'

const BoxInstalledApps = () => {
  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.installedApp.total)
  const items = useSelector((state: RootState) => state.installedApp.items)
  const filter = useSelector((state: RootState) => state.installedApp.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchInstalledApps()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [filter])

  const onChangePage: PaginationProps['onChange'] = (page) => {
    dispatch(
      updateFilter({
        page
      })
    )
  }

  return (
    <div>
      {items.length > 0 ? (
        <div className='flex flex-col'>
          <div className='grid grid-cols-2 gap-6 xl:grid-cols-2'>
            {items.map((item) => {
              return <InstalledListItem key={item.id} info={item} />
            })}
          </div>

          <div className='mt-8 flex flex-row'>
            <Pagination
              size='small'
              hideOnSinglePage
              total={total}
              showTotal={(_total) => `Total ${_total} items`}
              defaultPageSize={filter.limit}
              defaultCurrent={filter.limit}
              onChange={onChangePage}
            />
          </div>
        </div>
      ) : (
        <div className='min-h-[500px] center'>
          <Empty message={`You haven't selected any application to use. Please explore and choose the applications you want to use.`} />
        </div>
      )}
    </div>
  )
}

export default BoxInstalledApps

import { updateProfile } from '@shared/common/reducers/meReducer'
import type IUser from '@shared/common/types/user.type'
import Lang from '@shared/components/Lang'
import SectionTitle from '@shared/components/SectionTitle'
import type { RootState } from '@shared/store'
import { notification } from 'antd'
import type { FC } from 'react'
import React from 'react'
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

const LanguagePage: FC = () => {
  const dispatch = useDispatch<any>()
  const [api, contextHolder] = notification.useNotification()

  const authUser: IUser = useSelector((state: RootState) => state.auth.user)

  const onChangeLanguage = async (lang: string) => {
    try {
      await dispatch(
        updateProfile({
          lang
        })
      ).unwrap()

      api.success({
        message: `Notification`,
        description: <Lang vi='Cập nhật dữ liệu thành công.' en='Updated successfully.' />,
        placement: 'topRight',
        duration: 2
      })
    } catch (error) {}
  }

  const getCurrentLang = () => {
    return authUser.lang
  }

  return (
    <div>
      {contextHolder}

      <SectionTitle title='Languages' titleVi='Ngôn ngữ' />

      <div className='mt-6'>
        <div className='flex flex-col'>
          <button className='mt-4 flex cursor-pointer flex-row items-center justify-start rounded border bg-white p-4' onClick={() => onChangeLanguage('en')}>
            {getCurrentLang() === 'en' ? <IoMdRadioButtonOn size={24} className='text-primary' /> : <IoMdRadioButtonOff size={24} />}

            <div className='ml-2 flex-1 text-left text-base'>English, US</div>

            <div className='text-sm'>English, US</div>

            <img className='ml-2' width='24px' src='/images/svg/lang-en.svg' alt='' />
          </button>

          <button className='mt-4 flex cursor-pointer flex-row items-center justify-start rounded border bg-white p-4' onClick={() => onChangeLanguage('vi')}>
            {getCurrentLang() === 'vi' ? <IoMdRadioButtonOn size={24} className='text-primary' /> : <IoMdRadioButtonOff size={24} />}

            <div className='ml-2 flex-1 text-left text-base'>Tiếng Việt</div>

            <div className='text-sm'>Vietnamese</div>
            <img className='ml-2' width='24px' src='/images/svg/lang-vn.svg' alt='' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default LanguagePage

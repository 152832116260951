import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'

interface IState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
    createdAt?: string
    level?: number
    type?: number
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 20,
    search: '',
    sorts: '-createdAt',
    createdAt: '',
    level: undefined,
    type: undefined
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchLogs = createAsyncThunk('zobite-admin/log/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.zobiteAdmin.log.filter }

    if (!params.createdAt) {
      delete params.createdAt
    } else if (params.createdAt === 'today') {
      const now = new Date()
      const start = startOfDay(now).toISOString()
      const end = endOfDay(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'week') {
      const now = new Date()
      const start = startOfWeek(now).toISOString()
      const end = endOfWeek(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'month') {
      const now = new Date()
      const start = startOfMonth(now).toISOString()
      const end = endOfMonth(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'year') {
      const now = new Date()
      const start = startOfYear(now).toISOString()
      const end = endOfYear(now).toISOString()

      params.createdAt = `range:${start},${end}`
    }

    if (params.search === '') {
      delete params.search
    }

    return await api.get('logManagements', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminLogGetDto = {
  logId: string
}

export const getLog = createAsyncThunk('zobite-admin/log/get', async (payload: AdminLogGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`logManagements/${payload.logId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type AdminLogUpdateDto = {
  id: string
  name: string
}

export const updateLog = createAsyncThunk('zobite-admin/log/update', async (payload: AdminLogUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`logManagements/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminLogDeleteDto = {
  id: string
}

export const deleteLog = createAsyncThunk('zobite-admin/log/delete', async (payload: AdminLogDeleteDto, { rejectWithValue }) => {
  try {
    await api.get(`logManagements/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const logSlice = createSlice({
  name: 'zobite_admin_log',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },
    updateLocal(state, { payload }) {
      const info = payload as any

      const index = state.items.findIndex((item) => item.id === info.id)

      if (index >= 0) {
        const newObject = Object.assign(state.items[index], info)
        state.items.splice(index, 1, newObject)
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(updateLog.fulfilled, (state, action) => {
        const info = action.payload as any

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteLog.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, updateLocal } = logSlice.actions
export default logSlice.reducer

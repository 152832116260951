import { Image } from 'antd'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Outlet } from 'react-router-dom'

const GroupOrderLayout = () => {
  return (
    <main className='flex flex-col'>
      <div className=' border-b border-primary-100 bg-[#fff0e5]'>
        <div className='container flex h-[120px] flex-row items-center gap-4'>
          <div className='h-12 w-12 rounded bg-white p-2 shadow center'>
            <Image className='object-contain' src='/images/app-icons/group-order.svg' preview={false} />
          </div>

          <div className='text-3xl font-black text-primary'>Đặt nhóm</div>
        </div>
      </div>

      <PerfectScrollbar style={{ maxHeight: 'calc(100vh - 121px)' }} options={{ suppressScrollX: true }}>
        <Outlet />
      </PerfectScrollbar>
    </main>
  )
}

export default GroupOrderLayout

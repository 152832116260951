import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'

export interface IAdminAppState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    type?: number
    status?: number
    createdAt?: string
  }
}

const initialState: IAdminAppState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    type: undefined,
    status: undefined,
    sorts: '-createdAt'
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchApps = createAsyncThunk('zobite-admin/app/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.zobiteAdmin.app.filter }

    return await api.get('admin/apps', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminAppGetDto = {
  appId: string
}

export const getApp = createAsyncThunk('zobite-admin/app/get', async (payload: AdminAppGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`admin/apps/${payload.appId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type AdminAppUpdateDto = {
  id: string
  name: string
}

export const updateApp = createAsyncThunk('zobite-admin/app/update', async (payload: AdminAppUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`admin/apps/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminAppDeleteDto = {
  id: string
}

export const deleteApp = createAsyncThunk('zobite-admin/app/delete', async (payload: AdminAppDeleteDto, { rejectWithValue }) => {
  try {
    await api.get(`admin/apps/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const appSlice = createSlice({
  name: 'zobite_admin_app',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchApps.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(deleteApp.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter } = appSlice.actions
export default appSlice.reducer

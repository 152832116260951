import type { ElementRef } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import type { NoteItem } from '../../common/type'
import { updateNote } from '../../reducers/tnoteReducer'

type NoteTitleProps = {
  note: NoteItem
}

const NoteTitle = ({ note }: NoteTitleProps) => {
  const dispatch = useDispatch<any>()
  const inputRef = useRef<ElementRef<'textarea'>>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState(note.title)
  const [lastUpdatedTitle, setLastUpdatedTitle] = useState(note.title)

  const updateNoteTitle = async (value: string) => {
    try {
      await dispatch(
        updateNote({
          noteId: note.id,
          title: value
        })
      )
      setLastUpdatedTitle(value)
    } catch (error) {}
  }

  const onInputChange = (value: string) => {
    setTitle(value)
  }

  const enableInput = () => {
    setIsEditing(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const disableInput = () => {
    setIsEditing(false)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (title.trim() === '') {
        setTitle(lastUpdatedTitle)
      } else {
        updateNoteTitle(title)
      }
      setIsEditing(false)
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      if (isEditing) {
        if (title.trim() === '') {
          setTitle(lastUpdatedTitle)
        } else if (title !== lastUpdatedTitle) {
          updateNoteTitle(title)
        }
        setIsEditing(false)
      }
    }
  }

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEditing, title])

  return (
    <div className='mx-[54px] pb-5 pt-10'>
      {isEditing ? (
        <TextareaAutosize
          ref={inputRef}
          spellCheck='false'
          onBlur={disableInput}
          onKeyDown={onKeyDown}
          value={title}
          onChange={(e) => onInputChange(e.target.value)}
          className='resize-none break-words bg-transparent p-0 font-[inherit] text-[40px] font-bold leading-[48px] text-[#3F3F3F] outline-none'
        />
      ) : (
        <div onClick={enableInput} className='break-words text-[40px] font-bold leading-[48px] text-[#3F3F3F] outline-none'>
          {title}
        </div>
      )}
    </div>
  )
}

export default NoteTitle

/* eslint-disable import/no-extraneous-dependencies */
import api from '@shared/common/api'
import { formatMoney } from '@shared/common/utils'
import { Button, QRCode, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

type Props = {
  step: number
  calculatedInfo: any
  app: any
  currentPackage: any
  changeStep: (step: number) => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckoutStep2 = ({ app, currentPackage, changeStep, calculatedInfo, step }: Props) => {
  const [invoice, setInvoice] = useState<any>()

  const handleCreateInvoice = async () => {
    try {
      const data = await api.post(`invoices`, {
        packageId: currentPackage.id
      })

      if (data) {
        setInvoice(data)
      }
    } catch (error: any) {}
  }

  const handleChangeStatusInvoice = async () => {
    try {
      await api.put(`invoices/${invoice.id}/notice-payment`)
      changeStep(2)
    } catch (error: any) {}
  }

  useEffect(() => {
    handleCreateInvoice()
  }, [])

  return (
    <div className='mt-12 flex flex-row gap-6 border-t pt-8'>
      {invoice ? (
        <div className='mx-auto flex max-w-[640px] flex-1 flex-col'>
          <div className='flex flex-row rounded-lg border'>
            <div className='flex flex-col rounded border-r bg-gray-200 px-6 center'>
              <QRCode errorLevel='H' size={156} iconSize={24} value='https://ant.design/' icon='/images/default/mb_64x64.jpg' className='bg-white' />
            </div>

            <div className='flex flex-1 flex-col'>
              <div className='px-4 py-2.5 font-bold text-[#242dd6]'>Ngân hàng TMCP Quân Đội</div>

              <div className='flex flex-row items-center gap-1 px-4 py-2'>
                <div className=' text-gray-500'>Số tài khoản:</div>
                <CopyToClipboard text='0071000760599'>
                  <Tooltip title='copied' trigger='click'>
                    <div className='cursor-pointer font-semibold'>0071000760599</div>
                  </Tooltip>
                </CopyToClipboard>
              </div>

              <div className='flex flex-row items-center gap-1 px-4 py-2'>
                <div className=' text-gray-500'>Tên tài khoản:</div> <div className='font-semibold'>Công Ty TNHH Phần Mềm Zobite</div>
              </div>

              <div className='flex flex-row items-center gap-1 px-4 py-2'>
                <div className='text-gray-500'>Số tiền:</div>{' '}
                <div className='font-semibold text-red-600'>{`${formatMoney(calculatedInfo.total).replace('đ', '').trim()}`}</div>
              </div>

              <div className='flex flex-row items-center gap-1 px-4 py-2'>
                <div className='text-gray-500'>Nội dung:</div> <div className='font-semibold text-green-600'>{invoice.code}</div>
              </div>
            </div>
          </div>

          <div className='mt-4'>
            <div className='text-sm text-gray-600'>Hãy quét mã QR phía trên để hoàn tất thanh toán.</div>
          </div>

          <Button type='primary' size='large' className='mt-8 w-full' onClick={handleChangeStatusInvoice}>
            Đã thanh toán
          </Button>
        </div>
      ) : (
        <div className='flex h-full flex-1 flex-col center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default CheckoutStep2

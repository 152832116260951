import { convertEnumToSelectOptions, getUpdatedDiff } from '@shared/common/utils'
import { Button, Form, Input, message, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EQuoteStatus } from './common/constant'
import { getQuote, updateQuote } from './reducers/quoteReducer'

const ZobiteAdminQuoteDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const [messageApi, contextHolder] = message.useMessage()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getQuote({
            quoteId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  useEffect(() => {
    if (errorMessage) {
      messageApi.error(errorMessage)
    }
  }, [errorMessage])

  const hideModal = async () => {
    // if (onClose) {
    //   onClose()
    //   setInfo({})
    // }
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
      return
    }

    try {
      setLoading(true)
      await dispatch(
        updateQuote({
          id: info.id,
          ...updatedInfo
        })
      ).unwrap()

      setInfo(Object.assign(info, updatedInfo))

      hideModal()
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {contextHolder}
      {!loading && info?.id ? (
        <Form onValuesChange={handleValuesChange} layout='vertical' onFinish={onFinish} initialValues={info}>
          <Form.Item label='Message' name='message' required>
            <Input />
          </Form.Item>

          <Form.Item label='Author' name='author' required>
            <Input />
          </Form.Item>

          <Form.Item label='Background' name='background' required>
            <Input />
          </Form.Item>

          <Form.Item label='Status' name='status' required>
            <Select options={convertEnumToSelectOptions(EQuoteStatus)} />
          </Form.Item>

          <Form.Item label='Language' name='lang' required>
            <Select
              options={[
                { label: 'Vietnamese', value: 'vi' },
                { label: 'English', value: 'en' }
              ]}
            />
          </Form.Item>

          <div className='gap-4 center'>
            <Button disabled={disabled} loading={loading} className='mt-6' type='primary' htmlType='submit'>
              Update
            </Button>
          </div>
        </Form>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default ZobiteAdminQuoteDetail

import SectionTitle from '@shared/components/SectionTitle'
import type { FC } from 'react'
import React from 'react'

const SettingsNotificationsPage: FC = () => {
  return (
    <div>
      <SectionTitle title='Notifications' titleVi='Thông báo' />

      <div className='mt-6'>xxxxx</div>
    </div>
  )
}

export default SettingsNotificationsPage

import { EAppPlatform, EAppStatus, EAppType } from '@shared/common/enum'
import { getEnumKeyName } from '@shared/common/utils'
import type { RootState } from '@shared/store'
import type { TablePaginationConfig } from 'antd'
import { Drawer, Table, Tag } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { fetchApps, updateFilter } from './reducers/appReducer'

const ZobiteAdminAppsLayout = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.zobiteAdmin.app.total)
  const items = useSelector((state: RootState) => state.zobiteAdmin.app.items)
  const filter = useSelector((state: RootState) => state.zobiteAdmin.app.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchApps()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onClickRow = (record: any) => {
    navigate(`/zobite-admin/apps/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/zobite-admin/apps`)
  }

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Slug',
      dataIndex: 'slug'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        { text: 'Free', value: EAppType.FREE },
        { text: 'Premium', value: EAppType.PREMIUM },
        { text: 'Business', value: EAppType.BUSINESS }
      ],
      width: '160px',
      render: (val: string) => <div>{getEnumKeyName(EAppType, val)}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        { text: 'Developing', value: EAppStatus.DEVELOPING },
        { text: 'Maintaining', value: EAppStatus.MAINTAINING },
        { text: 'Released', value: EAppStatus.RELEASED },
        { text: 'Testing', value: EAppStatus.TESTING }
      ],
      width: '160px',
      render: (val: string) => <div>{getEnumKeyName(EAppStatus, val)}</div>
    },
    {
      title: 'Platforms',
      dataIndex: 'platforms',
      width: '320px',
      render: (_: any, { platforms }: { platforms: number[] }) => (
        <div className='flex flex-row'>
          {platforms.map((platform) => {
            return (
              <Tag color='orange' key={platform}>
                {getEnumKeyName(EAppPlatform, platform)}
              </Tag>
            )
          })}
        </div>
      )
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}
    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.type)) {
      newFilter = Object.assign(newFilter, {
        type: `in:${filters.type.join(',')}`,
        page: 1
      })
    } else if (filters.type === null) {
      newFilter = Object.assign(newFilter, {
        type: undefined,
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  return (
    <div className='flex flex-col'>
      <div>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          dataSource={items}
          rowKey='id'
          columns={columns}
          rowClassName='cursor-pointer'
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <Drawer title='App Detail' width={640} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default ZobiteAdminAppsLayout

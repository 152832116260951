import eventEmitter from '@shared/common/eventEmitter'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { updateOrderLocal } from '../../reducers/orderReducer'

const OrderListEvent = () => {
  const dispatch = useDispatch<any>()

  /*
   *****************************************
   *
   *
   */

  const handleOnUpdateOrder = (payload: any) => {
    dispatch(updateOrderLocal(payload))
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    const event1 = 'group_order.order.update'

    eventEmitter.on(event1, handleOnUpdateOrder)

    return () => {
      eventEmitter.off(event1, handleOnUpdateOrder)
    }
  }, [])

  /*
   *****************************************
   *
   *
   */

  return <div />
}

export default OrderListEvent

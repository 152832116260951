import { getEnumKeyName } from '@shared/common/utils'
import { Spin } from 'antd'
import { pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ELogLevel, ELogType } from './common/constant'
import { getLog } from './reducers/logReducer'

const ZobiteAdminLogDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  const fetchInfo = async () => {
    try {
      if (params.id) {
        setLoading(true)
        const rs = await dispatch(
          getLog({
            logId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getMeta = () => {
    return info?.meta || {}
  }

  const getValue = (key: string, value: string) => {
    if (key === 'type') {
      return getEnumKeyName(ELogType, value)
    }
    if (key === 'level') {
      return getEnumKeyName(ELogLevel, value)
    }
    return value
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  return (
    <div>
      {!loading && info?.id ? (
        <div className='flex flex-col divide-y border'>
          {Object.keys(pick(info, ['type', 'level', 'message'])).map((key) => (
            <div key={key} className='flex flex-row divide-x'>
              <div className='w-3/12 bg-gray-100 p-2 font-medium capitalize'>{key}</div>

              <div className='w-9/12 overflow-hidden whitespace-break-spaces p-2'>{getValue(key, info[key])}</div>
            </div>
          ))}

          <div className='flex flex-col divide-y '>
            {Object.keys(getMeta()).map((key) => (
              <div key={key} className='flex flex-row divide-x'>
                <div className='w-3/12 bg-gray-50 p-2 font-medium'>{key}</div>

                <div className='w-9/12 p-2'>{getMeta()[key]}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='flex h-full flex-1 flex-col center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default ZobiteAdminLogDetail

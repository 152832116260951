import React from 'react'

import ZobiteAdminLayout from './layout/ZobiteAdminLayout'
import ZobiteAdminAppDetail from './partials/apps/AppDetail'
import ZobiteAdminAppsLayout from './partials/apps/Layout'
import ZobiteAdminInvoiceDetail from './partials/invoices/Detail'
import ZobiteAdminInvoicesLayout from './partials/invoices/Layout'
import ZobiteAdminLogDetail from './partials/logs/Detail'
import ZobiteAdminLogsLayout from './partials/logs/Layout'
import ZobiteAdminQuoteDetail from './partials/quotes/Detail'
import ZobiteAdminQuotesLayout from './partials/quotes/Layout'
import ZobiteAdminUsersLayout from './partials/users/Layout'
import ZobiteAdminUserDetail from './partials/users/UserDetail'
import ZobiteAdminIndex from './ZobiteAdminIndex'

export const ZobiteAdminRoutes = [
  {
    path: 'zobite-admin',
    element: <ZobiteAdminLayout />,
    children: [
      { path: '', element: <ZobiteAdminIndex /> },
      {
        path: 'users',
        element: <ZobiteAdminUsersLayout />,
        children: [{ path: ':id', element: <ZobiteAdminUserDetail /> }]
      },
      {
        path: 'apps',
        element: <ZobiteAdminAppsLayout />,
        children: [{ path: ':id', element: <ZobiteAdminAppDetail /> }]
      },
      {
        path: 'invoices',
        element: <ZobiteAdminInvoicesLayout />,
        children: [{ path: ':id', element: <ZobiteAdminInvoiceDetail /> }]
      },
      {
        path: 'logs',
        element: <ZobiteAdminLogsLayout />,
        children: [{ path: ':id', element: <ZobiteAdminLogDetail /> }]
      },
      {
        path: 'movation-quotes',
        element: <ZobiteAdminQuotesLayout />,
        children: [{ path: ':id', element: <ZobiteAdminQuoteDetail /> }]
      }
    ]
  }
]

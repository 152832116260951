import { createChildTask } from '@shared/modules/todo/reducers/childTaskReducer'
import type { InputRef } from 'antd'
import { Input } from 'antd'
import React, { useRef, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

type Props = {
  taskId: string
}

const AddChildTask = ({ taskId }: Props) => {
  const dispatch = useDispatch<any>()
  const [content, setContent] = useState<string>('')
  const inputRef = useRef<InputRef>(null)
  const loadingRef = useRef<boolean>(false)

  const handleEnterKey = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (loadingRef.current) {
        return
      }
      loadingRef.current = true

      try {
        if (loadingRef.current && content) {
          await dispatch(
            createChildTask({
              taskId,
              content
            })
          ).unwrap()

          // Reset and focus input "Add ChildTask"
          setContent('')
          inputRef.current?.focus()
        }
      } catch (error) {
      } finally {
        loadingRef.current = false
      }
    }
  }

  return (
    <div className='flex flex-row items-center py-2 pl-5 pr-3'>
      <BsPlusLg size={14} className='mr-1' />

      <Input
        value={content}
        placeholder='Add child task'
        onChange={(event) => {
          setContent(event.target.value)
        }}
        onPressEnter={handleEnterKey}
        autoFocus
        bordered={false}
        className='text-sm'
        ref={inputRef}
      />
    </div>
  )
}

export default AddChildTask

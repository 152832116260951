import Lang from '@shared/components/Lang'
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { createOrder } from '../../reducers/orderReducer'

type Props = {
  onClose: () => void
}

const ModalAddOrder = ({ onClose }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  // const { lang } = useContext(LangContext) as LangContextType
  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const onFinish = async (formData: any) => {
    const { name, link, note } = formData

    try {
      setLoading(true)
      await dispatch(
        createOrder({
          name,
          link,
          note
        })
      ).unwrap()

      setIsOpenModal(false)

      onClose()
      form.resetFields()
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      form.resetFields()

      setTimeout(() => {
        myFieldRef.current.focus()
      }, 0)
    }
  }, [isOpenModal])

  return (
    <div>
      <button
        className='absolute -top-6 right-0 h-12 w-12 cursor-pointer rounded-full border border-none bg-primary shadow center hover:bg-primary-400'
        onClick={() => {
          setIsOpenModal(true)
        }}
      >
        <HiPlus size={24} className='text-white' />
      </button>

      <Modal
        title='Tạo đơn hàng nhóm'
        className='zo-modal top-10'
        width={700}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false)
        }}
        open={isOpenModal}
      >
        <div className='flex flex-col'>
          <div className='px-5 py-6'>
            <Form form={form} layout='vertical' onFinish={onFinish} autoComplete='off'>
              <div className='flex flex-col'>
                <Form.Item label='Tên đơn hàng' name='name' className='flex-1'>
                  <Input ref={myFieldRef} />
                </Form.Item>

                <Form.Item label='Liên kết' name='link' className='flex-1'>
                  <Input />
                </Form.Item>

                <Form.Item label='Ghi chú' name='note' className='flex-1'>
                  <Input.TextArea rows={10} />
                </Form.Item>
              </div>

              <div>
                <Button loading={loading} type='primary' htmlType='submit'>
                  <Lang vi='Thêm' en='Add' />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddOrder

/* eslint-disable react/no-unstable-nested-components */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { changePassword } from '@shared/common/reducers/meReducer'
import type IUser from '@shared/common/types/user.type'
import Lang from '@shared/components/Lang'
import SectionTitle from '@shared/components/SectionTitle'
import type { RootState } from '@shared/store'
import { Button, Form, Input, message } from 'antd'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SettingsChangePassword: FC = () => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const authUser: IUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)

  const onFinish = async (formData: any) => {
    if (formData.newPassword !== formData.confirmPassword) {
      messageApi.open({
        type: 'error',
        content: 'The new password and the confirmation password do not match.'
      })
      return
    }

    try {
      setLoading(true)
      await dispatch(
        changePassword({
          password: formData.password,
          newPassword: formData.newPassword
        })
      ).unwrap()

      form.resetFields()

      messageApi.open({
        type: 'success',
        content: 'Password updated successfully.'
      })
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  if (!authUser) {
    return <div />
  }

  return (
    <div>
      {contextHolder}

      <SectionTitle title='Change password' titleVi='Đổi mật khẩu' />

      <div className='mt-6 max-w-lg'>
        <Form form={form} className='mt-8' onFinish={onFinish} layout='vertical' autoComplete='off'>
          {!authUser.unsetPassword && (
            <Form.Item
              label={<Lang vi='Mật khẩu hiện tại' en='Password' />}
              name='password'
              rules={[{ required: true, message: 'Please input current password!' }]}
            >
              <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
          )}

          <Form.Item
            label={<Lang vi='Mật khẩu mới' en='New Password' />}
            name='newPassword'
            rules={[{ required: true, message: 'Please input new password!' }]}
          >
            <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item
            label={<Lang vi='Mật khẩu mới' en='Confirm Password' />}
            name='confirmPassword'
            rules={[{ required: true, message: 'Please confirm password!' }]}
          >
            <Input.Password size='large' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item className='mt-10'>
            <Button size='large' type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SettingsChangePassword

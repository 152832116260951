import api from '@shared/common/api'
import { EPackageStatus, EPackageType } from '@shared/common/enum'
import { convertEnumToSelectOptions, formatNumber, getEnumKeyName, getUpdatedDiff } from '@shared/common/utils'
import { Button, Form, Input, Popconfirm, Select } from 'antd'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi2'

type Props = {
  info: any
  onDelete: (packageId: string) => void
}

const PackageListItem = ({ info, onDelete }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      return
    }

    try {
      setLoading(true)
      await api.put(`admin/packages/${info.id}`, updatedInfo)
      setEditing(false)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      await api.delete(`admin/packages/${info.id}`)
      onDelete(info.id)
    } catch (error) {}
  }

  return (
    <div>
      <div className='flex flex-row items-center rounded-sm'>
        <div className='flex flex-1 flex-row items-center gap-3 p-4'>
          <div>{info.name}</div>
        </div>

        <div className='flex-1 p-4'>
          <div>{`${formatNumber(info.price)} vnđ`}</div>
        </div>

        <div className='flex-1 py-4'>{getEnumKeyName(EPackageType, info.type)}</div>

        <div className='w-20 center'>
          {!editing && <HiOutlinePlus onClick={() => setEditing(!editing)} className='cursor-pointer text-tertiary' size={20} />}
          {editing && <HiOutlineMinus onClick={() => setEditing(!editing)} className='cursor-pointer text-tertiary' size={20} />}
        </div>
      </div>

      {editing && (
        <div className='rounded border border-dashed bg-green-100 p-4'>
          <Form layout='vertical' onFinish={onFinish} initialValues={info}>
            <Form.Item label='Name' name='name' className='flex-1'>
              <Input />
            </Form.Item>

            <Form.Item label='Price' name='price' className='flex-1'>
              <Input suffix='vnđ' />
            </Form.Item>

            <Form.Item label='Description' name='description' className='flex-1'>
              <Input />
            </Form.Item>

            <Form.Item label='Content' name='content' className='flex-1'>
              <Input.TextArea rows={5} />
            </Form.Item>

            <div className='flex flex-row gap-6'>
              <Form.Item label='Type' name='type' className='flex-1'>
                <Select options={convertEnumToSelectOptions(EPackageType)} />
              </Form.Item>
            </div>

            <div className='flex flex-row gap-6'>
              <Form.Item label='Status' name='status' className='flex-1'>
                <Select options={convertEnumToSelectOptions(EPackageStatus)} />
              </Form.Item>
            </div>

            <div className='mt-4 flex flex-row gap-4'>
              <Button loading={loading} type='primary' htmlType='submit'>
                Update
              </Button>

              <Popconfirm title='Confirm' overlayInnerStyle={{ width: '260px' }} description='Are you sure?' onConfirm={handleDelete}>
                <Button danger>Delete</Button>
              </Popconfirm>
            </div>
          </Form>
        </div>
      )}
    </div>
  )
}

export default PackageListItem

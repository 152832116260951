import type { ReactNode } from 'react'
import React from 'react'

type Props = {
  className?: string
  children?: ReactNode
  onClick?: () => void
}

const ZoButton = ({ children, className, onClick }: Props) => {
  return (
    <button
      className={`h-10 w-10 min-w-[40px] cursor-pointer rounded bg-gray-200 transition-colors duration-75 ease-in-out center hover:bg-gray-200/80 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default ZoButton

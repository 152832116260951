import { Divider } from 'antd'
import React from 'react'
import { HiOutlineFingerPrint, HiOutlineIdentification, HiOutlineLanguage } from 'react-icons/hi2'
import { Outlet } from 'react-router-dom'

import MenuItem from '../components/MenuItem'

const SettingsLayout = () => {
  return (
    <main className='p-6'>
      <div className='mt-12 flex flex-row gap-6'>
        <div className='flex w-3/12 flex-col items-end'>
          <div className='flex min-w-[240px] flex-col gap-3 pr-6'>
            <MenuItem to='/settings' nameVi='Tài khoản' nameEn='Edit Profile' icon={<HiOutlineIdentification size={20} />} />
            <MenuItem to='/settings/change-password' nameVi='Đổi mật khẩu' nameEn='Change password' icon={<HiOutlineFingerPrint size={20} />} />
            <MenuItem to='/settings/languages' nameVi='Ngôn ngữ' nameEn='Languages' icon={<HiOutlineLanguage size={20} className='text-tertiary' />} />
            {/* <MenuItem to='/settings/notifications' name='Notifications' icon={<HiOutlineBell size={20} className='text-tertiary' />} /> */}

            <Divider />

            {/* <MenuItem to='/settings/invoices' name='Invoices' icon={<HiOutlineCreditCard size={20} className='text-tertiary' />} /> */}

            {/* <a href='' className='flex flex-row items-center gap-2 text-danger ml-4'>
              <HiXMark size={20} />
              Delete Account
            </a> */}
          </div>
        </div>

        <div className='w-3/12 flex-1'>
          <Outlet />
        </div>
      </div>
    </main>
  )
}

export default SettingsLayout

import eventEmitter from '@shared/common/eventEmitter'
import type { RootState } from '@shared/store'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'

const Socket = () => {
  const socketRef = useRef<any>()
  // const authUser = useSelector((state: RootState) => state.auth.user)
  const token = useSelector((state: RootState) => state.auth.token)

  /*
   *****************************************
   *
   *
   */

  const handleSocket = (data: any) => {
    const { action, payload } = data

    if (!action || isEmpty(payload)) {
      return
    }

    eventEmitter.emit(action, payload)

    // if (payload?.userId !== authUser.id) {
    //   return
    // }

    // if (action === 'group_order.order.update') {
    //   eventEmitter.emit(action, payload)
    // }
  }

  const init = () => {
    if (!token) {
      return
    }

    socketRef.current = io(`${process.env.REACT_APP_BASE_API_URL}`, {
      transports: ['websocket'],
      auth: {
        token: `bearer ${token}`
      }
    })

    socketRef.current.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log('connected')
    })

    socketRef.current.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log('disconnected')
    })

    socketRef.current.on('socket.event', handleSocket)
  }
  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    init()

    return () => {
      socketRef.current.disconnect()
      socketRef.current.off('socket.event', handleSocket)
    }
  }, [])

  return <div />
}

export default Socket

import { updateProfile } from '@shared/common/reducers/meReducer'
import type IUser from '@shared/common/types/user.type'
import { getUpdatedDiff } from '@shared/common/utils'
import Lang from '@shared/components/Lang'
import SectionTitle from '@shared/components/SectionTitle'
import type { RootState } from '@shared/store'
import { Button, Form, Input, notification } from 'antd'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SettingsPage: FC = () => {
  const [api, contextHolder] = notification.useNotification()

  const authUser: IUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(authUser, formData)

    if (isEmpty(updatedInfo)) {
      return
    }

    try {
      setLoading(true)
      await dispatch(updateProfile(updatedInfo)).unwrap()

      api.success({
        message: `Notification`,
        description: <Lang vi='Cập nhật dữ liệu thành công.' en='Updated successfully.' />,
        placement: 'topRight',
        duration: 2
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(authUser, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {contextHolder}

      <SectionTitle title='Edit Profile' titleVi='Tài khoản' />

      <div className='mt-6 max-w-lg'>
        <Form className='mt-8' name='me' onValuesChange={handleValuesChange} initialValues={authUser} onFinish={onFinish} layout='vertical' autoComplete='off'>
          <Form.Item label={<Lang vi='Tên đăng nhập' en='Username' />} name='username'>
            <Input readOnly size='large' />
          </Form.Item>

          <Form.Item label='Email' name='email'>
            <Input readOnly size='large' />
          </Form.Item>

          <Form.Item label={<Lang vi='Họ và tên' en='Full Name' />} name='fullname' rules={[{ required: true, message: 'Please input your full name!' }]}>
            <Input size='large' />
          </Form.Item>

          {/* <Form.Item label='Bio' name='description' rules={[{ message: 'Please input your Bio!' }]}>
            <TextArea rows={4} />
          </Form.Item> */}

          <Form.Item className='mt-10'>
            <Button type='primary' htmlType='submit' disabled={disabled} loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SettingsPage

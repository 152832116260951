import { formatMoney, parseMoney } from '@shared/common/formater'
import Lang from '@shared/components/Lang'
import { Button, Form, Input, InputNumber, Modal, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EOrderItemStatus } from '../../common/constant'
import { getOrderItem, updateOrderItem } from '../../reducers/orderItemReducer'

type Props = {
  orderItemId: string | undefined
  onSuccess?: () => void
  onClose?: () => void
}

const ModalEditOrderItem = ({ onSuccess, orderItemId, onClose }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [info, setInfo] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const onFinish = async (formData: any) => {
    const { name, note, price, quantity } = formData

    try {
      setLoading(true)

      await dispatch(
        updateOrderItem({
          id: info.id,
          quantity,
          name,
          note,
          price,
          status: EOrderItemStatus.ORDERED
        })
      ).unwrap()

      setIsOpenModal(false)

      if (onSuccess) {
        onSuccess()
      }
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      // form.resetFields()
    } else if (onClose) {
      onClose()
    }
  }, [isOpenModal])

  const fetchData = async () => {
    setLoading(true)
    try {
      if (orderItemId) {
        const data = await dispatch(
          getOrderItem({
            id: orderItemId
          })
        ).unwrap()
        setInfo(data)
      }
    } catch (error: any) {
      // setErrorMessage('Đường dẫn không hợp lệ.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (orderItemId) {
      setIsOpenModal(true)
      fetchData()
    }
  }, [orderItemId])

  /*
   *****************************************
   *
   *
   */

  return (
    <Modal
      title='Chỉnh sửa sản phẩm'
      className='zo-modal top-10'
      width={600}
      footer={false}
      onCancel={() => {
        setIsOpenModal(false)
      }}
      open={isOpenModal}
    >
      <div className='flex flex-col'>
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div className='px-5 py-6'>
            <Form key='form-edit' layout='vertical' onFinish={onFinish} initialValues={info} autoComplete='off'>
              <div className='flex flex-col'>
                <div className='flex flex-row gap-4'>
                  <Form.Item label='Tên sản phẩm' name='name' className='flex-1' rules={[{ required: true, message: 'Vui lòng điền tên sản phẩm' }]}>
                    <Input ref={myFieldRef} placeholder='Cà Phê sữa size M' />
                  </Form.Item>
                </div>

                <div className='flex flex-row gap-4'>
                  <Form.Item
                    label='Đơn giá'
                    name='price'
                    className='flex-1'
                    rules={[{ required: true, message: 'Vui lòng điền giá tiền hoặc số 0 nếu miễn phí' }]}
                  >
                    <InputNumber placeholder='Giá / 1 sản phẩm' style={{ width: '100%' }} formatter={formatMoney} parser={parseMoney} />
                  </Form.Item>

                  <Form.Item label='Số lượng' name='quantity' rules={[{ required: true, message: 'Vui lòng điền số lượng' }]}>
                    <InputNumber />
                  </Form.Item>
                </div>

                <Form.Item label='Ghi chú' name='note' className='flex-1'>
                  <Input placeholder='ít đường, ít đá...' />
                </Form.Item>
              </div>

              <div className='flex flex-row justify-end'>
                <Button loading={loading} type='primary' htmlType='submit'>
                  <Lang vi='Cập nhật' en='Update' />
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalEditOrderItem

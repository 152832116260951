import type { InputRef } from 'antd'
import { Input } from 'antd'
import React, { useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { createTask } from '../../reducers/taskReducer'

type Props = {
  folderId: string
}

const AddTask = ({ folderId }: Props) => {
  const dispatch = useDispatch<any>()

  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState<string>('')
  const addNewTask = useRef<InputRef>(null)
  const loadingRef = useRef<boolean>(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleEnterKey = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (loadingRef.current) {
        return
      }
      loadingRef.current = true

      try {
        if (loadingRef.current && title) {
          await dispatch(
            createTask({
              folderId,
              title
            })
          ).unwrap()

          // Reset and focus input "Add Task"
          setTitle('')
          addNewTask.current?.focus()
        }
      } catch (error) {
      } finally {
        loadingRef.current = false
      }
    }
  }

  return (
    <div
      onClick={() => setIsEditing(true)}
      onKeyDown={() => setIsEditing(true)}
      className='flex h-12 w-full flex-row items-center rounded-md bg-gray-100 px-2 text-sm shadow-sm'
    >
      <HiPlus size={20} className='mr-1.5 w-6 text-main' />

      {isEditing ? (
        <Input
          value={title}
          placeholder='Add Task'
          onChange={handleInputChange}
          onBlur={() => setIsEditing(false)}
          onKeyDown={handleEnterKey}
          autoFocus
          ref={addNewTask}
          className='flex-1 bg-transparent px-0 text-sm placeholder:text-sm focus:border-none'
        />
      ) : (
        <div className='flex-1 text-sm text-gray-400'>Add Task</div>
      )}
    </div>
  )
}

export default AddTask

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import axios from 'axios'

interface IState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    folderId?: string
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: 'position'
  }
}

/*
 *****************************************
 */

type TaskFetchDto = {
  folderId: string
}

let lastCancelToken: any

export const fetchTasks = createAsyncThunk('todo/task/list', async (payload: TaskFetchDto, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = {
      ...state.todo.task.filter,
      ...payload
    }
    if (lastCancelToken) {
      lastCancelToken.cancel()
    }

    lastCancelToken = axios.CancelToken.source()

    return await api.get('todo/tasks', {
      params,
      cancelToken: lastCancelToken.token
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type TaskGetDto = {
  id: string
}

export const getTask = createAsyncThunk('todo/task/get', async (payload: TaskGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`todo/tasks/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type TaskCreateDto = {
  folderId: string
  title?: string
  position?: number
}

export const createTask = createAsyncThunk('todo/task/create', async (payload: TaskCreateDto, { rejectWithValue }) => {
  try {
    return await api.post(`todo/tasks`, payload)
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type TaskUpdateDto = {
  id: string
  title?: string
  note?: string
  isCompleted?: boolean
}

export const updateTask = createAsyncThunk('todo/task/update', async (payload: TaskUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`todo/tasks/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type TaskUpdatePositionsDto = {
  positions: any[]
}

export const updateTaskPositions = createAsyncThunk('todo/task/update-positions', async (payload: TaskUpdatePositionsDto, { rejectWithValue }) => {
  try {
    await api.put(`todo/tasks/update-positions`, payload)
    return payload
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type TaskDeleteDto = {
  id: string
}

export const deleteTask = createAsyncThunk('todo/task/delete', async (payload: TaskDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`todo/tasks/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

export const taskSlice = createSlice({
  name: 'todo_task',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        state.items = items
        state.total = total
      })
      .addCase(createTask.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        }
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
      .addCase(updateTaskPositions.fulfilled, (state, action) => {
        const { positions } = action.payload as any

        for (const newItem of positions) {
          const findIndex = state.items.findIndex((item) => item.id === newItem.id)

          if (findIndex >= 0) {
            const newObject = Object.assign(state.items[findIndex], newItem)

            state.items.splice(findIndex, 1, newObject)
          }
        }
      })
  }
})

export const { updateFilter } = taskSlice.actions
export default taskSlice.reducer

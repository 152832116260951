import React from 'react'
import { Outlet } from 'react-router-dom'

import AddFolder from '../partials/folder/AddFolder'
import Folders from '../partials/folder/Folders'

const TodoLayout = () => {
  return (
    <main className='flex min-h-screen w-full flex-row'>
      <div className='flex h-screen w-[260px] min-w-[260px] flex-col border-r border-gray-200 bg-white'>
        <div className='flex h-[100px] min-h-[100px] flex-col items-center border-b border-gray-200 center'>
          <img src='/images/app-icons/to-do.svg' width={32} />
          <div className='mt-2 text-[16px] font-bold'>To Do</div>
        </div>

        <Folders />

        <AddFolder />
      </div>

      <div className='flex-1'>
        <Outlet />
      </div>
    </main>
  )
}

export default TodoLayout

import { updateFilter } from '@shared/common/reducers/invoiceReducer'
import type { RootState } from '@shared/store'
import { Select } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const FilterByTime = () => {
  const dispatch = useDispatch<any>()

  const filter = useSelector((state: RootState) => state.invoice.filter)

  const onChange = (value: string) => {
    dispatch(
      updateFilter({
        createdAt: value
      })
    )
  }

  return (
    <Select
      defaultValue={filter.createdAt}
      style={{ width: 160 }}
      onChange={onChange}
      options={[
        { value: '', label: 'All' },
        { value: 'today', label: 'Today' },
        { value: 'week', label: 'This Week' },
        { value: 'month', label: 'This Month' },
        { value: 'year', label: 'This Year' }
      ]}
    />
  )
}

export default FilterByTime

import '@shared/styles/index.scss'

import Error404 from '@shared/layout/Error404'
import Layout from '@shared/layout/Layout'
import UserAppDetail from '@shared/modules/app/UserAppDetail'
import UserAppsLayout from '@shared/modules/app/UserAppsLayout'
import ConfirmEmailPage from '@shared/modules/auth/ConfirmEmailPage'
import GoogleCallbackPage from '@shared/modules/auth/GoogleCallbackPage'
import LoginPage from '@shared/modules/auth/LoginPage'
import RegisterPage from '@shared/modules/auth/RegisterPage'
import ResetPasswordPage from '@shared/modules/auth/ResetPasswordPage'
import CheckoutIndex from '@shared/modules/checkout/CheckoutIndex'
import { GenerateSecurePasswordRoutes } from '@shared/modules/generate_secure_password/GenerateSecurePasswordRoutes'
import { GroupOrderRoutes } from '@shared/modules/group-order/GroupOrderRoutes'
import HomePage from '@shared/modules/home/HomePage'
import InvitePage from '@shared/modules/invite/InvitePage'
import InvoiceDetail from '@shared/modules/settings/invoices/InvoiceDetail'
import InvoiceLayout from '@shared/modules/settings/invoices/InvoiceLayout'
import LanguagePage from '@shared/modules/settings/LanguagePage'
import SettingsLayout from '@shared/modules/settings/layouts/SettingsLayout'
import SettingsChangePassword from '@shared/modules/settings/SettingsChangePassword'
import SettingsNotificationsPage from '@shared/modules/settings/SettingsNotificationsPage'
import SettingsPage from '@shared/modules/settings/SettingsPage'
import { TnoteRoutes } from '@shared/modules/tnote/TnoteRoutes'
import { TodoRoutes } from '@shared/modules/todo/TodoRoutes'
import { ZobiteAdminRoutes } from '@shared/modules/zobite-admin/ZobiteAdminRoutes'
import type { FC } from 'react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

const App: FC = () => {
  const routesConfig = [
    ...ZobiteAdminRoutes,
    ...TodoRoutes,
    ...GenerateSecurePasswordRoutes,
    ...GroupOrderRoutes,
    ...TnoteRoutes
    //
  ]

  const renderRoutes = (routes: any[]) =>
    routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ))

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='register' element={<RegisterPage />} />
        <Route path='oauth/google' element={<GoogleCallbackPage />} />
        <Route path='confirm-email' element={<ConfirmEmailPage />} />
        <Route path='reset-password' element={<ResetPasswordPage />} />

        <Route path='checkout' element={<CheckoutIndex />} />

        <Route path='settings' element={<SettingsLayout />}>
          <Route path='' element={<SettingsPage />} />
          <Route path='change-password' element={<SettingsChangePassword />} />
          <Route path='languages' element={<LanguagePage />} />
          <Route path='notifications' element={<SettingsNotificationsPage />} />

          <Route path='invoices' element={<InvoiceLayout />}>
            <Route path=':id' element={<InvoiceDetail />} />
          </Route>
        </Route>

        <Route path='apps' element={<UserAppsLayout />}>
          <Route path=':id' element={<UserAppDetail />} />
        </Route>

        {renderRoutes(routesConfig)}

        <Route path='*' element={<Error404 />} />
      </Route>

      <Route path='invite/:id' element={<InvitePage />} />
    </Routes>
  )
}

export default App

/* eslint-disable @typescript-eslint/no-unused-vars */
import type { NoteItem } from '@shared/modules/tnote/common/type'
import { deleteNote, duplicateNote } from '@shared/modules/tnote/reducers/tnoteReducer'
import type { RootState } from '@shared/store'
import { App, Divider, Popover } from 'antd'
import { format, parseISO } from 'date-fns'
import type { ReactNode } from 'react'
import React, { useState } from 'react'
import { AiOutlineDown, AiOutlineMore, AiOutlineRight } from 'react-icons/ai'
import { BsLink45Deg } from 'react-icons/bs'
import { IoCopyOutline, IoDocumentTextOutline, IoTrashOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

interface ItemProps {
  data?: NoteItem
  active?: boolean
  expanded?: boolean
  isSearch?: boolean
  level?: number
  onExpand?: () => void
  label?: string
  onClick?: () => void
  className?: string
  icon?: ReactNode
}

const Item: React.FC<ItemProps> = ({ data, label, onClick, icon, active, isSearch, level = 0, className, onExpand, expanded }) => {
  const dispatch = useDispatch<any>()
  const { message } = App.useApp()
  const navigate: NavigateFunction = useNavigate()
  const items = useSelector((state: RootState) => state.tnote.note.items)
  const [popupVisible, setPopupVisible] = useState(false)

  const formatTime = (time: string) => {
    const parsedDate = parseISO(time)
    const formattedDate = format(parsedDate, 'MMM d, yyyy, h:mm a')

    return formattedDate
  }

  const handleExpand = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onExpand?.()
  }

  const handleCreate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    message.loading('Creating new note...')
    setTimeout(() => {
      message.success('Note created!')
      onExpand?.()
    }, 1000)
  }

  const handleDelete = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      await dispatch(deleteNote(data?.id as string))
      message.success('Note deleted!')
      setTimeout(() => {
        const newItems = items.filter((item) => item.id !== data?.id)
        navigate(`/tnote/${newItems[0].id}`)
      }, 500)
    } catch (error) {}
  }

  const handleCopyLink = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    navigator.clipboard.writeText(`${window.location.origin}/tnote/${data?.id}`)
    setPopupVisible(false)
  }

  const hanldeDuplicate = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      const rs = await dispatch(duplicateNote(data?.id as string)).unwrap()
      if (rs) {
        navigate(`/tnote/${rs.id}`)
      }
    } catch (error) {}
  }

  const ChevronIcon = expanded ? AiOutlineDown : AiOutlineRight

  return (
    <div
      onClick={onClick}
      role='button'
      style={{ paddingLeft: level ? `${level * 12 + 12}px` : '12px' }}
      className={`group flex min-h-[30px] w-full cursor-pointer items-center rounded-md py-1 pr-3 text-sm font-medium text-gray-700 hover:bg-[#0000000A] ${className} ${
        active ? 'bg-[#0000000A]' : ''
      }`}
    >
      {/* {!!data?.id && (
        <div role='button' className='mr-1 h-full rounded-sm center hover:bg-gray-300' onClick={handleExpand}>
          <ChevronIcon className='h-4 w-4 shrink-0 text-gray-500' />
        </div>
      )} */}

      {icon || <IoDocumentTextOutline size={18} className='min-w-[18px] text-gray-500' />}

      <span className='ms-2 truncate font-normal'>{label || data?.title}</span>
      {isSearch && (
        <kbd className='font-mono pointer-events-none ml-auto inline-flex h-5 select-none items-center gap-1 rounded border bg-gray-200 px-1.5 text-[.625rem] font-medium text-gray-700'>
          <span className='text-xs'>CTRL</span>K
        </kbd>
      )}
      {!!data?.id && (
        <div className='ml-auto flex items-center gap-1'>
          <Popover
            placement='rightTop'
            style={{ borderRadius: '20px' }}
            arrow={false}
            open={popupVisible}
            onOpenChange={(visible) => setPopupVisible(visible)}
            content={
              <div className='-m-3 py-2'>
                <div onClick={handleCopyLink} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100'>
                  <BsLink45Deg className='mr-2 h-4 w-4' />
                  Copy Link
                </div>

                <div onClick={hanldeDuplicate} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100'>
                  <IoCopyOutline className='mr-2 h-4 w-4' />
                  Duplicate
                </div>

                <div onClick={handleDelete} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100 hover:text-red-400'>
                  <IoTrashOutline className='mr-2 h-4 w-4' />
                  Delete
                </div>
                <Divider className='my-1' />
                <div className='mx-3 flex flex-col gap-y-1 pt-1 text-xs text-gray-500'>
                  <span>Last edited by "Qui Tam"</span>
                  <span className='block'>{data?.updatedAt ? formatTime(data.updatedAt) : ''}</span>
                </div>
              </div>
            }
            trigger='click'
          >
            <div
              role='button'
              onClick={(e) => e.stopPropagation()}
              className='ml-auto h-full rounded-sm opacity-0 center hover:bg-gray-300 group-hover:opacity-100'
            >
              <AiOutlineMore className='h-4 w-4 text-gray-500' />
            </div>
          </Popover>
          {/* <div role='button' onClick={handleCreate} className='ml-auto h-full rounded-sm opacity-0 center hover:bg-gray-300 group-hover:opacity-100'>
            <AiOutlinePlus className='h-4 w-4 text-gray-500' />
          </div> */}
        </div>
      )}
    </div>
  )
}

export default Item

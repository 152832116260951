import { formatMoney, parseMoney } from '@shared/common/formater'
import Lang from '@shared/components/Lang'
import { Button, Form, Input, InputNumber, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { EOrderItemStatus } from '../../common/constant'
import { createOrderItem } from '../../reducers/orderItemReducer'

type Props = {
  orderId: string
}

const ModalAddOrderItem = ({ orderId }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const onFinish = async (formData: any) => {
    const { name, note, price, quantity } = formData

    try {
      setLoading(true)

      await dispatch(
        createOrderItem({
          orderId,
          quantity,
          name,
          note,
          price,
          status: EOrderItemStatus.ORDERED
        })
      ).unwrap()

      setIsOpenModal(false)

      form.resetFields()
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      form.resetFields()

      setTimeout(() => {
        myFieldRef.current.focus()
      }, 0)
    }
  }, [isOpenModal])

  /*
   *****************************************
   *
   *
   */
  return (
    <div>
      <button
        className='absolute -top-6 right-0 z-20 h-12 w-12 cursor-pointer rounded-full border border-none bg-primary shadow center hover:bg-primary-400'
        onClick={() => {
          setIsOpenModal(true)
        }}
      >
        <HiPlus size={24} className='text-white' />
      </button>

      <Modal
        title='Thêm sản phẩm'
        className='zo-modal top-10'
        width={600}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false)
        }}
        open={isOpenModal}
      >
        <div className='flex flex-col'>
          <div className='px-5 py-6'>
            <Form key='form-add' form={form} layout='vertical' onFinish={onFinish} initialValues={{ quantity: 1 }} autoComplete='off'>
              <div className='flex flex-col'>
                <div className='flex flex-row gap-4'>
                  <Form.Item label='Tên sản phẩm' name='name' className='flex-1' rules={[{ required: true, message: 'Vui lòng điền tên sản phẩm' }]}>
                    <Input ref={myFieldRef} placeholder='Cà Phê sữa size M' />
                  </Form.Item>
                </div>

                <div className='flex flex-row gap-4'>
                  <Form.Item
                    label='Đơn giá'
                    name='price'
                    className='flex-1'
                    rules={[{ required: true, message: 'Vui lòng điền giá tiền hoặc số 0 nếu miễn phí' }]}
                  >
                    <InputNumber placeholder='Giá / 1 sản phẩm' style={{ width: '100%' }} formatter={formatMoney} parser={parseMoney} />
                  </Form.Item>

                  <Form.Item label='Số lượng' name='quantity' rules={[{ required: true, message: 'Vui lòng điền số lượng' }]}>
                    <InputNumber />
                  </Form.Item>
                </div>

                <Form.Item label='Ghi chú' name='note' className='flex-1'>
                  <Input placeholder='ít đường, ít đá...' />
                </Form.Item>
              </div>

              <div className='flex flex-row justify-end'>
                <Button loading={loading} type='primary' htmlType='submit'>
                  <Lang vi='Thêm' en='Add' />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddOrderItem

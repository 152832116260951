// import HomeWidgetFolder from 'modules/folder/components/HomeWidgetFolder'
import type { RootState } from '@shared/store'
import { Spin } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const HomePage: FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const installedApps = useSelector((state: RootState) => state.installedApp.items)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (installedApps?.length) {
      navigate(`/${installedApps[0].slug}`)
    } else if (installedApps?.length === 0) {
      navigate('/apps')
    }
  }, [])

  return (
    <main className='center'>
      <Spin />
    </main>
  )
}

export default HomePage

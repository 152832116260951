import Lang from '@shared/components/Lang'
import type { ReactNode } from 'react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  nameEn: string
  nameVi: string
  to: string
  icon?: ReactNode
  className?: string
}

const MenuItem = ({ nameEn, nameVi, to, icon, className }: Props) => {
  const location = useLocation()

  return (
    <Link
      to={to}
      className={`flex cursor-pointer select-none flex-row items-center gap-3 rounded px-4 py-3 text-main transition duration-75 ease-in ${
        location.pathname === to ? 'bg-light-1' : 'hover:bg-light-1'
      } ${className}`}
    >
      {icon}

      <div className='line-clamp-1'>
        <Lang vi={nameVi} en={nameEn} />
      </div>
    </Link>
  )
}

export default MenuItem

/* eslint-disable no-nested-ternary */
import BaseFlatInput from '@shared/components/BaseFlatInput'
import { deleteChildTask, updateChildTask } from '@shared/modules/todo/reducers/childTaskReducer'
import React from 'react'
import { BsCheckCircleFill, BsCircle, BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

type Props = {
  info: any
}

const ChildTaskItem = ({ info }: Props) => {
  const dispatch = useDispatch<any>()

  /*
   *****************************************
   *
   *
   */

  const hanleDeleteChildTask = async () => {
    try {
      await dispatch(deleteChildTask({ id: info.id })).unwrap()
    } catch (error) {}
  }

  const handleUpdate = async (payload: any) => {
    try {
      await dispatch(updateChildTask({ id: info.id, ...payload })).unwrap()
    } catch (error) {}
  }

  const changeIsCompleted = async () => {
    handleUpdate({ isCompleted: !info.isCompleted })
  }

  const updateContent = async (content: string) => {
    handleUpdate({ content })
  }

  return (
    <div className='childtask_item group flex cursor-pointer flex-row items-center gap-3  bg-white py-1 pl-5 pr-3 text-sm text-gray-600 hover:bg-gray-50'>
      <button
        onClick={() => {
          changeIsCompleted()
        }}
        className='cursor-pointer bg-transparent p-0 center'
      >
        {info.isCompleted ? <BsCheckCircleFill size={14} className='text-[#576570]' /> : <BsCircle size={14} className='text-[#576570]' />}
      </button>

      <BaseFlatInput
        noStyle
        value={info.content}
        onChange={updateContent}
        wrapperClassName='bg-transparent flex-1'
        className='bg-transparent py-1.5 text-sm leading-none focus:bg-transparent'
      />

      <button className='hidden h-6 w-6 cursor-pointer rounded-sm bg-transparent hover:bg-gray-200 group-hover:center' onClick={hanleDeleteChildTask}>
        <BsXLg size={20} />
      </button>
    </div>
  )
}

export default ChildTaskItem

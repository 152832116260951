import type { RootState } from '@shared/store'
import { useSelector } from 'react-redux'

type Props = {
  vi: string
  en: string
}

const Lang = ({ vi, en }: Props) => {
  const user = useSelector((state: RootState) => state.auth.user)

  return user?.lang === 'en' ? en : vi
}

export default Lang

import { EInvoiceStatus } from '@shared/common/enum'
import { formatMoney, formatTime, getEnumKeyName } from '@shared/common/utils'
import StatisticCard from '@shared/components/StatisticCard'
import type { RootState } from '@shared/store'
import type { TablePaginationConfig } from 'antd'
import { Drawer, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { LiaFileInvoiceSolid } from 'react-icons/lia'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import FilterByTime from './components/FilterByTime'
import Search from './components/Search'
import { fetchInvoices, updateFilter } from './reducers/invoiceReducer'

function ZobiteAdminInvoicesLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.zobiteAdmin.invoice.total)
  const items = useSelector((state: RootState) => state.zobiteAdmin.invoice.items)
  const filter = useSelector((state: RootState) => state.zobiteAdmin.invoice.filter)
  const meta = useSelector((state: RootState) => state.zobiteAdmin.invoice.meta)

  const fetchData = async () => {
    try {
      await dispatch(fetchInvoices()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onClickRow = (record: any) => {
    navigate(`/zobite-admin/invoices/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/zobite-admin/invoices`)
  }

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: '200px'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        { text: 'Processing', value: EInvoiceStatus.PROCESSING },
        { text: 'Unpaid', value: EInvoiceStatus.UNPAID },
        { text: 'Paid', value: EInvoiceStatus.PAID },
        { text: 'Canceled', value: EInvoiceStatus.CANCELED }
      ],
      width: '200px',
      render: (status: string) => <div>{getEnumKeyName(EInvoiceStatus, status)}</div>
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      sorter: true,
      width: '200px',
      render: (value: number) => <div>{formatMoney(value)}</div>
    },
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      render: (val: string) => <div className=''>{formatTime(new Date(val))}</div>
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}

    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-4'>
        <Search />
        <div className='flex-1' />
        <FilterByTime />
      </div>

      <div className='mt-6 grid grid-cols-4 gap-4'>
        <StatisticCard value={meta?.totalCount ?? ''} title='Total' icon={<LiaFileInvoiceSolid size={20} />} />
        <StatisticCard value={meta?.processingCount ?? ''} title='Processing' icon={<LiaFileInvoiceSolid size={20} />} />
        <StatisticCard value={meta?.unpaidCount ?? ''} title='Unpaid' icon={<LiaFileInvoiceSolid size={20} />} />
        <StatisticCard value={meta?.paidCount ?? ''} title='Paid' icon={<LiaFileInvoiceSolid size={20} />} />
      </div>

      <div className='mt-8'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          dataSource={items}
          rowKey='id'
          columns={columns}
          rowClassName='cursor-pointer'
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <Drawer title={false} width={640} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default ZobiteAdminInvoicesLayout

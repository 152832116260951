import React from 'react'

import GenerateSecurePasswordIndex from './GenerateSecurePasswordIndex'
import GenerateSecurePasswordLayout from './layout/GenerateSecurePasswordLayout'

export const GenerateSecurePasswordRoutes = [
  {
    path: 'generate-secure-password',
    element: <GenerateSecurePasswordLayout />,
    children: [{ path: '', element: <GenerateSecurePasswordIndex /> }]
  }
]

import { SyncOutlined } from '@ant-design/icons'
import { formatMoney } from '@shared/common/formater'
import type IUser from '@shared/common/types/user.type'
import type { RootState } from '@shared/store'
import type { MenuProps, TablePaginationConfig } from 'antd'
import { Avatar, Button, Dropdown, Table, Tag, Tooltip } from 'antd'
import { isEmpty, sortBy } from 'lodash'
import React, { useEffect } from 'react'
import { BsBagCheckFill, BsCheckCircleFill, BsPerson, BsStickies } from 'react-icons/bs'
import { HiOutlineEllipsisVertical } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'

import { EOrderItemPaymentMethod, EOrderItemStatus, EOrderStatus } from '../../common/constant'
import { deleteOrderItem, fetchOrderItems, updateFilter } from '../../reducers/orderItemReducer'

type Props = {
  orderInfo: any
  onEdit?: (id: string) => void
  onMarkAsPaid?: (id: string) => void
}

const OrderTable = ({ orderInfo, onEdit, onMarkAsPaid }: Props) => {
  const dispatch = useDispatch<any>()
  // const params = useParams()
  const authUser: IUser = useSelector((state: RootState) => state.auth.user)
  const total = useSelector((state: RootState) => state.groupOrder.orderItem.total)
  const filter = useSelector((state: RootState) => state.groupOrder.orderItem.filter)
  const items = useSelector((state: RootState) => state.groupOrder.orderItem.items)

  const fetchData = async () => {
    try {
      await dispatch(
        fetchOrderItems({
          orderId: orderInfo.id as any
        })
      ).unwrap()
    } catch (error: any) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  /*
   *****************************************
   *
   *
   */

  const showItems = () => {
    return sortBy(items, 'createdAt')
  }

  /*
   *****************************************
   *
   *
   */
  const menuItems: MenuProps['items'] = [
    {
      label: 'Chỉnh sửa',
      key: 'edit',
      disabled: orderInfo.status !== EOrderStatus.SELECTING && orderInfo.user.id !== authUser.id
    },
    {
      label: 'Báo đã thanh toán',
      key: 'mark_as_paid'
    },
    {
      type: 'divider'
    },
    {
      label: 'Xóa',
      danger: true,
      key: 'delete',
      disabled: orderInfo.status !== EOrderStatus.SELECTING && orderInfo.user.id !== authUser.id
    }
  ]

  const handleEditItem = (id: string) => {
    if (onEdit) {
      onEdit(id)
    }
  }

  const handleDeleteItem = async (id: string) => {
    try {
      await dispatch(deleteOrderItem({ id })).unwrap()
    } catch (error) {}
  }

  const onClickMenu = ({ key, record }: any) => {
    if (key === 'edit') {
      handleEditItem(record.id)
    } else if (key === 'delete') {
      handleDeleteItem(record.id)
    } else if (key === 'mark_as_paid') {
      if (onMarkAsPaid) {
        onMarkAsPaid(record.id)
      }
    }
  }

  /*
   *****************************************
   *
   *
   */

  const calculateFee = (record: any) => {
    return Math.round(orderInfo.fee / (orderInfo.meta?.totalQuantity || 1)) * record.quantity
  }

  const calculateDiscount = (record: any) => {
    if (!orderInfo.discount) {
      return 0
    }

    return Math.floor(((record.quantity * record.price) / (orderInfo.meta?.totalPrice || 1)) * (orderInfo.discount || 1))
  }

  const calculateSumPrice = (record: any) => {
    return record.quantity * record.price + calculateFee(record) - calculateDiscount(record)
  }

  /*
   *****************************************
   *
   *
   */

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns: any = [
    {
      title: '',
      dataIndex: 'user',
      width: '64px',
      render: (user: any) => (
        <div>
          <Tooltip placement='right' title={user.fullname}>
            <Avatar size={42} src={user?.avatarUrl} />
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Tên sản phẩm',
      sorter: false,
      render: (record: any) => (
        <div className='flex flex-1 flex-col gap-1'>
          <div className='flex flex-row items-center gap-1 font-medium '>
            {/* <span className='rounded-sm bg-gray-200 px-1 py-[2px] text-sm leading-none center'>{record.quantity}x </span> */}
            {record.name}
          </div>
          <div className='flex flex-row items-center gap-4 text-sm text-tertiary'>
            <div className='flex flex-row items-center gap-1'>
              <BsPerson size={16} />

              {record.user.fullname}
            </div>
            {record.note && (
              <div className='flex flex-row items-center gap-1'>
                <BsStickies size={12} className='' />
                {record.note}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      width: '120px',
      align: 'right',
      render: (price: string) => <div>{`${formatMoney(price)}`}</div>
    },
    {
      title: 'SL',
      dataIndex: 'quantity',
      width: '80px',
      align: 'center',
      render: (quantity: string) => <div>{`${formatMoney(quantity)}`}</div>
    },
    {
      title: 'Phí',
      width: '120px',
      align: 'right',
      render: (record: any) => <div>{calculateFee(record) ? `+${formatMoney(calculateFee(record))}` : '-'}</div>
    },
    {
      title: 'Giảm giá',
      width: '120px',
      align: 'right',
      render: (record: any) => <div>{calculateDiscount(record) ? `-${formatMoney(calculateDiscount(record))}` : '-'}</div>
    },
    {
      title: 'Tổng tiền',
      width: '130px',
      align: 'right',
      render: (record: any) => <div className='font-medium text-red-600'>{formatMoney(calculateSumPrice(record))}</div>
    },
    {
      title: 'Trạng thái',
      width: '200px',
      render: (record: any) => (
        <div className='text-sm'>
          {record.status === EOrderItemStatus.DRAFT && (
            <Tag icon={<SyncOutlined spin />} color='processing'>
              Đang chọn món
            </Tag>
          )}

          {record.status === EOrderItemStatus.ORDERED && (
            <div className='flex flex-row items-center gap-2 text-main'>
              <BsBagCheckFill />
              Đã chọn món
            </div>
          )}

          {record.status === EOrderItemStatus.PAID && (
            <div>
              {record.paymentMethod === EOrderItemPaymentMethod.MOMO && (
                <span className='flex flex-row items-center gap-2 text-[#d82e8b]'>
                  <BsCheckCircleFill /> Đã MoMo
                </span>
              )}

              {record.paymentMethod === EOrderItemPaymentMethod.CASH && (
                <span className='flex flex-row items-center gap-2 text-[#d82e8b]'>
                  <BsCheckCircleFill />
                  Đã đưa tiền mặt
                </span>
              )}

              {record.paymentMethod === EOrderItemPaymentMethod.TRANSFER && (
                <span className='flex flex-row items-center gap-2 text-[#d82e8b]'>
                  <BsCheckCircleFill />
                  Đã chuyển khoản
                </span>
              )}
            </div>
          )}
        </div>
      )
    },
    {
      title: '',
      width: '48px',
      render: (record: any) => (
        <div className='flex w-12 flex-row justify-end'>
          {(record.user.id === authUser.id || authUser.id === orderInfo.user.id) && (
            <Dropdown
              menu={{
                items: menuItems,
                onClick: ({ key }) => {
                  onClickMenu({ key, record })
                },
                className: 'w-48'
              }}
              placement='bottomRight'
              trigger={['click']}
            >
              <Button className='h-8 w-8 rounded border center' icon={<HiOutlineEllipsisVertical size={16} />} />
            </Dropdown>
          )}
        </div>
      )
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}

    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.createdAt)) {
      newFilter = Object.assign(newFilter, {
        createdAt: filters.createdAt[0],
        page: 1
      })
    } else if (filters.lang === null) {
      newFilter = Object.assign(newFilter, {
        createdAt: '',
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }
  /*
   *****************************************
   *
   *
   */

  /*
   *****************************************
   *
   *
   */
  return (
    <div>
      <Table
        dataSource={showItems()}
        rowKey='id'
        columns={columns}
        className='rounded-t border border-b-0'
        rowClassName='p-0'
        // rowClassName='cursor-pointer'
        showSorterTooltip={false}
        sortDirections={sortDirections}
        onChange={handleTableChange}
        pagination={{ pageSize: filter.limit, current: filter.page, total, hideOnSinglePage: true }}
      />
    </div>
  )
}

export default OrderTable

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'

import type { EQuoteStatus } from '../common/constant'

interface IState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    search?: string
    createdAt?: string
    status?: number
    lang?: string
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 20,
    sorts: '-createdAt',
    createdAt: '',
    search: '',
    status: undefined,
    lang: undefined
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchQuotes = createAsyncThunk('zobite-admin/quote/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.zobiteAdmin.quote.filter }

    if (!params.createdAt) {
      delete params.createdAt
    } else if (params.createdAt === 'today') {
      const now = new Date()
      const start = startOfDay(now).toISOString()
      const end = endOfDay(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'week') {
      const now = new Date()
      const start = startOfWeek(now).toISOString()
      const end = endOfWeek(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'month') {
      const now = new Date()
      const start = startOfMonth(now).toISOString()
      const end = endOfMonth(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'year') {
      const now = new Date()
      const start = startOfYear(now).toISOString()
      const end = endOfYear(now).toISOString()

      params.createdAt = `range:${start},${end}`
    }

    if (params.search === '') {
      delete params.search
    }

    return await api.get('admin/motivation-quotes', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminQuoteGetDto = {
  quoteId: string
}

export const getQuote = createAsyncThunk('zobite-admin/quote/get', async (payload: AdminQuoteGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`admin/motivation-quotes/${payload.quoteId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * CREATE
 *
 */

type AdminQuoteCreateDto = {
  background: string
  message: string
  author: string
  status: EQuoteStatus
  lang: string
}

export const createQuote = createAsyncThunk('zobite-admin/quote/create', async (payload: AdminQuoteCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`admin/motivation-quotes`, payload)
    return { ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type AdminQuoteUpdateDto = {
  id: string
  name: string
}

export const updateQuote = createAsyncThunk('zobite-admin/quote/update', async (payload: AdminQuoteUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`admin/motivation-quotes/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminQuoteDeleteDto = {
  id: string
}

export const deleteQuote = createAsyncThunk('zobite-admin/quote/delete', async (payload: AdminQuoteDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`admin/motivation-quotes/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const quoteSlice = createSlice({
  name: 'zobite_admin_quote',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },
    updateLocal(state, { payload }) {
      const info = payload as any

      const index = state.items.findIndex((item) => item.id === info.id)

      if (index >= 0) {
        const newObject = Object.assign(state.items[index], info)
        state.items.splice(index, 1, newObject)
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        const info = action.payload as any

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteQuote.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, updateLocal } = quoteSlice.actions
export default quoteSlice.reducer

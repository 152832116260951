import React from 'react'

import BoxGenerator from './components/BoxGenerator'

const GenerateSecurePasswordIndex = () => {
  return (
    <div className='mx-auto mt-8 flex w-full max-w-[500px] flex-col'>
      <div className='text-2xl font-bold text-main'>Generate Secure Password</div>

      <div className='mt-6 overflow-hidden rounded border-2 border-main'>
        <BoxGenerator />
      </div>

      {/* <BoxHistory /> */}
    </div>
  )
}

export default GenerateSecurePasswordIndex

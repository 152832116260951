import { EAppPlatform, EAppStatus, EAppType } from '@shared/common/enum'
import { convertEnumToSelectOptions, getUpdatedDiff } from '@shared/common/utils'
import { Button, Form, Input, Select, Spin, Tabs } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Packages from './components/Packages'
import { getApp, updateApp } from './reducers/appReducer'

const ZobiteAdminAppDetail = () => {
  const dispatch = useDispatch<any>()
  const [form] = Form.useForm()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const [currentTab, setCurrentTab] = useState('general')

  const fetchInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getApp({
            appId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  const hideModal = async () => {
    // if (onClose) {
    //   onClose()
    //   setInfo({})
    // }
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
      return
    }

    try {
      setLoading(true)
      await dispatch(
        updateApp({
          id: info.id,
          ...updatedInfo
        })
      ).unwrap()

      hideModal()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  const onChangeTab = (key: string) => {
    setCurrentTab(key)
  }

  return (
    <div>
      <Tabs
        className='zo-tabs w-full'
        items={[
          {
            key: 'general',
            label: 'Information'
          },
          {
            key: 'package',
            label: 'Package'
          },
          {
            key: 'members',
            label: 'Members'
          },
          {
            key: 'statistics',
            label: 'Statistics'
          }
        ]}
        onChange={onChangeTab}
      />

      {!loading && info?.id ? (
        <div className='mt-8'>
          {currentTab === 'general' && (
            <div>
              <Form onValuesChange={handleValuesChange} form={form} layout='vertical' onFinish={onFinish} initialValues={info}>
                <Form.Item label='Slug' name='slug'>
                  <Input readOnly />
                </Form.Item>

                <Form.Item label='Name' name='name'>
                  <Input />
                </Form.Item>

                <Form.Item label='Description' name='description'>
                  <Input />
                </Form.Item>

                <Form.Item label='Trial days' name='trialDays'>
                  <Input />
                </Form.Item>

                <Form.Item label='Status' name='status'>
                  <Select options={convertEnumToSelectOptions(EAppStatus)} />
                </Form.Item>

                <Form.Item label='Type' name='type'>
                  <Select options={convertEnumToSelectOptions(EAppType)} />
                </Form.Item>

                <Form.Item label='platforms' name='platforms'>
                  <Select mode='multiple' options={convertEnumToSelectOptions(EAppPlatform)} />
                </Form.Item>

                <div className='center'>
                  <Button disabled={disabled} loading={loading} className='mt-6' type='primary' htmlType='submit'>
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {currentTab === 'package' && (
            <div>
              <Packages app={info} />
            </div>
          )}
        </div>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default ZobiteAdminAppDetail

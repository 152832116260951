import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import Navbar from './Navbar'

const MainContent: React.FC = () => {
  const { noteId } = useParams()

  return (
    <div className='flex h-screen flex-1 flex-col overflow-y-auto'>
      {noteId && <Navbar />}
      <div className='mx-auto w-full max-w-[816px] flex-1'>
        <Outlet />
      </div>
    </div>
  )
}

export default MainContent

import api from '@shared/common/api'
import { EPackageStatus, EPackageType } from '@shared/common/enum'
import { convertEnumToSelectOptions } from '@shared/common/utils'
import { Button, Form, Input, Select } from 'antd'
import React, { useState } from 'react'
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi2'

type Props = {
  appId: string
  onSuccess?: () => void
}

const AddPackage = ({ appId, onSuccess }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)

  const onFinish = async (formData: any) => {
    try {
      setLoading(true)

      await api.post(`admin/packages`, {
        appId,
        ...formData
      })

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='flex select-none flex-col gap-4 rounded border border-dashed border-gray-200 bg-gray-50 p-4'>
      <div className='flex flex-row items-center'>
        <div className='font-semibold text-main'>Add package</div>
        <div className='flex-1' />
        <div className='center'>
          {!editing && <HiOutlinePlus onClick={() => setEditing(!editing)} className='cursor-pointer text-tertiary' size={20} />}
          {editing && <HiOutlineMinus onClick={() => setEditing(!editing)} className='cursor-pointer text-tertiary' size={20} />}
        </div>
      </div>

      {editing && (
        <Form layout='vertical' onFinish={onFinish} initialValues={{ status: EPackageStatus.DRAFT, days: 30, type: EPackageType.ONETIME }}>
          <Form.Item label='Name' name='name' className='flex-1'>
            <Input />
          </Form.Item>

          <Form.Item label='Price' name='price' className='flex-1'>
            <Input suffix='vnđ' />
          </Form.Item>

          <Form.Item label='Description' name='description' className='flex-1'>
            <Input />
          </Form.Item>

          <Form.Item label='Content' name='content' className='flex-1'>
            <Input.TextArea rows={5} />
          </Form.Item>

          <div className='flex flex-row gap-6'>
            <Form.Item label='Type' name='type' className='flex-1'>
              <Select options={convertEnumToSelectOptions(EPackageType)} />
            </Form.Item>
          </div>

          <div className='flex flex-row gap-6'>
            <Form.Item label='Status' name='status' className='flex-1'>
              <Select options={convertEnumToSelectOptions(EPackageStatus)} />
            </Form.Item>
          </div>

          <div>
            <Button loading={loading} className='mt-4' type='primary' htmlType='submit'>
              Add
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default AddPackage

import { formatTime, getEnumKeyName } from '@shared/common/utils'
import type { RootState } from '@shared/store'
import type { TablePaginationConfig } from 'antd'
import { Drawer, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { ELogLevel, ELogType } from './common/constant'
import FilterByTime from './components/FilterByTime'
import Search from './components/Search'
import { fetchLogs, updateFilter } from './reducers/logReducer'

function ZobiteAdminLogsLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.zobiteAdmin.log.total)
  const items = useSelector((state: RootState) => state.zobiteAdmin.log.items)
  const filter = useSelector((state: RootState) => state.zobiteAdmin.log.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchLogs()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onClickRow = (record: any) => {
    navigate(`/zobite-admin/logs/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/zobite-admin/logs`)
  }

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns = [
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      render: (val: string) => <div className=''>{formatTime(new Date(val))}</div>
    },
    {
      title: 'Message',
      dataIndex: 'message',
      render: (val: string) => <div className='line-clamp-3'>{val}</div>
    },
    {
      title: 'Level',
      dataIndex: 'level',
      filters: [
        { text: 'Debug', value: ELogLevel.DEBUG },
        { text: 'Error', value: ELogLevel.ERROR },
        { text: 'Urgent', value: ELogLevel.URGENT },
        { text: 'Warn', value: ELogLevel.WARN }
      ],
      width: '160px',
      render: (val: string) => <div>{getEnumKeyName(ELogLevel, val)}</div>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [{ text: 'System', value: ELogType.SYSTEM }],
      width: '160px',
      render: (status: string) => <div>{getEnumKeyName(ELogType, status)}</div>
    }
  ]

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}
    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.type)) {
      newFilter = Object.assign(newFilter, {
        type: `in:${filters.type.join(',')}`,
        page: 1
      })
    } else if (filters.type === null) {
      newFilter = Object.assign(newFilter, {
        type: undefined,
        page: 1
      })
    }

    if (!isEmpty(filters.level)) {
      newFilter = Object.assign(newFilter, {
        level: `in:${filters.level.join(',')}`,
        page: 1
      })
    } else if (filters.level === null) {
      newFilter = Object.assign(newFilter, {
        level: undefined,
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-4'>
        <Search />

        <div className='flex-1' />

        <FilterByTime />
      </div>

      <div className='mt-8'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          dataSource={items}
          rowKey='id'
          rowClassName='cursor-pointer'
          columns={columns}
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <Drawer title='Log Detail' width='70%' placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default ZobiteAdminLogsLayout

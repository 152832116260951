import { EInvoiceStatus } from '@shared/common/enum'
import { formatMoney, getEnumKeyName } from '@shared/common/utils'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  info: any
}

const ListItem = ({ info }: Props) => {
  return (
    <div className='flex flex-row items-center rounded-sm hover:bg-gray-50'>
      <Link to={`/settings/invoices/${info.id}`} className='flex flex-1 flex-row items-center '>
        <div className='flex-1 p-4'>
          <div>{info.code}</div>
        </div>

        <div className='flex-1 p-4'>
          <div>{getEnumKeyName(EInvoiceStatus, info.status)}</div>
        </div>

        <div className='flex-1 py-4'>{formatMoney(info.total)}</div>
      </Link>

      {/* <div className='w-20 center'>Detail</div> */}
    </div>
  )
}

export default ListItem

import { ExclamationCircleFilled } from '@ant-design/icons'
import type { LangContextType } from '@shared/common/context/TranslateContext'
import { LangContext } from '@shared/common/context/TranslateContext'
import { reArrangeArray } from '@shared/common/utils'
import BaseDraggable from '@shared/components/BaseDraggable'
import Lang from '@shared/components/Lang'
import type { RootState } from '@shared/store'
import { Modal } from 'antd'
import React, { useContext, useEffect, useRef } from 'react'
import { Item, Menu, useContextMenu } from 'react-contexify'
import { BsTrash } from 'react-icons/bs'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { deleteFolder, fetchFolders, updateFolderPositions } from '../../reducers/folderReducer'
import FolderItem from './FolderItem'

const CONTEXIFY_MENU_ID = 'menu-folders'

const Folders = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  const navigate: NavigateFunction = useNavigate()
  const { lang } = useContext(LangContext) as LangContextType
  const { show } = useContextMenu()

  const refShowItems = useRef<any>()
  const items = useSelector((state: RootState) => state.todo.folder.items)
  const lastFolderId = useSelector((state: RootState) => state.todo.folder.current)

  const fetchData = async () => {
    try {
      const data = await dispatch(fetchFolders()).unwrap()

      if (!params.folderId && data?.items[0]?.id && !lastFolderId) {
        navigate(`/to-do/${data.items[0].id}`)
      }
    } catch (error: any) {
      if (error.message === 'Rejected') {
        // do no thing
      }
    }
  }

  useEffect(() => {
    fetchData()

    if (!params.folderId && lastFolderId) {
      const index = items.findIndex((item) => item.id === lastFolderId)
      if (index >= 0) {
        navigate(`/to-do/${lastFolderId}`)
      }
    }
  }, [])

  useEffect(() => {
    refShowItems.current = [...items]
  }, [items])

  const onEndDragLink = async (event: any) => {
    const { newIndex, oldIndex } = event

    if (newIndex === oldIndex) {
      return
    }

    const newArr = reArrangeArray(refShowItems.current, oldIndex, newIndex)
    const positions = newArr.map((item, i) => {
      return {
        id: item.id,
        position: i
      }
    })

    try {
      await dispatch(updateFolderPositions({ positions })).unwrap()
    } catch (error) {}
  }

  /*
   *****************************************
   *
   *
   */

  const handleDeleteFolder = async (folderId: string) => {
    try {
      await dispatch(
        deleteFolder({
          id: folderId
        })
      ).unwrap()

      navigate(`/to-do/${refShowItems.current.length ? refShowItems.current[0].id : ''}`)
    } catch (error) {}
  }

  const onDeleteItem = (id: string) => {
    Modal.confirm({
      title: lang('Are you sure delete this folder?', 'Bạn chắc chắn muốn xóa thư mục này không?'),
      icon: <ExclamationCircleFilled />,
      content: lang(
        'When you delete this folder, all bookmark data of the folder will be deleted and cannot be restored.',
        'Khi bạn xóa thư mục này, tất cả đường dẫn thuộc thư mục này sẽ bị xóa và không thể khôi phục.'
      ),
      okText: lang('Delete', 'Xóa'),
      okType: 'danger',
      cancelText: lang('Cancel', 'Hủy'),
      onOk() {
        return handleDeleteFolder(id)
      },
      onCancel() {}
    })
  }

  /*
   *****************************************
   *
   *
   */

  const displayMenu = (evt: any, folderInfo: any) => {
    show({
      id: CONTEXIFY_MENU_ID,
      event: evt,
      props: { ...folderInfo }
    })
  }

  const handleRightClickItem = (evt: any) => {
    const { id } = evt.props

    if (evt.id === 'delete') {
      onDeleteItem(id)
    }
  }

  return (
    <div className='flex-1'>
      <ReactPerfectScrollbar style={{ maxHeight: 'calc(100vh - 100px - 48px)' }} options={{ suppressScrollX: true }}>
        <BaseDraggable className='flex flex-col py-2' group='group-todo' dragClass='.cat_item' onEnd={onEndDragLink}>
          {items.map((item: any) => {
            return <FolderItem onContextMenu={(evt: any) => displayMenu(evt, item)} active={params.folderId === item.id} key={item.id} info={item} />
          })}
        </BaseDraggable>
      </ReactPerfectScrollbar>
      {/*  */}

      <Menu id={CONTEXIFY_MENU_ID} animation='fade'>
        <Item id='delete' onClick={handleRightClickItem}>
          <div className='flex flex-row items-center text-sm'>
            <div className='flex w-5 flex-row items-center'>
              <BsTrash size={14} />
            </div>

            <Lang vi='Xóa' en='Delete' />
          </div>
        </Item>
      </Menu>
    </div>
  )
}

export default Folders

import { formatMoney } from '@shared/common/utils'
import { Avatar } from 'antd'
import React from 'react'
import { FaFileInvoiceDollar } from 'react-icons/fa6'
import { HiPlay } from 'react-icons/hi2'
import { IoBagCheck } from 'react-icons/io5'

import { getStatusName } from '../../common/helper'

type Props = {
  info: any
}

const OrderOverview = ({ info }: Props) => {
  /*
   *****************************************
   *
   *
   */

  return (
    <div className='flex flex-col gap-6'>
      <div className=''>
        <div className='mt-4 rounded border border-dashed px-4 py-3'>
          <div className='flex flex-row items-center gap-1'>
            ❒ Liên kết:
            <a href={info.link} target='_blank' rel='noopener noreferrer' className='cursor-pointer text-primary hover:underline'>
              {info.link}
            </a>
          </div>

          <div className='mt-2 flex flex-row items-center gap-1'>
            ❒ Trạng thái:
            <div>{getStatusName(info)}</div>
          </div>

          <div className='mt-5 font-semibold'>Ghi chú:</div>
          <div className='mt-2 whitespace-pre-line leading-6'>{info.note}</div>
        </div>
      </div>

      {/*  */}
      <div className='flex flex-row overflow-hidden rounded border border-primary-200'>
        <div className='flex flex-col items-center bg-primary-50 px-20 py-8'>
          <Avatar size={48} src={info.user?.avatarUrl} />
          <div className='mt-3 text-lg font-semibold'>{info.user?.fullname}</div>
          <div className='text-xs text-tertiary'>Người tạo nhóm</div>
        </div>

        <div className='flex flex-1 flex-row items-center pl-2'>
          <div className='flex flex-1 flex-col p-5'>
            <div className='h-10 w-10 rounded border center'>
              <FaFileInvoiceDollar size={20} />
            </div>

            <div className='mt-2 text-tertiary'>Tổng tiền</div>
            <div className='mt-3 text-2xl font-bold'>{formatMoney(info.meta?.totalPrice || 0, '') || 0}</div>
          </div>

          <div className='flex flex-1 flex-col p-5'>
            <div className='h-10 w-10 rounded border center'>
              <HiPlay size={20} />
            </div>

            <div className='mt-2 text-tertiary'>Tổng món</div>
            <div className='mt-3 flex flex-row items-center text-2xl font-bold'>
              <span>{info.meta?.totalQuantity || 0}</span>
            </div>
          </div>

          <div className='flex flex-1 flex-col p-5'>
            <div className='h-10 w-10 rounded border center'>
              <IoBagCheck size={20} />
            </div>

            <div className='mt-2 text-tertiary'>Đã thanh toán</div>
            <div className='mt-3 flex flex-row items-center text-2xl font-bold'>
              <span>{info.meta?.countPaidQuantity || 0}</span>
              <span>/</span>
              <span>{info.meta?.totalQuantity || 0}</span>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  )
}

export default OrderOverview

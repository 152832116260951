import { ExclamationCircleFilled } from '@ant-design/icons'
import type { LangContextType } from '@shared/common/context/TranslateContext'
import { LangContext } from '@shared/common/context/TranslateContext'
import eventEmitter from '@shared/common/eventEmitter'
import type IUser from '@shared/common/types/user.type'
import type { RootState } from '@shared/store'
import type { MenuProps } from 'antd'
import { Button, Dropdown, Modal, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsBagHeart, BsBrush, BsTrash } from 'react-icons/bs'
import { HiChevronLeft, HiOutlineEllipsisVertical } from 'react-icons/hi2'
import { PiStorefrontDuotone } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { EOrderStatus } from '../../common/constant'
import { deleteOrder, getOrder } from '../../reducers/orderReducer'
import ModalAddOrderItem from '../order_item/ModalAddOrderItem'
import ModalEditOrderItem from '../order_item/ModalEditOrderItem'
import ModalMarkAsPaid from '../order_item/ModalMarkAsPaid'
import OrderItemEvent from '../order_item/OrderItemEvent'
import ModalEditOrder from './ModalEditOrder'
import ModalOrderStatus from './ModalOrderStatus'
import ModalShareOrder from './ModalShareOrder'
import OrderOverview from './OrderOverview'
import OrderTable from './OrderTable'

const OrderDetail = () => {
  const params = useParams()
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const { lang } = useContext(LangContext) as LangContextType

  const authUser: IUser = useSelector((state: RootState) => state.auth.user)

  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  const [showModalChangeStatus, setShowModalChangeStatus] = useState<boolean>(false)
  const [editingItemId, setEditingItemId] = useState<string>()
  const [markAsPaidItemId, setmarkAsPaidItemId] = useState<string>()
  const infoRef = useRef<any>()
  const [info, setInfo] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const fetchData = async () => {
    try {
      if (params.orderId) {
        const data = await dispatch(
          getOrder({
            id: params.orderId
          })
        ).unwrap()

        setInfo(data)
        infoRef.current = { ...data }
      }
    } catch (error: any) {
      setErrorMessage('Đường dẫn không hợp lệ.')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  /*
   *****************************************
   * Socket
   *
   */

  const handleOnUpdateOrder = (payload: any) => {
    if (payload?.id === infoRef?.current?.id) {
      setInfo((prevInfo: any) => {
        return { ...prevInfo, ...payload }
      })
    }
  }

  useEffect(() => {
    const event1 = 'group_order.order.update'

    eventEmitter.on(event1, handleOnUpdateOrder)

    return () => {
      eventEmitter.off(event1, handleOnUpdateOrder)
    }
  }, [])

  /*
   *****************************************
   *
   *
   */

  const menuItems: MenuProps['items'] = [
    {
      icon: <BsBrush />,
      label: 'Chỉnh sửa',
      key: 'edit'
    },
    {
      icon: <BsBagHeart />,
      label: 'Chuyển trạng thái',
      key: 'update_status'
    },
    {
      type: 'divider'
    },
    {
      icon: <BsTrash />,
      label: 'Xóa',
      danger: true,
      key: 'delete'
    }
  ]

  const handleDeleteItem = async () => {
    try {
      await dispatch(
        deleteOrder({
          id: info.id
        })
      ).unwrap()

      navigate('/group-order')
    } catch (error) {}
  }

  const onClickMenu: MenuProps['onClick'] = ({ key }) => {
    if (key === 'edit') {
      setShowModalEdit(true)
    } else if (key === 'update_status') {
      setShowModalChangeStatus(true)
    } else if (key === 'delete') {
      Modal.confirm({
        title: lang('Confirm', 'Xác nhận'),
        icon: <ExclamationCircleFilled />,
        content: lang('Are you sure delete this item?', 'Bạn chắc chắn muốn xóa đơn hàng này?'),
        okText: lang('Delete', 'Xóa'),
        okType: 'danger',
        cancelText: lang('Cancel', 'Hủy'),
        onOk() {
          return handleDeleteItem()
        },
        onCancel() {}
      })
    }
  }

  return (
    <div className='flex h-full min-h-screen flex-1 flex-col'>
      {!isEmpty(info) && (
        <div className='fixed left-[72px] right-0 top-[120px] z-10'>
          <div className='container relative'>{info.status === EOrderStatus.SELECTING && <ModalAddOrderItem orderId={info.id} />}</div>

          <OrderItemEvent orderId={info.id} />

          <ModalEditOrder
            orderId={info.id}
            show={showModalEdit}
            onClose={() => {
              setShowModalEdit(false)
            }}
          />

          <ModalOrderStatus
            orderInfo={info}
            show={showModalChangeStatus}
            onClose={() => {
              setShowModalChangeStatus(false)
            }}
          />
        </div>
      )}

      {!isEmpty(info) ? (
        <div className='container relative flex h-full flex-col pb-10'>
          <ModalEditOrderItem
            orderItemId={editingItemId}
            onSuccess={() => {
              setEditingItemId(undefined)
            }}
            onClose={() => {
              setEditingItemId(undefined)
            }}
          />

          <ModalMarkAsPaid
            orderItemId={markAsPaidItemId}
            onSuccess={() => {
              setmarkAsPaidItemId(undefined)
            }}
            onClose={() => {
              setmarkAsPaidItemId(undefined)
            }}
          />

          <div className='mt-4 flex flex-row items-center gap-1.5 text-sm'>
            <Link to='/group-order' className='gap-2 center hover:underline'>
              <HiChevronLeft />
              Quay lại
            </Link>
          </div>

          <div className='mt-5'>
            <div className='flex flex-col'>
              <div className='flex flex-row items-center gap-2'>
                <PiStorefrontDuotone size={24} />
                <div className='text-2xl font-black leading-none'>{info.name}</div>
                <div className='flex-1' />

                <ModalShareOrder orderId={info?.id} />

                {authUser.id === info.user.id && (
                  <Dropdown menu={{ items: menuItems, onClick: onClickMenu, className: 'w-56' }} placement='bottomRight' trigger={['click']}>
                    <Button className='h-10 w-10 rounded center hover:bg-gray-100' icon={<HiOutlineEllipsisVertical size={16} />} />
                  </Dropdown>
                )}
              </div>

              <OrderOverview info={info} />

              <div className='mt-5'>
                <OrderTable orderInfo={info} onEdit={(_id) => setEditingItemId(_id)} onMarkAsPaid={(_id) => setmarkAsPaidItemId(_id)} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='h-full flex-1 center'>
          {!errorMessage ? (
            <Spin />
          ) : (
            <div className='flex flex-col items-center gap-4'>
              {errorMessage}

              <Link to='/group-order'>
                <Button type='primary'>Quay lại</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default OrderDetail

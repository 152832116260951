import { reArrangeArray } from '@shared/common/utils'
import BaseDraggable from '@shared/components/BaseDraggable'
import type { RootState } from '@shared/store'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTasks, updateTaskPositions } from '../../reducers/taskReducer'
import TaskItem from './TaskItem'

type Props = {
  folderId: string
}

const Tasks = ({ folderId }: Props) => {
  const dispatch = useDispatch<any>()
  const refShowTasks = useRef<any>()

  const tasks = useSelector((state: RootState) => state.todo.task.items)
  const [showTasks, setShowTasks] = useState<any[]>([])
  const [completeTasks, setCompleteTasks] = useState<any[]>([])
  const [showCompletedTask, setShowCompletedTask] = useState<boolean>(false)

  const fetchData = async () => {
    if (!folderId) {
      return
    }
    try {
      await dispatch(fetchTasks({ folderId })).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    if (folderId) {
      fetchData()
    }
  }, [folderId])

  useEffect(() => {
    const taskCompleted = tasks.filter((task) => task.isCompleted)
    const taskUnCompleted = tasks.filter((task) => !task.isCompleted)

    setShowTasks(taskUnCompleted)
    setCompleteTasks(taskCompleted)
    refShowTasks.current = [...taskUnCompleted]
  }, [tasks, folderId])

  const onEndDragLink = async (event: any) => {
    const { newIndex, oldIndex } = event

    if (newIndex === oldIndex) {
      return
    }

    const newArr = reArrangeArray(refShowTasks.current, oldIndex, newIndex)

    const positions = newArr.map((item, i) => {
      return {
        id: item.id,
        position: i
      }
    })

    try {
      await dispatch(updateTaskPositions({ positions })).unwrap()
    } catch (error) {}
  }

  return (
    <div className='flex flex-1 flex-col'>
      <BaseDraggable className='flex flex-col divide-y divide-gray-200' group='tasks-todo' dragClass='.task_item' onEnd={onEndDragLink}>
        {showTasks.map((item: any) => {
          return <TaskItem key={item.id} info={item} />
        })}
      </BaseDraggable>

      {completeTasks?.length > 0 && (
        <div className='my-3'>
          <button
            onClick={() => {
              setShowCompletedTask(!showCompletedTask)
            }}
          >
            Completed tasks ({completeTasks.length})
          </button>
        </div>
      )}

      {showCompletedTask && (
        <div>
          <div className='flex flex-col divide-y divide-gray-200'>
            {completeTasks.map((item: any) => {
              return <TaskItem key={item.id} info={item} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Tasks

import { ExclamationCircleFilled } from '@ant-design/icons'
import BaseFlatInput from '@shared/components/BaseFlatInput'
import type { MenuProps } from 'antd'
import { Dropdown, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsJournalCheck, BsTrash } from 'react-icons/bs'
import { HiOutlineEllipsisHorizontal } from 'react-icons/hi2'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { deleteFolder, getFolder, updateFolder } from '../../reducers/folderReducer'
import AddTask from '../task/AddTask'
import Tasks from '../task/Tasks'

const FolderDetail = () => {
  const navigate: NavigateFunction = useNavigate()

  const dispatch = useDispatch<any>()
  const params = useParams()

  const [info, setInfo] = useState<any>()
  const [loading, setLoading] = useState<boolean>()

  const items: MenuProps['items'] = [
    {
      label: 'Delete',
      key: 'delete',
      icon: <BsTrash size={16} />,
      danger: true
    }
  ]

  const fetchData = async () => {
    if (!params.folderId) {
      return
    }

    try {
      setLoading(true)

      const rs = await dispatch(
        getFolder({
          id: params.folderId
        })
      ).unwrap()

      setInfo(rs)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [params.folderId])

  /*
   *****************************************
   *
   *
   */

  const handleDeleteFolder = async () => {
    try {
      await dispatch(deleteFolder({ id: info.id })).unwrap()

      navigate(`/to-do`)
    } catch (error) {}
  }

  const handleUpdateFolder = async (value: string) => {
    try {
      const infoChanged = await dispatch(
        updateFolder({
          id: info.id,
          name: value
        })
      ).unwrap()

      setInfo(infoChanged)
    } catch (error) {}
  }

  const handleInputChange = (value: string) => {
    handleUpdateFolder(value)
  }

  const onDeleteItem = () => {
    Modal.confirm({
      title: 'Are you sure delete this folder?',
      icon: <ExclamationCircleFilled />,
      content: 'When you delete this folder, all tasks data of the folder will be deleted and cannot be restored.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleDeleteFolder()
      },
      onCancel() {}
    })
  }

  const onClickDropdown: MenuProps['onClick'] = ({ key }) => {
    if (key === 'delete') {
      onDeleteItem()
    }
  }

  return (
    <div className='h-full bg-[#ebe8ff]'>
      {!loading && info ? (
        <div className='flex h-full w-full flex-row'>
          <div className='relative h-full flex-1'>
            <div className='flex h-16 flex-row items-center px-4'>
              <div className='flex flex-1 flex-row items-center gap-3'>
                <BsJournalCheck size={20} className='' />

                <BaseFlatInput value={info.name} onChange={handleInputChange} wrapperClassName='mr-4 w-full' className='text-[24px] font-bold' />
              </div>

              <Dropdown menu={{ items, onClick: onClickDropdown, className: 'w-32' }} placement='bottomRight' trigger={['click']}>
                <div className='h-8 w-8 cursor-pointer rounded bg-gray-100 center'>
                  <HiOutlineEllipsisHorizontal size={20} />
                </div>
              </Dropdown>
            </div>

            <ReactPerfectScrollbar className='px-4 pb-20' style={{ maxHeight: 'calc(100vh - 64px)' }} options={{ suppressScrollX: true }}>
              <Tasks folderId={info.id} />
            </ReactPerfectScrollbar>

            <div className='absolute inset-x-0 bottom-0 h-16 px-4'>
              <AddTask folderId={info.id} />
            </div>
          </div>

          <Outlet />
        </div>
      ) : (
        <div className='h-full w-full center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default FolderDetail

import type { ReactNode } from 'react'
import React from 'react'
import { LiaUserSolid } from 'react-icons/lia'

type Props = {
  icon?: ReactNode
  value: string | number
  title: string
  changePercent?: string | number
}

const StatisticCard = ({ icon, value, title, changePercent }: Props) => {
  return (
    <div className='flex flex-col rounded-lg border p-4'>
      <div>
        <div className='h-10 w-10 rounded border center'>{icon || <LiaUserSolid size={20} />}</div>
      </div>

      <div className='mb-1 mt-5 text-sm text-gray-600'>{title}</div>

      <div className='mb-1 flex flex-row'>
        <div className='text-3xl font-medium'>{value}</div>
        <div className='flex-1' />

        {changePercent && <div>{changePercent}%</div>}
      </div>
    </div>
  )
}

export default StatisticCard

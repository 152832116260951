import { updateLastRoute } from '@shared/common/reducers/cacheReducer'
import { getDefaultProfileAvatar } from '@shared/common/utils'
import { logout } from '@shared/modules/auth/reducers/authReducer'
import type { RootState } from '@shared/store'
import { Image, Tooltip } from 'antd'
import React from 'react'
import { HiMiniArrowRightOnRectangle, HiOutlineCog, HiOutlineQuestionMarkCircle, HiOutlineSquares2X2 } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'

const UserBoard = () => {
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()

  const user = useSelector((state: RootState) => state.auth.user)

  const handleLogout = async () => {
    dispatch(updateLastRoute(''))
    await dispatch(logout())
    navigate('/login')
  }

  return (
    <div className=' w-[280px] bg-white'>
      <div className='flex h-full flex-col gap-3 rounded-lg bg-white'>
        <div className='flex flex-row items-center gap-3'>
          <div>
            <Image preview={false} src={getDefaultProfileAvatar()} width={48} className='rounded-full' />
          </div>

          <div className='flex flex-col'>
            <div className='font-semibold text-main'>{user.fullname}</div>
            <div className='text-sm font-light text-gray-400'>{user.email}</div>
          </div>
        </div>

        <div className='flex-1' />

        <div className='flex flex-row items-center justify-center gap-4 rounded border bg-gray-50 p-2'>
          <Tooltip placement='top' title='Settings'>
            <Link to='/settings' className='rounded p-1 center hover:bg-gray-200'>
              <HiOutlineCog size={24} className='text-tertiary' />
            </Link>
          </Tooltip>

          <Tooltip placement='top' title='Apps'>
            <Link to='/apps' className='rounded p-1 center hover:bg-gray-200'>
              <HiOutlineSquares2X2 size={24} className='text-tertiary' />
            </Link>
          </Tooltip>

          <Tooltip placement='top' title='Help'>
            <Link to='https://zobite.com/help' target='_blank' className='rounded p-1 center hover:bg-gray-200'>
              <HiOutlineQuestionMarkCircle size={24} className='text-tertiary' />
            </Link>
          </Tooltip>

          <div className='flex-1' />

          <Tooltip placement='top' title='Logout'>
            <button onClick={handleLogout} className='cursor-pointer rounded bg-white p-1 center hover:bg-red-200'>
              <HiMiniArrowRightOnRectangle size={24} className='text-tertiary' />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default UserBoard

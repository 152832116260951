import { formatMoney } from '@shared/common/utils'
import { Button, Modal, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EOrderItemPaymentMethod, EOrderItemStatus } from '../../common/constant'
import { getOrderItem, updateOrderItem } from '../../reducers/orderItemReducer'

type Props = {
  orderItemId: string | undefined
  onSuccess?: () => void
  onClose?: () => void
}

const ModalMarkAsPaid = ({ onSuccess, orderItemId, onClose }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  const [info, setInfo] = useState<any>()
  const [selectedMethod, setSelectedMethod] = useState<number>(EOrderItemPaymentMethod.CASH)
  const [loading, setLoading] = useState<boolean>()

  const submit = async () => {
    try {
      setLoading(true)

      await dispatch(
        updateOrderItem({
          id: info.id,
          status: EOrderItemStatus.PAID,
          paymentMethod: selectedMethod
        })
      ).unwrap()

      setIsOpenModal(false)

      if (onSuccess) {
        onSuccess()
      }
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      if (onClose) {
        onClose()
      }
    }
  }, [isOpenModal])

  const fetchData = async () => {
    setLoading(undefined)
    try {
      if (orderItemId) {
        const data = await dispatch(
          getOrderItem({
            id: orderItemId
          })
        ).unwrap()
        setInfo(data)
      }
    } catch (error: any) {
      // setErrorMessage('Đường dẫn không hợp lệ.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (orderItemId) {
      setIsOpenModal(true)
      fetchData()
    }
  }, [orderItemId])

  /*
   *****************************************
   *
   *
   */

  return (
    <Modal
      title='Xác nhận đã thanh toán'
      className='zo-modal top-10'
      width={600}
      footer={false}
      onCancel={() => {
        setIsOpenModal(false)
      }}
      open={isOpenModal}
    >
      <div className='flex flex-col px-5 pb-6'>
        {loading === undefined || isEmpty(info) ? (
          <div className='h-full w-full center'>
            <Spin />
          </div>
        ) : (
          <div className='flex flex-col'>
            <div className='mt-3 rounded border border-dashed border-indigo-200 bg-indigo-100 p-4'>Trưởng nhóm ơi! Tôi đã thanh toán rồi nhé.</div>

            <div className='mt-8 text-[16px] font-bold'>Thông tin sản phẩm</div>

            <div className='mt-3 flex flex-row'>
              <div className='w-1/3 text-tertiary'>Tên sản phẩm:</div>
              <div>{info.name}</div>
            </div>

            <div className='mt-3 flex flex-row'>
              <div className='w-1/3 text-tertiary'>Số lượng:</div>
              <div>{info.quantity}</div>
            </div>

            <div className='mt-3 flex flex-row'>
              <div className='w-1/3 text-tertiary'>Tổng tiền:</div>
              <div>{formatMoney(info.quantity * info.price || 0)}</div>
            </div>

            <div className='mt-8 text-[16px] font-bold'>Hình thức chuyển khoản</div>
            <div className='mt-3 grid grid-cols-3 gap-4'>
              <button
                onClick={() => {
                  setSelectedMethod(EOrderItemPaymentMethod.CASH)
                }}
                className={`rounded border px-4 py-8 text-base transition duration-75 ease-in-out center ${
                  selectedMethod === EOrderItemPaymentMethod.CASH ? 'border-primary bg-primary-50' : 'border-gray-200 bg-white'
                }`}
              >
                Tiền mặt
              </button>

              <button
                onClick={() => {
                  setSelectedMethod(EOrderItemPaymentMethod.TRANSFER)
                }}
                className={`rounded border px-4 py-8 text-base transition duration-75 ease-in-out center ${
                  selectedMethod === EOrderItemPaymentMethod.TRANSFER ? 'border-primary bg-primary-50' : 'border-gray-200 bg-white'
                }`}
              >
                Chuyển khoản
              </button>

              <button
                onClick={() => {
                  setSelectedMethod(EOrderItemPaymentMethod.MOMO)
                }}
                className={`rounded border px-4 py-8 text-base transition duration-75 ease-in-out center ${
                  selectedMethod === EOrderItemPaymentMethod.MOMO ? 'border-primary bg-primary-50' : 'border-gray-200 bg-white'
                }`}
              >
                MoMo
              </button>
            </div>

            <div className='mt-8 flex flex-row justify-end gap-2'>
              <Button
                type='default'
                onClick={() => {
                  setIsOpenModal(false)
                }}
              >
                Hủy
              </Button>

              <Button loading={loading} type='primary' onClick={submit}>
                Xác nhận
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalMarkAsPaid

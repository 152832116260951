import type { ReactNode } from 'react'
import React, { useEffect, useRef } from 'react'
import Sortable from 'sortablejs'

type Props = {
  group: string
  dragClass: string
  className?: string
  style?: React.CSSProperties | undefined
  children?: ReactNode
  onEnd?: (evt: any) => void
}

const BaseDraggable = ({ group = ' drag-group', dragClass = '.drag_item', className, style, children, onEnd }: Props) => {
  const myRef = useRef<any>()

  const sortableRef = useRef<any>()
  // let sortable: any = null

  const initSortable = () => {
    // if (sortable !== undefined) {
    //   sortable.destroy()
    // }

    sortableRef.current = Sortable.create(myRef.current, {
      group,
      animation: 150,
      // swapThreshold: 1,
      draggable: dragClass,
      handle: dragClass,
      onEnd(evt) {
        if (onEnd) {
          onEnd(evt)
        }
      }
    })
  }

  useEffect(() => {
    initSortable()

    return () => {
      if (sortableRef.current) {
        sortableRef.current.destroy()
      }
    }
  }, [])

  return (
    <div className={className} ref={myRef} style={style}>
      {children}
    </div>
  )
}

export default BaseDraggable

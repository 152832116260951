import Lottie from '@shared/components/Lottie'
import type { RootState } from '@shared/store'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'

import { confirmEmail } from './reducers/authReducer'

const ConfirmEmailPage = () => {
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()

  // const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const handleConfirm = async (token: string) => {
    // setLoading(true)
    try {
      await dispatch(confirmEmail({ token })).unwrap()
    } catch (error: any) {
      const resMessage = error.message
      setErrorMessage(resMessage)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token')

    if (!token) {
      setErrorMessage('Invalid token.')
      return
    }

    handleConfirm(token)
  }, [])

  return (
    <div className='flex min-h-screen flex-col bg-gray-50'>
      <div className='min-h-[300px] bg-primary' />

      <div className='flex w-full flex-col items-center'>
        <div className='container -mt-32 flex min-h-[440px] max-w-[800px] flex-col items-center rounded-lg bg-white p-8 shadow'>
          {!errorMessage && <Lottie name='success' className='w-48' />}
          {errorMessage && <Lottie name='error' className='w-48' />}
          <div className='text-2xl font-bold text-main'>Email verification failed</div>
          <div className='mt-4 text-secondary'>
            {errorMessage || 'Your email has been verified. Log in now to experience the great applications of Zobite.'}
          </div>
          <div className='mt-6'>
            <Link to='/login'>
              <Button type='primary'>Back to Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmEmailPage

import { reArrangeArray } from '@shared/common/utils'
import BaseDraggable from '@shared/components/BaseDraggable'
import { fetchChildTasks, updateChildTaskPositions } from '@shared/modules/todo/reducers/childTaskReducer'
import type { RootState } from '@shared/store'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ChildTaskItem from './ChildTaskItem'

type Props = {
  taskId: any | undefined
}

const ChildTasks = ({ taskId }: Props) => {
  const dispatch = useDispatch<any>()
  const refShowChildTasks = useRef<any>()
  const [showChildTasks, setShowChildTasks] = useState<any[]>([])
  const childTasks = useSelector((state: RootState) => state.todo.childTask.items)

  const fetchChildTask = async () => {
    if (!taskId) {
      return
    }
    try {
      await dispatch(fetchChildTasks({ taskId })).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    if (taskId) {
      fetchChildTask()
    } else {
      setShowChildTasks([])
    }
  }, [taskId])

  useEffect(() => {
    setShowChildTasks(childTasks)
    refShowChildTasks.current = [...childTasks]
  }, [childTasks, taskId])

  const onEndDragLink = async (event: any) => {
    const { newIndex, oldIndex } = event

    if (newIndex === oldIndex) {
      return
    }

    const newArr = reArrangeArray(refShowChildTasks.current, oldIndex, newIndex)

    const positions = newArr.map((item, i) => {
      return {
        id: item.id,
        position: i
      }
    })

    try {
      await dispatch(updateChildTaskPositions({ positions })).unwrap()
    } catch (error) {}
  }

  return (
    <BaseDraggable className='flex flex-col' group='childTasks-todo' dragClass='.childtask_item' onEnd={onEndDragLink}>
      {showChildTasks.map((item: any) => {
        return <ChildTaskItem key={item.id} info={item} />
      })}
    </BaseDraggable>
  )
}

export default ChildTasks

import Lang from '@shared/components/Lang'
import { Modal, Tabs } from 'antd'
import React, { useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import BoxExploreApps from './components/BoxExploreApps'
import BoxInstalledApps from './components/BoxInstalledApps'

function UserAppsLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const [currentTab, setCurrentTab] = useState('installed')

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/apps`)
  }

  const onChangeTab = (key: string) => {
    setCurrentTab(key)
  }

  return (
    <main className='mx-auto flex max-w-[1280px] flex-col p-8'>
      <div className='text-2xl font-semibold text-main'>
        <Lang vi='Kho ứng dụng' en='Mini App Store' />
      </div>

      <div className='mt-6 flex flex-row gap-4'>
        <Tabs
          className='zo-tabs w-full'
          items={[
            {
              key: 'installed',
              label: <Lang vi='Đã cài đặt' en='Installed' />
            },
            {
              key: 'explore',
              label: <Lang vi='Kho ứng dụng' en='Explore' />
            }
          ]}
          onChange={onChangeTab}
        />
      </div>

      <div className='pt-8'>
        {currentTab === 'installed' && <BoxInstalledApps />}
        {currentTab === 'explore' && <BoxExploreApps />}
      </div>

      <Modal className='zo-modal' width={720} footer={false} onCancel={handleClose} destroyOnClose open={shouldOpen()}>
        <div className='flex min-h-[500px] flex-col'>{shouldOpen() && <Outlet />}</div>
      </Modal>
    </main>
  )
}

export default UserAppsLayout

import { Button, Image, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { HiOutlineArrowTopRightOnSquare } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { uninstallApp } from '../reducers/appReducer'
import { fetchInstalledApps } from '../reducers/installedAppReducer'

type Props = {
  info: any
}

const InstalledListItem = ({ info }: Props) => {
  const dispatch = useDispatch<any>()

  const [uninstalled, setUninstalled] = useState<boolean>(false)

  const handleUninstall = async () => {
    try {
      await dispatch(
        uninstallApp({
          appId: info.id
        })
      ).unwrap()

      await dispatch(fetchInstalledApps()).unwrap()
    } catch (error) {
    } finally {
      setUninstalled(true)
    }
  }

  // const onClickMenu: MenuProps['onClick'] = ({ key }) => {
  //   if (key === 'uninstall') {
  //     handleUninstall()
  //   }
  // }

  return (
    <div className='flex flex-col rounded border border-primary-100 bg-primary-50'>
      <div className='flex flex-1 flex-row items-start gap-4 px-4 py-6'>
        <div className='h-12 w-20 select-none center'>
          <Image src={`/images/app-icons/${info.slug}.svg`} height={48} preview={false} className='object-contain' />
        </div>

        <div className='flex flex-1 flex-col gap-1'>
          <Link to={`/apps/${info.id}`} className='text-lg font-semibold text-main'>
            {info.name}
          </Link>

          <div className='text-sm font-light text-tertiary'>{info.description}</div>
        </div>

        <div>
          {!uninstalled && (
            <Popconfirm
              title='Confirm'
              overlayInnerStyle={{ width: '360px' }}
              description='All data of this application will be lost if you uninstall it. Are you sure you want to uninstall it?'
              onConfirm={handleUninstall}
            >
              <Button danger size='small'>
                Uninstall
              </Button>
            </Popconfirm>
          )}

          {uninstalled && <div className=' text-gray-400'>Uninstalled</div>}

          {/* <Dropdown menu={{ items, onClick: onClickMenu, className: 'w-32' }} placement='bottomRight' trigger={['click']}>
            <div className='h-7 w-7 cursor-pointer rounded border bg-white center hover:bg-gray-50'>
              <HiOutlineEllipsisHorizontal size={16} />
            </div>
          </Dropdown> */}
        </div>
      </div>

      <div className='flex flex-row items-center border-t border-primary-100 px-4 py-2'>
        <div>{info.type === 1 ? 'Free' : 'Premium'}</div>
        <div className='flex-1' />

        <div>
          <Link to={`/apps/${info.id}`} className='h-6 w-6 rounded center hover:bg-gray-100'>
            <HiOutlineArrowTopRightOnSquare className='text-gray-400' size={18} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default InstalledListItem

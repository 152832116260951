import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getApp } from './reducers/appReducer'

const UserAppDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)

  const fetchInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getApp({
            appId: params.id
          })
        ).unwrap()

        setInfo(rs)
        setLoading(false)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  return (
    <div className='flex h-full flex-1 flex-col'>
      {!loading && info?.id ? (
        <div className='flex flex-col'>
          <div className='relative flex flex-col'>
            <img src='/images/default/cover1.jpeg' className='h-[160px] w-full object-cover' alt='' />

            <div className='absolute -bottom-12 left-8 h-24 w-24 rounded-xl border bg-white shadow center'>
              <img alt='' src={`/images/app-icons/${info.slug}.svg`} className='h-16 w-16 object-contain' />
            </div>
          </div>

          <div className='pl-[148px] pt-3'>
            <div className='text-2xl font-black text-main'>{info.name}</div>
          </div>

          <div className='flex-1 center' />
        </div>
      ) : (
        <div className='flex h-full flex-1 flex-col center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default UserAppDetail

import { EAppType } from '@shared/common/enum'
import { getEnumKeyName } from '@shared/common/utils'
import { Button, Image } from 'antd'
import React from 'react'
import { HiOutlineArrowTopRightOnSquare } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { installApp } from '../reducers/appReducer'
import { fetchInstalledApps } from '../reducers/installedAppReducer'

type Props = {
  info: any
}

const ListItem = ({ info }: Props) => {
  const dispatch = useDispatch<any>()

  const handleInstall = async () => {
    await dispatch(
      installApp({
        appId: info.id
      })
    ).unwrap()

    try {
      await dispatch(fetchInstalledApps()).unwrap()
    } catch (error) {}
  }

  return (
    <div className='flex flex-col rounded border border-primary-100 bg-primary-50'>
      <div className='flex flex-1 flex-row items-start gap-4 px-4 py-6'>
        <div className='h-12 w-20 select-none center'>
          <Image src={`/images/app-icons/${info.slug}.svg`} height={48} preview={false} className='object-contain' />
        </div>

        <div className='flex flex-1 flex-col gap-1'>
          <Link to={`/apps/${info.id}`} className='text-lg font-semibold text-main'>
            {info.name}
          </Link>

          <div className='text-sm font-light text-tertiary'>{info.description}</div>
        </div>

        <div>
          <Button type='default' size='small' onClick={handleInstall}>
            Install
          </Button>

          {/* <Dropdown menu={{ items, onClick: onClickMenu, className: 'w-32' }} placement='bottomRight' trigger={['click']}>
            <div className='h-7 w-7 cursor-pointer rounded border bg-white center hover:bg-gray-50'>
              <HiOutlineEllipsisHorizontal size={16} />
            </div>
          </Dropdown> */}
        </div>
      </div>

      <div className='flex flex-row items-center border-t border-primary-100 px-4 py-2'>
        <div>{getEnumKeyName(EAppType, info.type)}</div>
        <div className='flex-1' />

        <div>
          <Link to={`/apps/${info.id}`} className='h-6 w-6 rounded center hover:bg-gray-100'>
            <HiOutlineArrowTopRightOnSquare className='text-gray-400' size={18} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ListItem

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import { EAppPlatform } from '@shared/common/enum'
import type { RootState } from '@shared/store'

export interface InstalledAppState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    createdAt?: string
    platform?: number
  }
}

const initialState: InstalledAppState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: '-createdAt',
    platform: EAppPlatform.WEBAPP
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchInstalledApps = createAsyncThunk('installedApp/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.installedApp.filter }

    params.platform = EAppPlatform.WEBAPP

    return await api.get('apps/installed', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type InstalledAppPositionsDto = {
  positions: any[]
}

export const updateMenuPositions = createAsyncThunk('installedApp/update-positions', async (payload: InstalledAppPositionsDto, { rejectWithValue }) => {
  try {
    await api.put(`apps/update-position`, payload)
    return payload
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const installedAppSlice = createSlice({
  name: 'installedApp',

  initialState,

  reducers: {
    reset: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = JSON.parse(JSON.stringify(initialState))
    },

    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInstalledApps.fulfilled, (state, action) => {
      const { total, items } = action.payload as any

      if (items && items.length) {
        state.items = [...items].sort((a, b) => {
          return a.position - b.position
        })
      } else {
        state.items = []
      }

      state.total = total
    })
  }
})

export const { reset, updateFilter } = installedAppSlice.actions
export default installedAppSlice.reducer

import { formatMoney, parseMoney } from '@shared/common/formater'
import { Button, Form, InputNumber, Modal } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React, { useEffect, useState } from 'react'
import { BsBag, BsBagCheck, BsBagCheckFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

import { EOrderStatus } from '../../common/constant'
import { updateOrder } from '../../reducers/orderReducer'

type Props = {
  orderInfo: any
  show: boolean
  onClose: () => void
}

const ModalOrderStatus = ({ orderInfo, onClose, show }: Props) => {
  const dispatch = useDispatch<any>()

  const [loading, setLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState(orderInfo.status)

  const [fee, setFee] = useState(orderInfo.fee)
  const [discount, setDiscount] = useState(orderInfo.discount)

  const handleUpdateOrder = async (payload: any) => {
    try {
      setLoading(true)
      await dispatch(
        updateOrder({
          id: orderInfo.id,
          ...payload
        })
      ).unwrap()
      setLoading(false)

      onClose()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    setSelected(orderInfo.status)
    setFee(orderInfo.fee)
    setDiscount(orderInfo.discount)
  }, [orderInfo])

  /*
   *****************************************
   *
   *
   */

  return (
    <Modal
      title='Thay đổi trạng thái đơn hàng'
      className='zo-modal top-10'
      width={700}
      footer={false}
      onCancel={() => {
        onClose()
      }}
      open={show}
    >
      <div className='p-6'>
        <div className='flex flex-row gap-6 '>
          <button
            className={`flex flex-1 cursor-pointer flex-col items-center gap-5 rounded-lg border p-8 text-[16px] transition-all duration-75 ease-in-out ${
              selected === EOrderStatus.SELECTING
                ? 'border-primary-300 bg-primary-50 font-medium text-primary'
                : 'border-gray-100 bg-gray-50 shadow hover:bg-gray-100'
            }`}
            onClick={() => {
              setSelected(EOrderStatus.SELECTING)
            }}
          >
            <div className='h-10 w-10 center'>{orderInfo.status === EOrderStatus.SELECTING ? <BsBagCheck size={24} /> : <BsBagCheckFill size={24} />}</div>
            Chọn món
          </button>

          <button
            className={`flex flex-1 cursor-pointer flex-col items-center gap-5 rounded-lg border p-8 text-[16px] transition-all duration-75 ease-in-out ${
              selected === EOrderStatus.DELIVERING
                ? 'border-primary-300 bg-primary-50 font-medium text-primary'
                : 'border-gray-100 bg-gray-50 shadow hover:bg-gray-100'
            }`}
            onClick={() => {
              setSelected(EOrderStatus.DELIVERING)
            }}
          >
            <div className='h-10 w-10 center'>
              {orderInfo.status === EOrderStatus.SELECTING && <BsBag size={28} />}
              {orderInfo.status === EOrderStatus.DELIVERING && <BsBagCheck size={24} />}
              {orderInfo.status === EOrderStatus.RECEIVED && <BsBagCheckFill size={24} />}
            </div>
            Đang giao
          </button>

          <button
            className={`flex flex-1 cursor-pointer flex-col items-center gap-5 rounded-lg border p-8 text-[16px] transition-all duration-75 ease-in-out ${
              selected === EOrderStatus.RECEIVED
                ? 'border-primary-300 bg-primary-50 font-medium text-primary'
                : 'border-gray-100 bg-gray-50 shadow hover:bg-gray-100'
            }`}
            onClick={() => {
              setSelected(EOrderStatus.RECEIVED)
            }}
          >
            <div className='h-10 w-10 center'>
              {orderInfo.status !== EOrderStatus.RECEIVED && <BsBag size={24} />}
              {orderInfo.status === EOrderStatus.RECEIVED && <BsBagCheckFill size={24} />}
            </div>
            Đã nhận hàng
          </button>
        </div>

        <div className='mt-6 border-t border-dashed pt-5 center'>
          <Form className='w-full' layout='vertical' autoComplete='off'>
            <div className='flex w-full flex-row gap-4'>
              <FormItem className='flex-1' label='Tiền phí' tooltip='Tiền phí ship + phí khác'>
                <InputNumber
                  className='w-full'
                  min={0}
                  step={1000}
                  formatter={formatMoney}
                  parser={parseMoney}
                  value={fee}
                  onChange={(value) => {
                    if (value) {
                      setFee(value)
                    }
                  }}
                />
              </FormItem>

              <FormItem className='flex-1' label='Giảm giá' tooltip='Số tiền được giảm'>
                <InputNumber
                  className='w-full'
                  min={0}
                  step={1000}
                  formatter={formatMoney}
                  parser={parseMoney}
                  value={discount}
                  onChange={(value) => {
                    if (value) {
                      setDiscount(value)
                    }
                  }}
                />
              </FormItem>
            </div>
          </Form>
        </div>

        <div className='mt-4 flex flex-row justify-end'>
          <Button
            loading={loading}
            type='primary'
            onClick={() => {
              handleUpdateOrder({
                status: selected,
                fee: fee || 0,
                discount: discount || 0
              })
            }}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOrderStatus

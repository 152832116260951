import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import axios from 'axios'

interface IState {
  current: any
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
  }
}

const initialState: IState = {
  current: undefined,
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: 'position'
  }
}

/*
 *****************************************
 */

let lastCancelToken: any

export const fetchFolders = createAsyncThunk('todo/folder/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.todo.folder.filter }

    if (lastCancelToken) {
      lastCancelToken.cancel()
    }

    lastCancelToken = axios.CancelToken.source()

    return await api.get('todo/folders', {
      params,
      cancelToken: lastCancelToken.token
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type FolderGetDto = {
  id: string
}

export const getFolder = createAsyncThunk('todo/folder/get', async (payload: FolderGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`todo/folders/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type FolderCreateDto = {
  name: string
}

export const createFolder = createAsyncThunk('todo/folder/create', async (payload: FolderCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`todo/folders`, payload)
    return { ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type FolderUpdateDto = {
  id: string
  name: string
}

export const updateFolder = createAsyncThunk('todo/folder/update', async (payload: FolderUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`todo/folders/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

type FolderDeleteDto = {
  id: string
}

export const deleteFolder = createAsyncThunk('todo/folder/delete', async (payload: FolderDeleteDto, { rejectWithValue }) => {
  try {
    await api.delete(`todo/folders/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */
type UpdateFolderPositionsDto = {
  positions: any[]
}

export const updateFolderPositions = createAsyncThunk('todo/folder/update-positions', async (payload: UpdateFolderPositionsDto, { rejectWithValue }) => {
  try {
    await api.put(`todo/folders/update-positions`, payload)
    return payload
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 */

export const folderSlice = createSlice({
  name: 'todo_folder',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    setCurrent(state, { payload }) {
      state.current = payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchFolders.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateFolder.fulfilled, (state, action) => {
        const info = action.payload as any

        const findIndex = state.items.findIndex((item) => item.id === info.id)

        if (findIndex >= 0) {
          state.items.splice(findIndex, 1, info)
        }
      })
      .addCase(updateFolderPositions.fulfilled, (state, action) => {
        const { positions } = action.payload as any

        for (const newItem of positions) {
          const findIndex = state.items.findIndex((item) => item.id === newItem.id)

          if (findIndex >= 0) {
            const newObject = Object.assign(state.items[findIndex], newItem)

            state.items.splice(findIndex, 1, newObject)
          }
        }
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, setCurrent } = folderSlice.actions
export default folderSlice.reducer

import { Input, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

import { createFolder } from '../../reducers/folderReducer'

const AddFolder = () => {
  const dispatch = useDispatch<any>()

  const [modal, contextHolder] = Modal.useModal()

  const [name, setName] = useState<string>('')
  const loadingRef = useRef<boolean>(false)

  const handleEnterKey = async () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    try {
      if (loadingRef.current && name) {
        await dispatch(
          createFolder({
            name
          })
        ).unwrap()

        setName('')
      }
    } catch (error: any) {
      if (error.errorCode === 406) {
        modal.error({
          title: 'Error',
          content: 'To keep your task management simple and most effective, avoid creating more than 20 folders.'
        })
        setName('')
      }
    } finally {
      loadingRef.current = false
    }
  }

  return (
    <div className='h-12 bg-gray-100 px-2 text-sm center'>
      {contextHolder}

      <BsPlusLg size={20} className='mr-2 text-main' />

      <Input
        value={name}
        bordered={false}
        placeholder='Add folder...'
        onChange={(event) => {
          setName(event.target.value)
        }}
        onPressEnter={handleEnterKey}
      />
    </div>
  )
}

export default AddFolder

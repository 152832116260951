import type { RootState } from '@shared/store'
import { Alert, Button, Form, Input, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'

import { requestResetPassword, resetPassword } from './reducers/authReducer'

const ResetPasswordPage = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()

  const [loading, setLoading] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')
  const [successfull, setSuccessfull] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    const tokenParam = new URLSearchParams(window.location.search).get('token')
    if (tokenParam) {
      setToken(tokenParam)
    }
  }, [])

  const handleRequestResetPassword = async (formValue: { email: string }) => {
    const { email } = formValue

    setLoading(true)

    try {
      await dispatch(requestResetPassword({ email })).unwrap()
      setSuccessfull(true)
    } catch (error: any) {
      const resMessage = error.message
      setErrorMessage(resMessage)
      setSuccessfull(false)
    } finally {
      setLoading(false)
    }
  }

  const handleResetPassword = async (formValue: { password: string }) => {
    const { password } = formValue

    setLoading(true)

    try {
      await dispatch(resetPassword({ password, token })).unwrap()
      setSuccessfull(true)
    } catch (error: any) {
      const resMessage = error.message
      setErrorMessage(resMessage)
      setSuccessfull(false)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = (form: any) => {
    if (!token) {
      handleRequestResetPassword(form)
    } else {
      handleResetPassword(form)
    }
  }

  return (
    <div className='flex min-h-screen w-full flex-col bg-[#FCFCFC] center'>
      <div className='flex w-[392px] flex-col'>
        {!successfull ? (
          <div className='flex flex-col'>
            <div className='text-4xl font-semibold text-base-1'>Forgot password?</div>
            <div className='mt-6 text-sm font-semibold text-base-1'>Enter your email and we will send you a reset link</div>

            <div className='my-6 bg-[#EFEFEF]' />

            <Form name='forgot_password' layout='vertical' onFinish={onFinish} autoComplete='off'>
              {!token && (
                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please input a valid email.' }
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              )}

              {token && (
                <div>
                  <Form.Item label='New Password' name='password' rules={[{ required: true, message: 'Please input new password!' }]}>
                    <Input.Password size='large' />
                  </Form.Item>

                  <Form.Item label='Confirm Password' name='confirm_password' rules={[{ required: true, message: 'Please input new password again!' }]}>
                    <Input.Password size='large' />
                  </Form.Item>
                </div>
              )}

              {errorMessage && <Alert message={errorMessage} type='error' showIcon className='mt-6' />}

              <div className='flex flex-col py-6'>
                <Form.Item>
                  <Button type='primary' size='large' htmlType='submit' loading={loading} className='w-full'>
                    Submit
                  </Button>
                </Form.Item>

                <div className='flex flex-row font-semibold'>
                  <div className='font-semibold text-tertiary'>Already a member?</div>
                  <Link to='/login' className='ml-1 flex flex-1 flex-row no-underline'>
                    Sign in
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className='flex flex-col rounded-lg border border-solid border-gray-200 bg-white shadow-md'>
            {!token && (
              <Result
                status='success'
                title='Check your email'
                subTitle='We have sent you an email containing a link for you to reset your password. Please check your email and follow the instructions.'
                extra={[
                  <Link to='/login' key='console'>
                    <Button type='primary' size='large' className='w-full'>
                      Login
                    </Button>
                  </Link>
                ]}
              />
            )}

            {token && (
              <Result
                status='success'
                title='Successfully reset password'
                subTitle='Please return to the login page to sign in and use the great apps from Zobite.'
                extra={[
                  <Link to='/login' key='console'>
                    <Button type='primary' size='large' className='w-full'>
                      Back to login
                    </Button>
                  </Link>
                ]}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPasswordPage

import { randomString } from '@shared/common/utils'
import Lang from '@shared/components/Lang'
import ZoButton from '@shared/components/ZoButton'
import { addHistory, setLength, setMasks } from '@shared/modules/generate_secure_password/reducers/configReducer'
import type { RootState } from '@shared/store'
import { Checkbox, Slider } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IoCheckmark, IoCopyOutline, IoShuffleOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

const BoxGenerator = () => {
  const dispatch = useDispatch<any>()
  const [disabled, setDisabled] = useState<boolean>(false)
  // const [showHistory, setShowHistory] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  const length = useSelector((state: RootState) => state.generateSecurePassword.config.length)
  const masks = useSelector((state: RootState) => state.generateSecurePassword.config.masks)
  // const histories = useSelector((state: RootState) => state.generateSecurePassword.config.histories)

  const options = [
    { label: <Lang vi='Chữ In Hoa (ABC)' en='Uppercase (ABC)' />, value: 'uppercase', disabled: false },
    { label: <Lang vi='Chữ thường (abc)' en='Lowercase (abc)' />, value: 'lowercase', disabled: false },
    { label: <Lang vi='Kí tự số (123)' en='Numbers (123)' />, value: 'numbers', disabled: false },
    { label: <Lang vi='Kí tự đặc biệt (!#$)' en='Symbols (!#$)' />, value: 'symbols', disabled: false }
  ]

  const onChange = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length) {
      dispatch(setMasks(checkedValues))
    }

    if (checkedValues.length === 1) {
      options[3].disabled = true
    } else {
      setDisabled(false)
    }
  }

  const onRefresh = () => {
    const val = randomString(length, masks)
    setValue(val)
  }

  useEffect(() => {
    onRefresh()
  }, [masks, length])

  const onChangeLength = (len: number) => {
    dispatch(setLength(len))
  }

  const onCopy = () => {
    setCopied(true)

    dispatch(addHistory(value))

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col overflow-hidden transition duration-75 ease-in-out'>
        <div className='flex flex-row items-center  border-b-4 border-primary bg-white  p-4 pb-5'>
          <div className='flex w-9/12 flex-1 flex-col'>
            <div className='text-sm font-medium text-gray-400'>
              <Lang vi='Mật khẩu gợi ý' en='Generate Secure Password' />
            </div>

            <div className='w-full truncate'>{value}</div>
          </div>

          <div className='flex w-3/12 flex-row justify-end'>
            <CopyToClipboard text={value} onCopy={onCopy}>
              <ZoButton className='bg-transparent hover:bg-primary-200 focus:bg-primary-300'>
                {copied ? <IoCheckmark size={20} className='text-success' /> : <IoCopyOutline size={20} className='text-main' />}
              </ZoButton>
            </CopyToClipboard>

            <ZoButton className='bg-transparent hover:bg-primary-200 active:bg-primary-300' onClick={onRefresh}>
              <IoShuffleOutline size={20} className='text-main' />
            </ZoButton>
          </div>
        </div>

        <div className='flex flex-col bg-white pb-8 pt-5'>
          <div className='flex flex-row items-center gap-1 px-4'>
            <Lang vi='Độ dài mật khẩu:' en='Password length:' />

            <input
              type='text'
              className='h-[28px] w-[34px] rounded-sm border px-2 center'
              value={length}
              onChange={(evt) => {
                if (evt.target.value && !Number.isNaN(Number(evt.target.value))) {
                  onChangeLength(+evt.target.value)
                }
              }}
            />

            <Lang vi='ký tự' en='characters' />
          </div>

          <div className='px-3'>
            <Slider
              className='mt-5'
              min={1}
              max={99}
              value={length}
              onChange={(val) => {
                onChangeLength(val)
              }}
            />
          </div>

          <div className='mt-2 px-4'>
            <Checkbox.Group disabled={disabled} className='flex flex-col gap-3.5' value={masks} options={options} onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxGenerator

import { EOrderStatus } from './constant'

export function getStatusName(info: any): string {
  if (info.status === EOrderStatus.DELIVERING) {
    return 'Đang giao'
  }

  if (info.status === EOrderStatus.RECEIVED) {
    return 'Đã nhận hàng'
  }
  return 'Đang chọn món'
}

import { App } from 'antd'
import React from 'react'

import MainContent from '../components/MainContent'
import Sidebar from '../partials/sidebar/Sidebar'

const TnoteLayout: React.FC = () => {
  return (
    <App message={{ maxCount: 1 }}>
      <div className='relative flex h-screen'>
        <Sidebar />
        <MainContent />
      </div>
    </App>
  )
}

export default TnoteLayout

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'

interface IState {
  current: any
}

const initialState: IState = {
  current: undefined
}

/*
 *****************************************
 *
 *
 */

type InviteGetDto = {
  id: string
}

export const getInvite = createAsyncThunk('invite/invite/get', async (payload: InviteGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`invite-links/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * CREATE
 *
 */

type JoinInviteDto = {
  id: string
}

export const joinInvite = createAsyncThunk('invite/invite/create', async (payload: JoinInviteDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`invite-links/join/${payload.id}`)
    return { ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const inviteSlice = createSlice({
  name: 'invite',

  initialState,

  reducers: {}
})

// export const { updateFilter, setCurrent } = inviteSlice.actions
export default inviteSlice.reducer

import { updateLastRoute } from '@shared/common/reducers/cacheReducer'
import { fetchMe } from '@shared/common/reducers/meReducer'
import type { RootState } from '@shared/store'
import type { FC } from 'react'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Sidebar from './Sidebar'
import Socket from './Socket'

const Layout: FC = () => {
  const location = useLocation()
  const navigate: NavigateFunction = useNavigate()

  const dispatch = useDispatch<any>()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const isLoggedInState = useRef<boolean>(isLoggedIn)
  const guestRoutes = ['/oauth/google', '/login', '/register', '/confirm-email', '/reset-password']

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchMe())
    }

    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [])

  useEffect(() => {
    if (isLoggedInState.current === true && isLoggedIn === false) {
      navigate('/login')
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!guestRoutes.includes(location.pathname)) {
      if (isLoggedIn) {
        dispatch(updateLastRoute(location.pathname))
      } else {
        navigate('/login')
      }
    }
  }, [location])

  return (
    <div className='flex min-h-screen w-screen flex-row bg-[#f0f2f5]'>
      {isLoggedIn && <Sidebar />}

      {isLoggedIn && <Socket />}

      <div className={`flex max-h-screen flex-col overflow-y-auto ${isLoggedIn ? '' : ''}`} style={{ width: `calc(100vw - ${isLoggedIn ? '72px' : '0px'})` }}>
        <div className='h-auto bg-white'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout

import React from 'react'

import Lang from './Lang'

type Props = {
  color?: string
  title: string
  titleVi: string
  className?: string
}

const SectionTitle = ({ title, titleVi, color = '#B4E4CA', className = '' }: Props) => {
  return (
    <div className={`flex flex-row items-center gap-3 ${className}`}>
      <div className='h-8 w-4 rounded-sm' style={{ backgroundColor: color }} />
      <div className='text-xl font-semibold text-base-1'>
        <Lang vi={titleVi} en={title} />
      </div>
    </div>
  )
}

export default SectionTitle

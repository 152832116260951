import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'

interface IState {
  total: number
  items: any[]

  filter: {
    page: number
    limit: number
    sorts?: string
    createdAt?: string
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: '-createdAt'
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchInvoices = createAsyncThunk('invoice/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.invoice.filter }

    return await api.get('invoices', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type InvoiceGetDto = {
  invoiceId: string
}

export const getInvoice = createAsyncThunk('invoice/get', async (payload: InvoiceGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`invoices/${payload.invoiceId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const invoiceSlice = createSlice({
  name: 'invoice',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      const { total, items } = action.payload as any

      state.items = items
      state.total = total
    })
  }
})

export const { updateFilter } = invoiceSlice.actions
export default invoiceSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'
import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'

export interface UserState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    roles?: number[]
    search?: string
    createdAt?: string
  }
  meta?: {
    totalCount?: number
    newUserCount?: number
    googleUserCount?: number
  }
}

const initialState: UserState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 25,
    sorts: '-createdAt',
    roles: undefined,
    search: '',
    createdAt: ''
  },
  meta: {
    totalCount: 0,
    newUserCount: 0,
    googleUserCount: 0
  }
}

export const fetchUsers = createAsyncThunk('zobite-admin/user/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.zobiteAdmin.user.filter }

    if (!params.createdAt) {
      delete params.createdAt
    } else if (params.createdAt === 'today') {
      const now = new Date()
      const start = startOfDay(now).toISOString()
      const end = endOfDay(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'week') {
      const now = new Date()
      const start = startOfWeek(now).toISOString()
      const end = endOfWeek(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'month') {
      const now = new Date()
      const start = startOfMonth(now).toISOString()
      const end = endOfMonth(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'year') {
      const now = new Date()
      const start = startOfYear(now).toISOString()
      const end = endOfYear(now).toISOString()

      params.createdAt = `range:${start},${end}`
    }

    if (params.search === '') {
      delete params.search
    }

    return await api.get('users', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createUser = createAsyncThunk('zobite-admin/user/create', async (payload: any, { rejectWithValue }) => {
  try {
    const rs: any = await api.post('users', payload)
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type UserGetDto = {
  userId: string
}

export const getUser = createAsyncThunk('zobite-admin/user/get', async (payload: UserGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`users/${payload.userId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type UserUpdateDto = {
  id: string
  name: string
}

export const updateUser = createAsyncThunk('zobite-admin/user/update', async (payload: UserUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`users/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type UserDeleteDto = {
  userId: string
}

export const deleteUser = createAsyncThunk('zobite-admin/user/delete', async (payload: UserDeleteDto, { rejectWithValue }) => {
  try {
    await api.get(`users/${payload.userId}`)
    return { id: payload.userId }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const userSlice = createSlice({
  name: 'zobite_admin_user',

  initialState,

  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },

    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { total, items, meta } = action.payload as any

        state.items = items
        state.total = total
        state.meta = meta
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const info = action.payload

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const info = action.payload as any

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { reset, updateFilter } = userSlice.actions
export default userSlice.reducer

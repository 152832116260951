import { getDefaultProfileAvatar, reArrangeArray } from '@shared/common/utils'
import BaseDraggable from '@shared/components/BaseDraggable'
import { fetchInstalledApps, updateMenuPositions } from '@shared/modules/app/reducers/installedAppReducer'
import type { RootState } from '@shared/store'
import { Image, Popover } from 'antd'
import React, { useEffect, useRef } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'

import MenuItem from './MenuItem'
import UserBoard from './UserBoard'

const Sidebar = () => {
  const dispatch = useDispatch<any>()
  const refShowItems = useRef<any>()
  const menus = useSelector((state: RootState) => state.installedApp.items)

  const fetchData = async () => {
    try {
      await dispatch(fetchInstalledApps()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    refShowItems.current = [...menus]
  }, [menus])

  const onEndDragMenu = async (event: any) => {
    const { newIndex, oldIndex } = event

    if (newIndex === oldIndex) {
      return
    }

    const newArr = reArrangeArray(refShowItems.current, oldIndex, newIndex)

    const positions = newArr.map((item, i) => {
      return {
        id: item.id,
        position: i
      }
    })

    try {
      await dispatch(updateMenuPositions({ positions })).unwrap()
    } catch (error) {}
  }

  return (
    <div className='relative flex w-[72px] flex-col border-r bg-white shadow-sm'>
      <PerfectScrollbar style={{ maxHeight: 'calc(100vh - 72px)' }} options={{ suppressScrollX: true }}>
        <BaseDraggable group='sidebar-group-menu' dragClass='.drag_item' onEnd={onEndDragMenu}>
          {menus.map((item: any) => {
            return <MenuItem key={item.id} name={item.name} to={item.slug} icon={item.slug} />
          })}
        </BaseDraggable>
      </PerfectScrollbar>

      <Popover trigger='click' content={UserBoard} placement='right' arrow={false}>
        <div className='flex h-[72px] cursor-pointer center'>
          <Image preview={false} src={getDefaultProfileAvatar()} width={42} className='rounded-full' />
        </div>
      </Popover>
    </div>
  )
}

export default Sidebar

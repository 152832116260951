export enum EOrderStatus {
  SELECTING = 1,
  DELIVERING = 2,
  RECEIVED = 3
}

export enum EOrderItemStatus {
  DRAFT = 1,
  ORDERED = 2,
  PAID = 3
}

export enum EOrderItemPaymentMethod {
  NONE = 1,
  CASH = 2,
  TRANSFER = 3,
  MOMO = 4
}

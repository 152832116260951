import { combineReducers } from '@reduxjs/toolkit'
import { EAppPlatform } from '@shared/common/enum'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { localStorage } from 'redux-persist-webextension-storage'

import tnoteReducer from './reducers/tnoteReducer'

const persistConfig = {
  key: 'tnote',
  storage: Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION ? localStorage : storage,
  whitelist: []
  // blacklist: ['current'],
}

const TnoteReducer = combineReducers({
  note: tnoteReducer
})

export default persistReducer(persistConfig, TnoteReducer)

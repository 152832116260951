import { fetchInvoices, updateFilter } from '@shared/common/reducers/invoiceReducer'
import SectionTitle from '@shared/components/SectionTitle'
import type { RootState } from '@shared/store'
import type { PaginationProps } from 'antd'
import { Drawer, Empty, Pagination } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import FilterByTime from './components/FilterByTime'
import ListItem from './components/ListItem'
import Sort from './components/Sort'

const InvoiceLayout = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.invoice.total)
  const items = useSelector((state: RootState) => state.invoice.items)
  const filter = useSelector((state: RootState) => state.invoice.filter)

  const fetchData = async () => {
    try {
      await dispatch(fetchInvoices()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  const onChangePage: PaginationProps['onChange'] = (page) => {
    dispatch(
      updateFilter({
        page
      })
    )
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/settings/invoices`)
  }

  return (
    <div className='flex flex-col'>
      <SectionTitle title='Invoices' titleVi='Hóa đơn' />

      <div className='mt-6 flex flex-row gap-4'>
        {/* <FilterByPost /> */}
        <FilterByTime />

        <div className='flex-1' />

        <Sort />
      </div>

      <div className='mt-6'>
        <div className='mb-2 flex flex-row items-center rounded-t border-b-2 bg-gray-100 pb-2.5 pt-3.5 font-medium'>
          <div className='flex-1 px-4'>Code</div>
          <div className='flex-1 px-4'>Status</div>
          <div className='flex-1'>Total</div>
          <div className='w-20 pr-5 text-right'>Action</div>
        </div>

        {items.length > 0 ? (
          <div className='flex flex-col'>
            <div className='flex flex-col divide-y'>
              {items.map((item) => {
                return <ListItem key={item.id} info={item} />
              })}
            </div>

            <div className='mt-8 flex flex-row'>
              <Pagination
                size='small'
                hideOnSinglePage
                total={total}
                showTotal={(totalCount) => `Total ${totalCount} items`}
                defaultPageSize={filter.limit}
                defaultCurrent={filter.limit}
                onChange={onChangePage}
              />
            </div>
          </div>
        ) : (
          <div className='min-h-[500px] center'>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>

      <Drawer title='Invoice' width={640} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </div>
  )
}

export default InvoiceLayout

import type { RootState } from '@shared/store'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Editor from './components/Editor'
import NoteTitle from './partials/note_item/NoteTitle'
import { fetchNote, updateNote } from './reducers/tnoteReducer'

const TnoteIndex: React.FC = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const note = useSelector((state: RootState) => state.tnote.note.note)

  const fetchData = async () => {
    try {
      await dispatch(fetchNote(params.noteId as string))
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  const updateNoteContent = debounce(async (value: string) => {
    try {
      await dispatch(
        updateNote({
          noteId: note.id,
          content: value
        })
      )
    } catch (error) {}
  }, 1000)

  const onContentChange = async (value: string) => {
    updateNoteContent(value)
  }

  useEffect(() => {
    if (params.noteId) {
      fetchData()
    }
  }, [params])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='mt-10 flex flex-col gap-4' key={note.id}>
      <NoteTitle note={note} />
      <Editor onContentChange={onContentChange} initialContent={note?.content ? note?.content : undefined} />
    </div>
  )
}

export default TnoteIndex

import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'

import { deleteQuote } from '../reducers/quoteReducer'

type Props = {
  selectedKeys: React.Key[]
  onReload?: () => void
}

const TableActions = ({ selectedKeys, onReload }: Props) => {
  const dispatch = useDispatch<any>()

  const handleDeleteItem = async () => {
    for (const id of selectedKeys) {
      try {
        await dispatch(deleteQuote({ id: id.toString() })).unwrap()
      } catch (error) {}
    }

    if (onReload) {
      onReload()
    }
  }

  return (
    <div
      className={`fixed bottom-0 left-[72px] right-0 z-10 flex h-16 flex-row items-center border-t-2 bg-white px-6 shadow-sm duration-300 ease-in-out ${
        selectedKeys.length !== 0 ? ' translate-y-0' : ' translate-y-[72px]'
      }`}
    >
      <Button type='primary' danger onClick={handleDeleteItem}>
        {`Delete ${selectedKeys.length} items`}
      </Button>

      <div className='flex-1' />
    </div>
  )
}

export default TableActions

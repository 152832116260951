import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { HiOutlineComputerDesktop, HiOutlineDocumentText, HiOutlineUsers, HiOutlineWallet } from 'react-icons/hi2'
import { LiaQuoteLeftSolid } from 'react-icons/lia'
import { Link, Outlet, useLocation } from 'react-router-dom'

const ZobiteAdminLayout = () => {
  const [current, setCurrent] = useState('home')
  const location = useLocation()

  useEffect(() => {
    setCurrent(location.pathname)
  }, [])

  const items: MenuProps['items'] = [
    {
      label: <Link to='/zobite-admin'>Home</Link>,
      key: '/zobite-admin',
      icon: <HiOutlineComputerDesktop />
    },
    {
      label: <Link to='/zobite-admin/users'>Users</Link>,
      key: '/zobite-admin/users',
      icon: <HiOutlineUsers />
    },
    {
      label: <Link to='/zobite-admin/invoices'>Invoices</Link>,
      key: '/zobite-admin/invoices',
      icon: <HiOutlineWallet />
    },
    {
      label: <Link to='/zobite-admin/apps'>Apps</Link>,
      key: '/zobite-admin/apps',
      icon: <HiOutlineWallet />
    },
    {
      label: <Link to='/zobite-admin/logs'>Logs</Link>,
      key: '/zobite-admin/logs',
      icon: <HiOutlineDocumentText />
    },
    {
      label: <Link to='/zobite-admin/movation-quotes'>Quotes</Link>,
      key: '/zobite-admin/motivation-quotes',
      icon: <LiaQuoteLeftSolid />
    }
  ]
  //
  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  }

  return (
    <main className='flex flex-col'>
      <div className='pt-8'>
        <div className='px-6'>
          <div className='text-2xl font-bold text-main'>Zobite Admin</div>
          <div className='mt-1 text-tertiary'>Ứng dụng quản lý của công ty TNHH phần mềm Zobite</div>
        </div>

        <div className='mt-6 pl-2'>
          <Menu onClick={onClick} selectedKeys={[current]} mode='horizontal' items={items} />
        </div>
      </div>

      <div className='relative p-6'>
        <Outlet />
      </div>
    </main>
  )
}

export default ZobiteAdminLayout

import Lottie from '@shared/components/Lottie'
import type { ReactNode } from 'react'
import React from 'react'

type Props = {
  message?: string
  children?: ReactNode
}

const Empty = ({ children, message = 'Empty data' }: Props) => {
  return (
    <div className='flex flex-col items-center'>
      <Lottie name='empty' />

      <div className='text-tertiary'>{message}</div>

      {children}
    </div>
  )
}

export default Empty

import api from '@shared/common/api'
import { EAppType } from '@shared/common/enum'
import { Divider, Empty } from 'antd'
import React, { useEffect, useState } from 'react'

import AddPackage from './AddPackage'
import PackageListItem from './PackageListItem'

type Props = {
  app: any
}

const Packages = ({ app }: Props) => {
  const [packages, setPackages] = useState<any[]>([])

  const fetchData = async () => {
    try {
      const data: any = await api.get(`admin/packages`, {
        params: {
          appId: app.id,
          sorts: 'createdAt'
        }
      })

      setPackages(data?.items || [])
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <div className='mt-6'>
        {packages.length > 0 ? (
          <div className='flex flex-col'>
            <div className='mb-2 flex flex-row items-center rounded-t border-b-2 bg-gray-100 pb-2 pt-3 text-sm font-medium'>
              <div className='flex-1 px-4'>Name</div>
              <div className='flex-1 px-4'>Price</div>
              <div className='flex-1 px-4'>Type</div>
              <div className='w-20 pr-5 text-right'>Action</div>
            </div>

            <div className='flex flex-col divide-y'>
              {packages.map((item) => {
                return <PackageListItem key={item.id} info={item} onDelete={fetchData} />
              })}
            </div>
          </div>
        ) : (
          <div className='center'>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>

      <Divider />

      {app.type === EAppType.PREMIUM && <AddPackage appId={app.id} onSuccess={fetchData} />}
    </div>
  )
}

export default Packages

import BaseFlatInput from '@shared/components/BaseFlatInput'
import { Input, Popconfirm, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { deleteTask, getTask, updateTask } from '../../reducers/taskReducer'
import AddChildTask from '../child_task/AddChildTask'
import ChildTasks from '../child_task/ChildTasks'

const TaskDetail = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const dispatch = useDispatch<any>()

  const [info, setInfo] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')

  const fetchData = async () => {
    if (!params.taskId) {
      return
    }

    try {
      setLoading(true)

      const rs = await dispatch(
        getTask({
          id: params.taskId
        })
      ).unwrap()
      setInfo(rs)
      setNote(rs.note)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (params.taskId) {
      fetchData()
    }
  }, [params.taskId])

  /*
   *****************************************
   *
   *
   */

  const handleUpdate = async (payload: any) => {
    try {
      const data = await dispatch(
        updateTask({
          id: info.id,
          ...payload
        })
      ).unwrap()

      setInfo(data)
    } catch (error) {}
  }

  const handleUpdateTitle = (title: string) => {
    handleUpdate({
      title
    })
  }

  const handleUpdateNote = (val: string) => {
    if (val === info.note) {
      return
    }

    handleUpdate({
      note: val
    })
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteTask({ id: info.id })).unwrap()

      navigate(`/to-do/${params.folderId}`)
    } catch (error) {}
  }

  /*
   *****************************************
   *
   *
   */
  return (
    <div className='h-full w-[400px] border-l border-gray-200 '>
      {params.taskId && !loading ? (
        <div className='flex h-full flex-col bg-white'>
          <div className='flex h-16 flex-row items-center gap-3 px-4'>
            <BaseFlatInput value={info.title} onChange={handleUpdateTitle} className='w-full text-[20px] font-medium' />
          </div>

          <ReactPerfectScrollbar style={{ maxHeight: 'calc(100vh - 64px - 48px)' }} options={{ suppressScrollX: true }}>
            <div>
              <ChildTasks taskId={params.taskId} />

              <AddChildTask taskId={params.taskId} />

              <div className='flex-1 bg-[#f5f5f5] p-2'>
                <Input.TextArea
                  onBlur={(evt) => {
                    handleUpdateNote(evt.target.value)
                  }}
                  value={note}
                  onChange={(evt) => {
                    setNote(evt.target.value)
                  }}
                  className='rounded-none border-none text-sm'
                  placeholder='Ghi chú...'
                  autoSize={{ minRows: 6, maxRows: 20 }}
                />
              </div>
            </div>
          </ReactPerfectScrollbar>

          <div className='flex h-12 flex-row items-center border-t pr-4'>
            <div className='flex-1' />

            <Popconfirm title='Confirm' overlayInnerStyle={{ width: '300px' }} description='Are you sure you want to delete this?' onConfirm={handleDelete}>
              <button className='group h-7 w-7 cursor-pointer rounded bg-transparent p-0 center hover:bg-red-200'>
                <BsTrash size={16} className='group-hover:text-red-800' />
              </button>
            </Popconfirm>
          </div>
        </div>
      ) : (
        <div>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default TaskDetail

/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { EAppPlatform } from '@shared/common/enum'
import cacheReducer from '@shared/common/reducers/cacheReducer'
import invoiceReducer from '@shared/common/reducers/invoiceReducer'
import meReducer from '@shared/common/reducers/meReducer'
import appReducer from '@shared/modules/app/reducers/appReducer'
import installedAppReducer from '@shared/modules/app/reducers/installedAppReducer'
import authReducer from '@shared/modules/auth/reducers/authReducer'
import GenerateSecurePasswordReducer from '@shared/modules/generate_secure_password/store'
import groupOrderReducer from '@shared/modules/group-order/store'
import TnoteReducer from '@shared/modules/tnote/store'
import TodoReducer from '@shared/modules/todo/store'
import ZobiteAdminAppReducer from '@shared/modules/zobite-admin/store'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { localStorage } from 'redux-persist-webextension-storage'
import { createStateSyncMiddleware } from 'redux-state-sync'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION ? localStorage : storage,
  whitelist: ['auth', 'me', 'app', 'installedApp', 'cache', 'tnote']
}

const rootReducer = combineReducers({
  auth: authReducer,
  me: meReducer,
  app: appReducer,
  installedApp: installedAppReducer,
  invoice: invoiceReducer,
  cache: cacheReducer,
  // App
  zobiteAdmin: ZobiteAdminAppReducer,
  generateSecurePassword: GenerateSecurePasswordReducer,
  todo: TodoReducer,
  groupOrder: groupOrderReducer,
  tnote: TnoteReducer
})

let middlewares: any = [thunk]

if (Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION) {
  middlewares = [
    thunk,
    createStateSyncMiddleware({
      blacklist: ['persist/PERSIST', 'persist/REHYDRATE']
    })
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

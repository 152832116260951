/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit'
import { EAppPlatform } from '@shared/common/enum'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { localStorage } from 'redux-persist-webextension-storage'

import orderItemReducer from './reducers/orderItemReducer'
import orderReducer from './reducers/orderReducer'

const persistConfig = {
  key: 'groupOrder',
  // @ts-ignore
  storage: Number(process.env.REACT_APP_PLATFORM) === EAppPlatform.BROWSER_EXTENSION ? localStorage : storage,
  whitelist: []
  // blacklist: ['current'],
}

const OrderTogetherReducer = combineReducers({
  order: orderReducer,
  orderItem: orderItemReducer
  // workspace: persistReducer(persistConfig, workspaceReducer),
})

// export default OrderTogetherReducer

export default persistReducer(persistConfig, OrderTogetherReducer)

import type { FocusEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  id?: string
  name?: string
  placeholder?: string
  wrapperClassName?: string
  className?: string
  value: string
  noStyle?: boolean
  onChange: (val: string) => void
}

const BaseFlatInput = ({ onChange, value, id, name, className, wrapperClassName, noStyle, placeholder = '' }: Props) => {
  const inputRef = useRef<any>(null)
  const [val, setVal] = useState(value)

  useEffect(() => {
    setVal(value)
  }, [value])

  const handleEnterKey = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && val !== value) {
      if (inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  const handleOnBlur = (evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value !== value) {
      if (val.trim() === '') {
        setVal(value)
      } else {
        onChange(val)
      }
    }
  }

  return (
    <div className={`${noStyle ? 'zo-base-flat-input-no-style' : 'zo-base-flat-input'}  ${wrapperClassName}`}>
      <input
        ref={inputRef}
        placeholder={placeholder}
        id={id}
        className={`${className}`}
        name={name}
        type='text'
        value={val}
        onKeyUp={handleEnterKey}
        onBlur={handleOnBlur}
        onChange={(evt) => {
          setVal(evt.target.value)
        }}
      />
    </div>
  )
}

export default BaseFlatInput

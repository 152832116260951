import type { ReactNode } from 'react'
import React from 'react'

type Props = {
  // type: 'login' | 'register'
  children?: ReactNode
}

const ZobiteAuthLink = ({ children }: Props) => {
  const getRedirectUrl = () => {
    const callbackUrl = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/login`)

    if (process.env.REACT_APP_APP_ENV === 'local') {
      return `http://localhost:3001/login?continue=${callbackUrl}`
    }

    if (process.env.REACT_APP_APP_ENV === 'dev') {
      return `https://iddev.zobite.com/login?continue=${callbackUrl}`
    }

    return `https://id.zobite.com/login?continue=${callbackUrl}`
  }

  return (
    <a href={getRedirectUrl()} className='h-12 w-full rounded-lg border-2 border-[#EFEFEF] font-semibold center' rel='noreferrer'>
      {children ?? 'Continue with email address'}
    </a>
  )
}

export default ZobiteAuthLink

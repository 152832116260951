import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@shared/common/api'
import type { RootState } from '@shared/store'

interface INoteState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
  }
  note: any
}

const initialState: INoteState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 50,
    search: undefined,
    sorts: 'position'
  },
  note: {}
}

/*
 *****************************************
 *
 *
 */

export const fetchNotes = createAsyncThunk('tnote/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.tnote.note.filter }

    return await api.get('tnote/notes', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createNote = createAsyncThunk('tnote/create', async (_, { rejectWithValue }) => {
  const initialContent = [
    {
      type: 'paragraph',
      content: ''
    }
  ]
  try {
    return await api.post('tnote/notes', {
      title: 'Untitled',
      content: JSON.stringify(initialContent, null, 2),
      position: 0
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const duplicateNote = createAsyncThunk('tnote/duplicate', async (noteId: string, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  const note = state.tnote.note.items.find((item) => item.id === noteId)

  try {
    return await api.post('tnote/notes', {
      title: note.title,
      content: note.content,
      position: note.position + 1
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type UpdateNotePositionsDto = {
  positions: any[]
}

export const updateNotePosition = createAsyncThunk('tnote/updatePositions', async (payload: UpdateNotePositionsDto, { rejectWithValue }) => {
  try {
    await api.put(`tnote/notes/update-positions`, payload)
    return payload
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const fetchNote = createAsyncThunk('tnote/fetchNote', async (noteId: string, { rejectWithValue }) => {
  try {
    return await api.get(`tnote/notes/${noteId}`)
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateNote = createAsyncThunk('tnote/updateNote', async (payload: any, { rejectWithValue }) => {
  const { noteId, ...rest } = payload
  try {
    return await api.put(`tnote/notes/${noteId}`, rest)
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteNote = createAsyncThunk('tnote/deleteNote', async (noteId: string, { rejectWithValue }) => {
  try {
    const rs = await api.delete(`tnote/notes/${noteId}`)
    return {
      id: noteId,
      status: rs
    }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const tnoteSlice = createSlice({
  name: 'tnote_reducer',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    updateOrderLocal(state, { payload }) {
      const info = payload as any

      const findIndex = state.items.findIndex((item) => item.id === info.id)

      if (findIndex >= 0) {
        state.items.splice(findIndex, 1, Object.assign(state.items[findIndex], payload))
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items
        state.total = total
      })
      .addCase(updateNotePosition.fulfilled, (state, action) => {
        const { positions } = action.payload as any

        for (const newItem of positions) {
          const findIndex = state.items.findIndex((item) => item.id === newItem.id)

          if (findIndex >= 0) {
            const newObject = Object.assign(state.items[findIndex], newItem)

            state.items.splice(findIndex, 1, newObject)
          }
        }
      })
      .addCase(fetchNote.fulfilled, (state, action) => {
        const note = action.payload as any
        state.note = note
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const note = action.payload as any

        const index = state.items.findIndex((item) => item.id === note.id)
        if (index !== -1) {
          state.items.splice(index, 1, note)
        }
      })
      .addCase(duplicateNote.fulfilled, (state, action) => {
        const note = action.payload as any
        state.items.splice(state.items.findIndex((item) => item.id === note.id) + 1, 0, note)
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const { id, status } = action.payload as any

        if (!status) {
          return
        }

        const index = state.items.findIndex((item) => item.id === id)
        if (index !== -1) {
          state.items.splice(index, 1)
        }
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.items.unshift(action.payload)
      })
  }
})

export const { updateFilter, updateOrderLocal } = tnoteSlice.actions
export default tnoteSlice.reducer

import Lottie from '@shared/components/Lottie'
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <main className='h-full flex-1 flex-col center'>
      <Lottie className='w-[320px]' name='man1' />

      <div className='mt-4 text-tertiary'>Sorry, the page you visited does not exist.</div>

      <Link to='/' className='mt-6'>
        <Button type='primary'>Back to home</Button>
      </Link>
    </main>
  )
}

export default Error404
